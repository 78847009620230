import {
  PromoCardData,
  PromoCardTemplateData,
  PromoCardType,
  PromoSettingCMSData
} from '~/App/shared/interfaces/PromoSettingCMSData';
import { Nullable } from '~/App/shared/types/Nullable';
import {
  ButtonRecord,
  PromoCardRecord,
  PromoCardTemplateRecord,
  PromoSettingByUsageTypeQuery,
  FilterPageQuery,
  TextLinkRecord
} from '~/config/generated/graphql';
import { isMainCategory } from '~/helpers/filterSearchParams';

export const handleFilterPageData = ({ filterPage }: FilterPageQuery) => {
  return {
    id: filterPage?.id,
    title: filterPage?.title,
    description: filterPage?.description,
    seo: {
      title: filterPage?.seo?.title,
      description: filterPage?.seo?.description
    },
    seoInformation: filterPage?.seoInformation,
    categoryBlurbs: filterPage?.categoryBlurbs
  };
};

type MissingPromoCardRecordTypes = {
  isActive: boolean;
  cardPositionPhone: number;
  cardPositionTablet: number;
  cardPositionDesktop: number;
};

export const handlePromoSettingCMSData = ({
  promoSetting: promoSetting
}: PromoSettingByUsageTypeQuery): PromoSettingCMSData => {
  return {
    usageType: promoSetting?.usageType ?? null,
    promoCards:
      promoSetting?.promoCards?.map(data =>
        mapPromoCards(data as Partial<PromoCardRecord>)
      ) ?? []
  };
};

export const mapPromoCards = (
  card: Partial<PromoCardRecord>
): PromoCardData => {
  return {
    isActive: (card as MissingPromoCardRecordTypes).isActive,
    applyForCategory:
      card?.applyForCategory
        ?.map(filterPage => filterPage.mainCategory)
        ?.filter(isMainCategory) ?? [],
    cardPositionPhone: (card as MissingPromoCardRecordTypes).cardPositionPhone,
    cardPositionTablet: (card as MissingPromoCardRecordTypes)
      .cardPositionTablet,
    cardPositionDesktop: (card as MissingPromoCardRecordTypes)
      .cardPositionDesktop,
    promoCardTemplates: card.promoCardTemplates?.map(mapPromoTemplate) ?? []
  };
};

export const isButtonRecord = (
  link: Nullable<Partial<ButtonRecord> | Partial<TextLinkRecord>>
): link is ButtonRecord => {
  return link?.__typename === 'ButtonRecord';
};

export const isTextLinkRecord = (
  link: Nullable<Partial<ButtonRecord> | Partial<TextLinkRecord>>
): link is TextLinkRecord => {
  return link?.__typename === 'TextLinkRecord';
};

export const mapPromoTemplate = (
  promoCardTemplate: PromoCardTemplateRecord
): PromoCardTemplateData => {
  const templateLink = promoCardTemplate?.link?.[0] ?? null;

  return {
    cardType: (promoCardTemplate.cardType as PromoCardType) ?? null,
    title: promoCardTemplate.title ?? '',
    content: promoCardTemplate.content ?? null,
    textColor: promoCardTemplate.textColor ?? null,
    backgroundColor: promoCardTemplate.backgroundColor ?? null,
    link: templateLink,
    ga4EventIdentifier: promoCardTemplate.ga4EventIdentifier ?? ''
  };
};
