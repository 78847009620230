import {
  AlertIcon,
  AuctionIcon,
  Button,
  CarIcon,
  CheckBox,
  Headline,
  IdIcon,
  RegistrationPlate,
  ShowMoreIcon,
  Subtitle
} from '@kvdbil/components';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getIdFromSlug } from '~/helpers/auction';
import { BASE_CARGO_DEV_URL, BASE_CARGO_URL } from '~/config/constants';
import { storeObjectById } from '../shared/selectors/storeObject';
import Divider from '../views/ObjectPage/components/Divider';
import { toggleShowDato } from '../shared/actions/kvdHelperActions';
import { showDato as showDatoSelector } from '../shared/selectors/kvdHelperSelectors';
import { useTranslation } from '~/Locale';
import { isClient } from '~/config/public/environment';

const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  align-items: center;
  transition: left 0.5s ease;
  left: ${({ isOpen }) => (isOpen ? '0px' : '-300px')};
  top: 50%;
  transform: translate(0, -50%);
  z-index: 500000;
`;

const Content = styled.div`
  width: 300px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fff;
  border: 2px #1a2030 solid;
  border-left: none;
  box-shadow: 14px 14px 37px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 14px 14px 37px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 14px 14px 37px 1px rgba(0, 0, 0, 0.75);
  overflow: hidden;
`;

const Toggle = styled.button<{ isOpen: boolean }>`
  height: 2rem;
  width: 1.5rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fff;
  border: 2px #1a2030 solid;
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px 0 0;
  font-size: 1.5rem;

  #arrow {
    transition: transform 0.5s ease;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(-90deg)')};
  }

  :hover {
    cursor: pointer;
  }
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.main};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 4rem;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  gap: 1.5rem;
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
`;

const Buttons = styled.div<{ isInfoShowing: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ isInfoShowing }) =>
    (isInfoShowing ? '2rem' : '1rem') + ' 0 1rem 0'};
  gap: 0.5rem;
`;

const CheckBoxArea = styled.div`
  justify-content: center;
  align-items: center;
  margin: 2rem 1rem 1rem 1rem;
`;

const encodeURL = (url: string) => url.replaceAll('&', '%26');

function KvdHelper({ location }: { location?: string }) {
  const dispatch = useDispatch();
  const showDato = useSelector(showDatoSelector);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const auctionId = getIdFromSlug(location?.split('/').pop());
  const auction = useSelector(storeObjectById(auctionId));

  const regPlate = auction?.processObject?.properties?.registrationPlate;

  const handleClick = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const handleCheckboxOnChange = useCallback(() => {
    dispatch(toggleShowDato());
  }, [dispatch]);

  const encodedUrl = isClient ? encodeURL(window.location.href) : '';

  return (
    <Container isOpen={isOpen}>
      <Content>
        <Header>
          <img src="/images/kvdbil-logo-social.png" alt="logo" />
          <Headline style={{ marginRight: '0.5rem' }}>-</Headline>
          <Headline>Helper</Headline>
        </Header>
        {Boolean(auction) && (
          <InfoContainer>
            <Info>
              <IdIcon />
              <Subtitle>{auction?.processObject.id}</Subtitle>
            </Info>
            {regPlate && (
              <Info>
                <RegistrationPlate regNumber={regPlate} />
              </Info>
            )}
          </InfoContainer>
        )}
        <Divider />
        <Buttons isInfoShowing={Boolean(auction)}>
          <a
            href={`${
              process.env.NODE_ENV === 'production'
                ? BASE_CARGO_URL
                : BASE_CARGO_DEV_URL
            }/object-management/${auction?.processObject.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button prefixIcon={<CarIcon />} isDisabled={Boolean(!auction)}>
              {t('Object management')}
            </Button>
          </a>
          <a
            href={`${
              process.env.NODE_ENV === 'production'
                ? BASE_CARGO_URL
                : BASE_CARGO_DEV_URL
            }/auction-management/active-auctions/${auction?.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button prefixIcon={<AuctionIcon />} isDisabled={Boolean(!auction)}>
              {t('Auction management')}
            </Button>
          </a>
          <a
            href={`https://kvarndammen.atlassian.net/servicedesk/customer/portal/1/group/24/create/42?summary=kvd.se:%20&description=Bug%20found%20here:%20${encodedUrl}%0A%0A`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button prefixIcon={<AlertIcon />} color="error">
              {t('Report bug/problem')}
            </Button>
          </a>
        </Buttons>
        <Divider />
        <CheckBoxArea>
          <CheckBox
            label={t('Edit Dato content')}
            checked={showDato}
            onChange={handleCheckboxOnChange}
          />
        </CheckBoxArea>
      </Content>
      <Toggle isOpen={isOpen} onClick={handleClick}>
        <ShowMoreIcon id="arrow" />
      </Toggle>
    </Container>
  );
}

export default KvdHelper;
