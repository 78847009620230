import { FeatureFlagsObject } from '~/App/shared/interfaces/FeatureFlags';
import { ReduxStore } from '~/App/shared/interfaces/store';
import { ABTests } from '~/App/shared/interfaces/store/AbTests';
import { AWIN_CLIENT_ID } from '../constants';
import { Locale } from '~/App/shared/localization/types';

const isWindow = typeof window !== 'undefined';

declare global {
  interface Window {
    CLIENT_LOCALE: Locale;
    CLIENT_BASE_URL: string;
    ORCHESTRATION_VERSION: string;
    ORCHESTRATION_BASE_URL: string;
    WEBSOCKET_BASE_URL: string;
    DATO_HOST: string;
    DATO_ACCESS_TOKEN: string;
    CMS_PROXY_HOST: string;
    GOOGLE_RECAPTCHA_V3_SITE_KEY: string;
    GOOGLE_MAPS_AND_PLACES_API_KEY: string;
    BUGSNAG_API_KEY: string;
    DOCKER_IMAGE_TAG: string;
    ACCESS_MODE: string;
    CLIENT_VERSION: string;
    FEATURE_CAMPAIGN_ENABLED: string;
    FEATURE_TARGETED_AUCTIONS_ENABLED: string;
    INITIAL_REDUX_STATE: ReduxStore;
    FEATURE_FLAGS: FeatureFlagsObject;
    FEATURE_FLAG_CLIENT_ID: string;
    abTests: ABTests;
    FEATURE_RELEASING_ENABLED: string;
    FEATURE_AFFILIATE_TRACKING_ENABLED: string;
    FEATURE_SCRIVE_ENABLED: string;
    ENVIRONMENT: string;
    ZAVER_WIDGET_SRC_URL: string;
  }
}

export const locale = (
  isWindow ? window.CLIENT_LOCALE : process.env.LOCALE ?? Locale.SV
) as Locale;

export const baseUrl = isWindow
  ? window.CLIENT_BASE_URL
  : process.env.BASE_URL ?? 'https://www.kvd.se';

export const orchestrationVersion = isWindow
  ? window.ORCHESTRATION_VERSION
  : process.env.ORCHESTRATION_VERSION ?? 'v1';

export const orchestrationBaseUrl = isWindow
  ? window.ORCHESTRATION_BASE_URL
  : process.env.ORCHESTRATION_BASE_URL ?? 'https://api.public.kvd.se';

export const websocketBaseUrl = isWindow
  ? window.WEBSOCKET_BASE_URL
  : process.env.WEBSOCKET_BASE_URL ?? 'https://ws.public.kvd.se';

export const datoHost = isWindow
  ? window.DATO_HOST
  : process.env.DATO_HOST ?? '';

export const cmsProxyHost = isWindow
  ? window.CMS_PROXY_HOST
  : process.env.CMS_PROXY_HOST ?? '';

export const datoAccessToken = isWindow
  ? window.DATO_ACCESS_TOKEN
  : process.env.DATO_ACCESS_TOKEN ?? '';

export const launchDarklyClientID = isWindow
  ? window.FEATURE_FLAG_CLIENT_ID
  : process.env.FEATURE_FLAG_CLIENT_ID ?? '';

export const googleRecaptchaSiteKey = isWindow
  ? window.GOOGLE_RECAPTCHA_V3_SITE_KEY
  : process.env.GOOGLE_RECAPTCHA_V3_SITE_KEY ?? '';

export const googleMapsAndPlacesApiKey = isWindow
  ? window.GOOGLE_MAPS_AND_PLACES_API_KEY
  : process.env.GOOGLE_MAPS_AND_PLACES_API_KEY ?? '';

export const didomiKey = process.env.DIDOMI_KEY;

export const bugsnagKey = isWindow
  ? window.BUGSNAG_API_KEY
  : process.env.BUGSNAG_API_KEY ?? '';

export const zaverWidgetSrcUrl = isWindow
  ? window.ZAVER_WIDGET_SRC_URL
  : process.env.ZAVER_WIDGET_SRC_URL ??
    'https://iframe-checkout.test.zaver.se/loader/v1.js';

export const version = (
  isWindow ? window.DOCKER_IMAGE_TAG : process.env.DOCKER_IMAGE_TAG
) as string;

export const isReadOnly = isWindow
  ? window.ACCESS_MODE === 'readonly'
  : process.env.ACCESS_MODE === 'readonly' ?? false;

export const isProduction = process.env.NODE_ENV === 'production' || false;

export const isDevelopment = process.env.NODE_ENV === 'development' || false;

export const campaignFeatureEnabled = isWindow
  ? Boolean(window.FEATURE_CAMPAIGN_ENABLED === 'true')
  : Boolean(process.env.FEATURE_CAMPAIGN_ENABLED === 'true');

export const targetedAuctionsFeatureEnabled = isWindow
  ? Boolean(window.FEATURE_TARGETED_AUCTIONS_ENABLED === 'true')
  : Boolean(process.env.FEATURE_TARGETED_AUCTIONS_ENABLED === 'true');

export const initialState = (): ReduxStore | undefined => {
  if (typeof window === 'undefined') {
    return;
  }

  const preloadedState = window.INITIAL_REDUX_STATE || {};
  const preloadedABTests = window.abTests;
  preloadedState.abTests = preloadedABTests;

  return preloadedState;
};

export const useReduxLogger = process.env.USE_REDUX_LOGGER === 'true';

export const useWebsockets = isWindow;

export const releasingEnabled = isWindow
  ? Boolean(window.FEATURE_RELEASING_ENABLED === 'true')
  : Boolean(process.env.FEATURE_RELEASING_ENABLED === 'true');

export const affiliateTrackingEnabled = isWindow
  ? window.FEATURE_AFFILIATE_TRACKING_ENABLED === 'true' || false
  : process.env.FEATURE_AFFILIATE_TRACKING_ENABLED === 'true' || false;

export const scriveEnabled = isWindow
  ? window.FEATURE_SCRIVE_ENABLED === 'true' || false
  : process.env.FEATURE_SCRIVE_ENABLED === 'true' || false;

export const environment =
  (isWindow ? window.ENVIRONMENT : process.env.ENVIRONMENT) || 'local';

export const isProductionEnvironment = environment === 'production';

export const port = process.env.PORT || 3000;

export const isDebug = process.env.DEBUG_CLIENT === 'true';

export const awinClientId = AWIN_CLIENT_ID;

export const nodeEnvironment = process.env.NODE_ENV || 'production';

export const isServer = !isWindow;
export const isClient = isWindow;
