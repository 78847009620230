import {
  CloseIcon,
  Flex,
  SearchIcon,
  Spacer,
  TextField,
  mq
} from '@kvdbil/components';
import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '~/Locale';
import { useOutsideClick } from '../../hooks/outsideClickHook';
import { useIsDevice } from '../../hooks/useIsDevice';
import SearchFieldHeader from './SearchFieldHeader';
import ScrollLock from 'react-scrolllock';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  clearRecentSearches,
  saveSearchAsRecent
} from '../../actions/recently-viewed-searches';
import {
  AutoCompleteItem,
  autoCompleteSearch
} from '~/helpers/orchestration/auctionSearchAutoComplete';
import { recentlyViewedSearches as recentlyViewedSearchesSelector } from '~/App/shared/selectors/recentlyViewedSearches';
import { requestErrorHandler } from '~/helpers/notifyError';
import { useThrottle } from '../../hooks/useThrottle';
import ResultList, { AutoCompleteSearch } from './ResultList';
import { useParamFromQuery } from '../../hooks/useQueryParams';
import { IncludeContent } from '../MediaQueryContainer';
import SearchFieldCategorySelect from './SearchFieldCategorySelect';
import SearchFieldCategorySelectMobile from './SearchFieldCategorySelectMobile';
import { useFilterSearchParams } from '~/App/views/FilterPage/hooks/useFilterSearchParams';
import { CategoryOption } from '../../types/FilterSearchParam';
import {
  getObjectCategoryOptions,
  getPathFromMainCategory
} from '~/helpers/filterTranslation';
import { getDefaultCategoryOption } from './helpers';
import { MainCategory } from '../../types/MainCategoryTypes';
import { useFeatureFlagValue } from '~/App/components/FeatureFlags';

const roundedSearchBorderRadius = '30px';

const padding = '1.5rem';

const ObjectSearchFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SearchFieldPageContainer = styled.div<{ isOpened: boolean }>`
  flex: 1;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  ${mq(null, 'tablet')} {
    position: relative;

    ${({ theme, isOpened }) =>
      isOpened &&
      css`
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 500001; // Overlaps the contact button
        background: ${theme.colors.background.light};
        overflow: auto;
        margin-top: 0;
        padding: 0 1rem 1rem 1rem;

        & *:not(& #search-field-row) {
          opacity: 0;
          animation: fadeIn 0.3s ease-in-out forwards;
        }
      `}
  }

  ${mq('tablet')} {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const SearchEdge = styled(Flex)`
  height: 60px;
  padding-inline: ${padding};
`;

interface LeftEdgeProps {
  isDropdownOpen: boolean;
  isSelectOpen: boolean;
}

const LeftEdge = styled(SearchEdge)<LeftEdgeProps>`
  border-top-left-radius: ${roundedSearchBorderRadius};
  border-bottom-left-radius: ${({ isSelectOpen }) =>
    isSelectOpen ? 0 : roundedSearchBorderRadius};
  z-index: 10;
  padding-inline: 0;
  width: 200px;
  height: 60px;
  background: ${({ theme, isDropdownOpen }) =>
    isDropdownOpen
      ? theme.colors.background.light
      : theme.colors.background.gray};

  ${mq('tablet', 'laptop')} {
    width: 165px;
  }

  ${mq(null, 'tablet')} {
    display: none !important;
  }
`;

const RightEdge = styled(SearchEdge)<{ dropdownOpen: boolean }>`
  ${({ dropdownOpen }) =>
    dropdownOpen
      ? css`
          background: ${({ theme }) => theme.colors.background.light};
          color: ${({ theme }) => theme.colors.common.black};
        `
      : css`
          background: ${({ theme }) => theme.colors.common.black};
          color: ${({ theme }) => theme.colors.common.white};
          border-bottom-right-radius: ${roundedSearchBorderRadius};
        `}

  border-top-right-radius: ${roundedSearchBorderRadius};
  border-bottom-right-radius: ${roundedSearchBorderRadius};
  z-index: 10;

  & > svg {
    font-size: 2rem;
  }
`;

const SearchTextField = styled(TextField)`
  flex: 1;
  z-index: 10;

  & input:placeholder-shown {
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
  }

  & > div {
    height: 60px;
    border: 0;
    outline: 0;
    padding-left: ${padding};
  }

  ${mq(null, 'tablet')} {
    & > div {
      border-top-left-radius: ${roundedSearchBorderRadius};
      border-bottom-left-radius: ${roundedSearchBorderRadius};
    }
  }
`;

const SearchFieldContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  border-top-left-radius: ${roundedSearchBorderRadius};
  border-bottom-left-radius: ${roundedSearchBorderRadius};
`;

const SearchFieldRow = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0;
  flex: 1;
  margin-top: 0;
  z-index: 100;

  ${mq(null, 'tablet')} {
    ${({ theme, isOpen }) =>
      isOpen &&
      css`
        margin-top: 3.5rem;
        border: 1px solid ${theme.colors.gray.light5};
      `}

    animation: ${({ isOpen }) =>
      isOpen &&
      css`
          slideUp 0.3s ease-in-out forwards
        `};

    border-radius: ${roundedSearchBorderRadius};
  }

  @keyframes slideUp {
    from {
      margin-top: 3.5rem;
    }
    to {
      margin-top: 0;
    }
  }
`;

const SeparatorContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  background: ${({ theme }) => theme.colors.background.light};
  width: 2px;
  height: 60px;

  ${mq(null, 'tablet')} {
    display: none;
  }
`;

const Separator = styled.div`
  background: ${({ theme }) => theme.colors.background.gray};
  width: 100%;
  height: 60%;
`;

const SearchResults = styled.div`
  position: absolute;
  top: 30px;
  background: ${({ theme }) => theme.colors.background.light};
  width: 100%;
  border-bottom-left-radius: ${roundedSearchBorderRadius};
  border-bottom-right-radius: ${roundedSearchBorderRadius};

  ${mq(null, 'tablet')} {
    position: relative;
    top: 0;
    margin-top: 1rem;
  }

  ${mq('tablet')} {
    padding: ${padding};
    box-shadow: ${({ theme }) => theme.elevations.elevation4};
  }
`;

const CategorySelectWrapper = styled(IncludeContent)`
  width: 100%;

  & > div {
    max-width: 200px;
  }
`;

const applySuggestionSpec = (suggestions: AutoCompleteItem[]) =>
  suggestions.map(({ terms }) => ({
    name: terms,
    filters: {
      terms: terms
    }
  }));

const useSearchCategory = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const previousPathname = useRef(pathname);
  const categoryOptions = useMemo(() => getObjectCategoryOptions(t), [t]);

  const [searchCategory, setSearchCategory] = useState(
    getDefaultCategoryOption(t, pathname, categoryOptions)
  );

  useEffect(() => {
    if (previousPathname.current !== pathname) {
      setSearchCategory(getDefaultCategoryOption(t, pathname, categoryOptions));
      previousPathname.current = pathname;
    }
  }, [pathname, categoryOptions, t]);

  return { searchCategory, setSearchCategory, categoryOptions };
};

type AutoCompleteMappedItem = {
  name: string;
  filters: {
    terms: string;
  };
};

const ObjectSearchField = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { filterSearchParams } = useFilterSearchParams([], location.search);
  const terms = useParamFromQuery('terms');
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useIsDevice();

  const isHeavyObjectAutoCompleat = useFeatureFlagValue(
    'p2c-heavy-objects-auto-complete'
  );

  const [isDropdownOpen, setOpen] = useState(false);
  const [isSelectOpen, setSelectOpen] = useState(false);

  const { searchCategory, setSearchCategory, categoryOptions } =
    useSearchCategory();

  const [searchInput, setSearchInput] = useState(terms ?? '');
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<
    AutoCompleteMappedItem[]
  >([]);
  const recentlyViewedSearches = useSelector(recentlyViewedSearchesSelector);
  const isAutoComplete = Boolean(searchInput) && searchInput?.length > 1;
  const searchOptions: AutoCompleteSearch[] = !isAutoComplete
    ? recentlyViewedSearches
    : autoCompleteOptions;

  const handleClose = useCallback(() => {
    if (isDropdownOpen) {
      setOpen(false);
    }
  }, [setOpen, isDropdownOpen]);

  const componentRef = useOutsideClick(handleClose);
  const inputRef = useRef<HTMLInputElement>(null);

  const getAutoCompleteSuggestions = useCallback(
    (terms: string, mainCategory: MainCategory) => {
      if (
        !isHeavyObjectAutoCompleat &&
        (mainCategory === 'heavy_transport' || mainCategory === 'machines')
      )
        return;

      autoCompleteSearch(terms, mainCategory)
        .then(suggestions => {
          const mappedSuggestions = suggestions
            ? applySuggestionSpec(suggestions)
            : [];

          setAutoCompleteOptions(mappedSuggestions);
        })
        .catch(requestErrorHandler);
    },
    [isHeavyObjectAutoCompleat]
  );

  const handleSearch = useCallback(
    (
      input: string,
      option: CategoryOption,
      filters: URLSearchParams,
      clearFilters?: boolean
    ) => {
      handleClose();

      const terms = input.replace(/\s{1,}/g, ' ').trim();
      const hasTerms = Boolean(terms);

      option.value && filters.append('vehicleType', option.value);

      const url = getPathFromMainCategory(t, option.value);
      const searchParams = clearFilters ? new URLSearchParams() : filters;

      !terms && searchParams.delete('terms');
      terms && searchParams.set('terms', terms);

      hasTerms && dispatch(saveSearchAsRecent(t, terms, searchParams));

      searchParams.delete('vehicleType');

      if (location.pathname === url) {
        history.replace(`${url}?${searchParams.toString()}`);
      } else {
        history.push(`${url}?${searchParams.toString()}`);
      }

      setSearchInput(terms);
      inputRef.current?.blur();
    },
    [handleClose, t, location.pathname, dispatch, history]
  );

  const handleCategoryOnChange = useCallback(
    (option: CategoryOption) => {
      setSearchCategory(option);
      handleSearch(searchInput, option, filterSearchParams, true);
    },
    [filterSearchParams, handleSearch, searchInput, setSearchCategory]
  );

  const handleEnter: KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
    key === 'Enter' &&
      handleSearch(searchInput, searchCategory, filterSearchParams);
  };

  const handleClearRecentSearches = useCallback(() => {
    dispatch(clearRecentSearches());
    inputRef.current?.focus();
    setOpen(true);
  }, [dispatch]);

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setOpen(true);
    event.target.focus();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpen(true);
    setSearchInput(event.target.value);
  };

  const handleInputReset = useCallback(() => {
    handleSearch('', searchCategory, filterSearchParams);
    setSearchInput('');
    inputRef.current?.focus();
  }, [filterSearchParams, handleSearch, searchCategory]);

  const throttledSearch = useThrottle(getAutoCompleteSuggestions, 400);

  useEffect(() => {
    if (isAutoComplete) {
      throttledSearch(searchInput, searchCategory.value);
    }
  }, [isAutoComplete, searchCategory.value, searchInput, throttledSearch]);

  useEffect(() => {
    setSearchInput(terms ?? '');
  }, [terms, setSearchInput]);

  return (
    <ObjectSearchFieldContainer>
      <CategorySelectWrapper to="tablet">
        <SearchFieldCategorySelectMobile
          options={categoryOptions}
          defaultValue={categoryOptions[0]}
          value={searchCategory}
          onChange={handleCategoryOnChange}
        />
      </CategorySelectWrapper>

      <SearchFieldPageContainer isOpened={isDropdownOpen} ref={componentRef}>
        {isMobile && isDropdownOpen && <ScrollLock isActive />}
        {isDropdownOpen && <SearchFieldHeader handleClose={handleClose} />}
        <SearchFieldContainer
          onClick={e => {
            e.stopPropagation(); // Prevents the outside click handler from firing
          }}
        >
          <SearchFieldRow isOpen={isDropdownOpen} id="search-field-row">
            <LeftEdge
              isDropdownOpen={isDropdownOpen}
              isSelectOpen={isSelectOpen}
            >
              <SearchFieldCategorySelect
                onMenuOpen={() => {
                  setSelectOpen(true);
                  setOpen(false);
                }}
                onMenuClose={() => setSelectOpen(false)}
                onChange={handleCategoryOnChange}
                options={categoryOptions}
                value={searchCategory}
              />
            </LeftEdge>

            <SeparatorContainer>
              {isDropdownOpen && !isSelectOpen && <Separator />}
            </SeparatorContainer>

            <SearchTextField
              onFocus={e => handleInputFocus(e)}
              type="text"
              placeholder={t('Search for brand...')}
              onChange={e => handleInputChange(e)}
              value={searchInput}
              forwardRef={inputRef}
              onKeyUp={handleEnter}
            />
            <RightEdge dropdownOpen={isDropdownOpen}>
              {searchInput?.length > 0 ? (
                <CloseIcon onClick={handleInputReset} />
              ) : (
                <SearchIcon
                  onClick={() =>
                    handleSearch(
                      searchInput,
                      searchCategory,
                      filterSearchParams
                    )
                  }
                />
              )}
            </RightEdge>
          </SearchFieldRow>

          {isDropdownOpen && Boolean(searchOptions?.length) && (
            <SearchResults>
              <IncludeContent from="tablet">
                <Spacer height={0.5} />
              </IncludeContent>

              <ResultList
                handleClose={handleClose}
                localTerms={searchInput}
                searchAuctions={(searchParams, mainCategory, _orderBy) => {
                  // find mainCategory in categoryOptions
                  const searchCategory = categoryOptions.find(
                    category => category.value === mainCategory
                  );
                  const filterSearchParams = new URLSearchParams(searchParams);

                  handleSearch(
                    searchParams?.terms ?? '',
                    searchCategory ?? categoryOptions[0],
                    filterSearchParams
                  );
                }}
                searchOptions={searchOptions}
                clearRecentSearches={handleClearRecentSearches}
                isAutoComplete={isAutoComplete}
                mainCategory={searchCategory.value}
              />
            </SearchResults>
          )}
        </SearchFieldContainer>
      </SearchFieldPageContainer>
    </ObjectSearchFieldContainer>
  );
};

export default ObjectSearchField;
