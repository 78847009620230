import React from 'react';
import Link, { LinkProps } from '~/App/shared/components/Link';
import { BodyLink } from '@kvdbil/components';
import styled from 'styled-components';
import CookiesButtonLink from '~/App/components/Footer/CookiesButtonLink';
import { FooterNavigationData } from '~/App/shared/interfaces/store/CmsData';

declare global {
  interface Window {
    Didomi?: {
      preferences: {
        show: CallableFunction;
        isVisible: boolean;
        hide: CallableFunction;
      };
    };
  }
}

const showDidomiSettings = () => {
  window?.Didomi?.preferences.show();
};

const SubMenuItem = styled(BodyLink)`
  padding: 0.25rem 0;
  &:first-child {
    padding-top: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.gray.dark3};
  }
`;

export type Item = {
  url?: string;
  text: string;
  relationship?: string[];
};

interface SubMenuProps {
  items: NonNullable<FooterNavigationData['linkGroups']>[0]['links'];
}
const SubMenuItems = ({ items }: SubMenuProps) => {
  return (
    <>
      {items.map((item, key) => (
        <SubMenuItem key={key} as="li">
          {item?.alternativeActionKey === 'cookie_settings' ? (
            <CookiesButtonLink onClick={showDidomiSettings}>
              {item.text}
            </CookiesButtonLink>
          ) : (
            <Link
              to={item?.url?.trim() ?? '/'}
              customAttrs={
                item?.customLinkMeta?.filter(
                  ({ attrName, attrValue }) => attrName && attrValue
                ) as LinkProps['customAttrs']
              }
            >
              {item.text}
            </Link>
          )}
        </SubMenuItem>
      ))}
    </>
  );
};

export default SubMenuItems;
