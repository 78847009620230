import React from 'react';

import {
  Button,
  CaptionLink,
  Flex,
  UserIcon,
  SmileIcon,
  SignOutIcon
} from '@kvdbil/components';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { TextIconWithLinkRecord } from '~/config/generated/graphql';

import LanguageSelect from '~/App/shared/localization/components/LanguageSelect';
import { IncludeContent } from '~/App/shared/components/MediaQueryContainer';
import { isLoggedInSelector } from '~/App/shared/selectors/sessionSelector';
import { useTranslation } from '~/Locale';

import Link from '~/App/shared/components/Link';
import { openMenuDrawer } from '~/App/shared/actions/drawerMenu';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.gray};
`;

const UtilityBarContainer = styled(IncludeContent)`
  max-width: 1440px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ButtonLink = styled(Link)`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.dark} !important;
`;

const CreateAccountButton = styled(Button)`
  color: ${({ theme }) => theme.colors.text.dark};
`;

const LoginButton = styled(Button)`
  :hover {
    background-color: ${({ theme }) => theme.colors.secondary.main};
  }
`;

const ButtonLinkIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;

type UtilityBarProps = {
  textIconWithLinkList: TextIconWithLinkRecord[];
};

const UtilityBar = ({ textIconWithLinkList }: UtilityBarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);

  const handleLogin = () => {
    dispatch(openMenuDrawer('LOGIN'));
  };

  const handleRegister = () => {
    dispatch(openMenuDrawer('REGISTER'));
  };

  return (
    <Wrapper>
      <UtilityBarContainer from={'tablet'} display="flex">
        <LanguageSelect isPlacedInHeader />

        <Flex align={'center'} gap={0}>
          {textIconWithLinkList.map(({ textIcon, textLink }, index) => {
            const _textIcon = textIcon?.[0];
            const _textLink = textLink?.[0];

            return (
              <ButtonLink key={index} to={_textLink.url ?? '/'}>
                <ButtonLinkIcon
                  src={_textIcon?.icon?.url}
                  alt={_textIcon?.icon?.alt ?? ''}
                  width={'1rem'}
                  height={'1rem'}
                />
                <CaptionLink>{_textIcon?.text ?? ''}</CaptionLink>
              </ButtonLink>
            );
          })}

          {isLoggedIn ? (
            <>
              <ButtonLink
                data-testid="nav-my-account"
                to={'/ROUTES.MY_ACCOUNT/ROUTES.MY_ACCOUNT_SAVED_OBJECTS'}
              >
                <UserIcon />
                <CaptionLink>{t('My account')}</CaptionLink>
              </ButtonLink>

              <ButtonLink data-testid="nav-logout-button" to={`/ROUTES.LOGOUT`}>
                <SignOutIcon />
                <CaptionLink>{t('Log out')}</CaptionLink>
              </ButtonLink>
            </>
          ) : (
            <>
              <CreateAccountButton
                data-testid="nav-create-account-button"
                variant="flat"
                color="neutral"
                onClick={handleRegister}
                prefixIcon={<SmileIcon />}
              >
                <CaptionLink>{t('Create account')}</CaptionLink>
              </CreateAccountButton>

              <LoginButton
                data-testid="nav-login-button"
                variant="solid"
                color="secondary"
                onClick={handleLogin}
                prefixIcon={<UserIcon />}
              >
                <CaptionLink>{t('Sign in')}</CaptionLink>
              </LoginButton>
            </>
          )}
        </Flex>
      </UtilityBarContainer>
    </Wrapper>
  );
};

export default UtilityBar;
