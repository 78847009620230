/* eslint-disable max-lines */
import { pathOr, path, prop, always, evolve, when, applySpec } from 'ramda';
import { orchestration } from '~/config/public';
import { formatMoney, inclVATtoExclVAT } from '~/helpers/currency';
import { log } from '~/helpers/bugsnagHelper';
import { translateFilterValueString } from '~/helpers/auctionsFilter';
import { translatedFilterValue } from './filterTranslation';
import { getTranslatedEnginePower } from '~/helpers/processObject';
import ProcessObject, {
  Fact,
  ManufacturerWarrantyFact
} from '~/App/shared/interfaces/ProcessObject';
import BaseObject from '~/App/shared/interfaces/BaseObject';
import Auction, { AuctionType } from '~/App/shared/interfaces/Auction';
import { Bid } from '~/App/shared/interfaces/Bid';
import { ProcessedAuction } from '~/App/shared/interfaces/ProcessedAuction';
import { PreviewSpecifications } from '~/App/shared/interfaces/PreviewSpecifications';
import { Fuel } from '~/App/shared/interfaces/Fuel';
import { Session } from '~/App/shared/interfaces/store/Session';
import { isLoggedIn } from './member';
import { Image } from '~/App/views/Checkout/components/VehicleCard';
import { getBiddingFeeForMember } from './bid';
import {
  FINANCING_MINIMUM_AMOUNT,
  META_TITLE_MIN_BREAK_POINT,
  MONTHLY_COST_MONTHS,
  MONTHLY_COST_RESIDUAL_VALUE_MONTHS
} from '~/config/constants';
import { getTargetedAuctionGroupUrl } from './targetedAuctions';
import { TranslateFunction } from '~/Locale';
import { calculateFinance, getAllowedResidualLoan } from './calculator';

import dayjs from 'dayjs';
import { Member } from '~/App/shared/interfaces/Member';
import { getBaseCanonicalUrl } from './baseCanonicalUrl';
import { BaseObjectType } from '~/App/shared/types/BaseObjectType';
import { isHeavyEquipmentObject } from '~/App/views/ObjectPage/helpers';
import { formatOdometerReading } from './odometer';
import { Properties } from '~/App/shared/interfaces/Properties';
import { unionBy } from './unionBy';
import { isEmpty } from '~/App/shared/utils';

interface AuctionCosts {
  sellMethod: string;
  buyNowAmount: number;
  soldFor: number;
  soldForVat: number;
  winningBid: number;
  winningBidVat: number;
  biddingFee: number;
  biddingFeeVat: number;
  total: number;
  totalVat: number;
}

export const SOON_ON_KVDBIL_IMAGE_URL = '/images/soon.jpg';
export const SOON_ON_KVDBIL_PRESENTATION_IMAGE_URL =
  '/images/soon_presentation.jpg';

export const getAuctionCosts = (
  auction: Auction,
  member: Member
): AuctionCosts => {
  const vatFactor = 0.2;

  const winningBid = parseInt(`${auction?.winningBid?.amount ?? 0}`, 10);
  const soldFor = parseInt(`${auction?.soldFor ?? 0}`, 10);
  const sellMethod = auction?.sellMethod || '';
  const biddingFee = getBiddingFeeForMember(member, auction);
  const total = soldFor + biddingFee;

  const deductibleVat = auction?.processObject.deductibleVat;
  const soldForVat = deductibleVat ? soldFor * vatFactor : 0;

  const winningBidVat = deductibleVat ? winningBid * vatFactor : 0;
  const biddingFeeVat = biddingFee * vatFactor;
  const totalVat = soldForVat + biddingFeeVat;

  const buyNowAmount = auction?.buyNowAmount ?? 0;

  return {
    sellMethod,
    soldFor,
    soldForVat,
    winningBid,
    biddingFee,
    total,
    winningBidVat,
    biddingFeeVat,
    totalVat,
    buyNowAmount
  };
};

export const getCarDealerPrice = (auction: Auction) => {
  const carDealerPrice = auction?.processObject?.valuation?.carDealerPrice;
  return carDealerPrice && carDealerPrice > 0 ? carDealerPrice : null;
};

export const getCurrentBidText = (
  startBidAmount: number,
  leadingBidAmount: number
) => {
  return startBidAmount > 0 && leadingBidAmount === 0
    ? startBidAmount
    : leadingBidAmount;
};

export const getLeadingBid = (bids: Bid[]) => {
  const sortedBids = bids.sort((a, b) => b.id - a.id);

  if (sortedBids.length > 0) {
    return sortedBids?.[0];
  }

  // Return empty bid with amount if no bids exists.
  return;
};

export const getMinBidRaise = (bids: Bid[], minBidRaise = 0, startBid = 0) => {
  if (bids.length > 0) {
    const firstBid = bids?.[0];
    if (firstBid) {
      return firstBid.amount + minBidRaise;
    }
  }

  if (startBid > minBidRaise) {
    return startBid;
  }

  return minBidRaise;
};

interface ObjectWithProcessObject {
  processObject: ProcessObject;
}

export const getTitle = (object: ObjectWithProcessObject) => {
  const heading1 = object?.processObject?.heading1;

  if (heading1) {
    return heading1;
  }

  const title = object?.processObject?.title;

  if (title) {
    return title;
  }

  return '';
};

export const getPageTitle = (auction: Auction) => {
  const heading1 = auction?.processObject?.heading1;
  const heading2 = auction?.processObject?.heading2;

  if (heading1 && heading2) {
    const headings = `${heading1} ${heading2}`;
    // Sometimes heading1 and heading2 contains the model name,
    // thus we make sure there are no duplicates
    const titleWithoutDuplicates = [...new Set(headings.split(' '))].join(' ');
    return titleWithoutDuplicates;
  } else if (heading1 && !heading2) {
    return heading1;
  }

  const title = auction.processObject?.title;

  if (title) {
    return title;
  }

  return '';
};

export const getPreviewPreamble = (processObject: ProcessObject) => {
  const heading2 = processObject?.heading2 ?? '';
  return heading2;
};

export const getPreviewImage = (auction: Auction): string => {
  const fallbackImageUrl = `${orchestration.url}/auction/primary-image/${auction.processObjectId}`;
  const previewImage = isComingSoonAuction(auction)
    ? SOON_ON_KVDBIL_IMAGE_URL
    : auction.previewImage || fallbackImageUrl;
  return previewImage;
};

export const translateFuel = (t: TranslateFunction, fuels: Fuel[] = []) => {
  try {
    if (fuels.length > 1) {
      const fuel1 = pathOr('', [0, 'fuelCode'], fuels).toLowerCase();
      const fuel2 = pathOr('', [1, 'fuelCode'], fuels).toLowerCase();
      const fuelCodes = [fuel1, fuel2];

      if (fuelCodes.every(code => ['ethanol', 'petrol'].includes(code))) {
        return translatedFilterValue(t, 'petrol_ethanol');
      }
      if (fuelCodes.every(code => ['methane', 'petrol'].includes(code))) {
        return translatedFilterValue(t, 'petrol_methane');
      }
      if (fuelCodes.every(code => ['electric', 'petrol'].includes(code))) {
        return translatedFilterValue(t, 'electric_petrol');
      }
      if (fuelCodes.every(code => ['electric', 'diesel'].includes(code))) {
        return translatedFilterValue(t, 'electric_diesel');
      }
    }
    const fuel = pathOr('', [0, 'fuelCode'], fuels).toLowerCase();
    const fuelArray = ['petrol', 'diesel', 'electric', 'methane'];

    if (fuelArray.includes(fuel)) {
      return translatedFilterValue(t, fuel);
    }
  } catch (err: Error | unknown) {
    log(err);
  }

  return '';
};

const translateGearBoxes = (t: TranslateFunction) => ({
  automatic: t('Automatic'),
  manual: t('Manual')
});

export const translateGearbox = (
  t: TranslateFunction,
  gearbox: string
): string => {
  const translatedGearBoxes = translateGearBoxes(t);
  return (
    translatedGearBoxes[
      gearbox?.toLowerCase() as keyof typeof translatedGearBoxes
    ] ?? ''
  );
};

export const generatePreviewSpecifications = (
  t: TranslateFunction,
  processObject: ProcessObject,
  objectType: BaseObjectType
): PreviewSpecifications => {
  const year = processObject?.properties?.modelYear;
  const fuels = processObject?.properties?.fuels ?? [];

  const gearbox = processObject?.properties?.gearbox?.toLowerCase();
  const odometerReading = processObject?.properties?.odometerReading ?? 0;
  const odometerUnit = processObject?.properties?.odometerUnit ?? 'km';

  return {
    year,
    fuels: translateFuel(t, fuels),
    gearbox: translateGearbox(t, gearbox),
    odometerReading: formatOdometerReading(
      t,
      odometerReading,
      odometerUnit,
      objectType
    )
  };
};

interface ProcessAuction {
  auction: Auction;
  websocketBids?: Bid[];
  websocketAuctions?: {
    [key: string]: Auction;
  };
}

export const isComingSoonAuction = (auction: Auction): boolean => {
  const comingSoonFlag = auction.comingSoon || false;
  const notOpen = auction.state !== 'OPEN';
  return comingSoonFlag && notOpen;
};

export const isActiveReservation = (auction: Auction): boolean => {
  const activeReservation = auction.isReserved || false;
  const open = auction.state === 'OPEN';
  return activeReservation && open;
};

export const isSoldObject = (auction: Auction): boolean => {
  const isSoldByBuyNow = auction.isSoldByBuyNow || false;
  const notOpen = auction.state !== 'OPEN';
  return isSoldByBuyNow && notOpen;
};

export const processAuction = (
  t: TranslateFunction,
  { auction, websocketBids = [], websocketAuctions = {} }: ProcessAuction
): ProcessedAuction => {
  const auctionId = auction?.id;
  const auctionBids = auction?.activeAuction?.bids ?? [];
  const auctionWebsocketBids: Bid[] = websocketBids.filter(
    bid => bid.auctionId === auctionId
  );

  const websocketAuction = websocketAuctions[auctionId] || {};
  const releasing = auction?.releasing;
  const isReleasable = auction?.processObject?.isReleasable ?? false;

  const {
    bids: _,
    myMaxBid: __,
    ...activeAuction
  } = websocketAuction.activeAuction ?? {};

  const omittedWebsocketAuction = {
    ...websocketAuction,
    ...activeAuction
  };

  const currentAuction = {
    ...auction,
    ...omittedWebsocketAuction
  } as Auction;

  const previewSpecifications = generatePreviewSpecifications(
    t,
    auction?.processObject,
    auction?.processObject?.objectType
  );

  const concatenatedBids: Bid[] =
    unionBy([...auctionBids, ...auctionWebsocketBids], 'id')
      .filter(bid => bid !== null)
      .sort((a, b) => b.id - a.id) || [];

  const cancelledBids = websocketAuction.cancelledBids || [];
  const bids = concatenatedBids.filter(bid => !cancelledBids.includes(bid.id));

  const leadingBid = getLeadingBid(bids);

  const minBidRaise = getMinBidRaise(
    bids,
    auction.minBidRaise,
    auction.startBid
  );

  const numberOfBidders = new Set(bids.map(bid => bid.bidderAlias)).size;

  const previewTitle = getTitle(currentAuction);

  const previewImage = getPreviewImage(auction);
  const previewPreamble = getPreviewPreamble(auction?.processObject) || '';
  const previewPackage = auction?.processObject?.heading3 ?? '';

  const evolvedAuction = evolve({
    processObject: {
      media: when(
        always(isComingSoonAuction(currentAuction)),
        always([
          {
            contentType: 'image',
            type: 'PRESENTATION',
            uri: SOON_ON_KVDBIL_PRESENTATION_IMAGE_URL
          }
        ])
      )
    }
  })(currentAuction);

  const processedAuction: ProcessedAuction = {
    ...evolvedAuction,
    bids,
    releasing,
    leadingBid,
    minBidRaise,
    isReleasable,
    cancelledBids,
    numberOfBidders,
    previewImage,
    previewTitle,
    previewPackage,
    previewPreamble,
    previewSpecifications
  };

  return processedAuction;
};

export const processAuctionBuyNow = (auction: Auction) => {
  const previewImage = getPreviewImage(auction);
  const processedAuction: Auction = {
    ...auction,
    previewImage
  };

  return processedAuction;
};

export interface ProcessAuctionStates {
  auction: ProcessedAuction;
  session?: Session;
  wonAuctions?: number[];
}

export interface ProcessedAuctionStates {
  isBuyNow: boolean;
  isBuyNowAvailable: boolean;
  isWinner: boolean;
  isClosed: boolean;
  isLeading: boolean;
  isCertified: boolean;
  isComingSoon: boolean;
  isReleasable: boolean;
  isEcoFriendly: boolean;
  isCounting: boolean;
  isOverbidded: boolean;
  isParticipating: boolean;
  IsProcessObjectIdEven: boolean;
  isSoldByBuyNow: boolean;
}

const falsyProcessed = {
  isBuyNow: false,
  isBuyNowAvailable: false,
  isWinner: false,
  isClosed: false,
  isLeading: false,
  isCertified: false,
  isReleasable: false,
  isComingSoon: false,
  isEcoFriendly: false,
  isCounting: false,
  isOverbidded: false,
  isParticipating: false,
  IsProcessObjectIdEven: false,
  isSoldByBuyNow: false
};

const isAuctionClosed = (auction: Auction): boolean =>
  pathOr(false, ['activeAuction', 'closed'], auction);

export const processAuctionStates = ({
  auction,
  session,
  wonAuctions = []
}: ProcessAuctionStates): ProcessedAuctionStates => {
  if (!auction) {
    return falsyProcessed;
  }

  const { activeAuction } = auction;

  const bids = auction?.bids ?? [];

  const leadingBid = getLeadingBid(bids);
  const memberBids = [...bids].filter(
    bid => bid.bidderAlias === activeAuction?.myBidderAlias
  );
  const highestMemberBid = memberBids.sort((a, b) => b.amount - a.amount)[0];

  const sellMethod = auction?.sellMethod;

  const isBuyNow = auction.buyNow;
  const isBuyNowAvailable = auction.buyNowAvailable;
  const isSoldByBuyNow = auction.isSoldByBuyNow;

  const isClosed = isBuyNow
    ? auction.closedAt !== null
    : isAuctionClosed(auction);

  const winningBid = auction.activeAuction?.highestBid;
  const auctionId = auction?.id;
  let isWinner =
    (isClosed &&
      winningBid &&
      winningBid.id === highestMemberBid?.id &&
      sellMethod !== 'BUY_NOW') ||
    wonAuctions.includes(parseInt(auctionId, 10));

  let isParticipating = memberBids.length > 0;
  let isLeading =
    isParticipating && leadingBid?.bidderAlias === activeAuction?.myBidderAlias;
  let isOverbidded = !isLeading;

  if (!isLoggedIn(session) || isSoldByBuyNow) {
    isParticipating = false;
    isLeading = false;
    isOverbidded = false;
    isWinner = false;
  }

  const isComingSoon = isComingSoonAuction(auction);
  const isCertified = auction?.processObject?.certified ?? false;
  const isReleasable =
    auction?.processObject?.isReleasable &&
    (auction?.releasing?.monthlyCostStatus ?? 'INVALID') === 'VALID';
  const isEcoFriendly = auction?.processObject?.properties?.ecoFriendly;

  const { processObjectId } = auction;
  const IsProcessObjectIdEven = parseInt(processObjectId, 10) % 2 == 0;

  const isCounting = Boolean(activeAuction?.countdownInProgress && !isClosed);

  return {
    isBuyNow,
    isBuyNowAvailable,
    isWinner,
    isClosed,
    isLeading,
    isCertified,
    isComingSoon,
    isReleasable,
    isEcoFriendly,
    isCounting,
    isOverbidded,
    isParticipating,
    IsProcessObjectIdEven,
    isSoldByBuyNow
  };
};

export const processProcessObject = (
  t: TranslateFunction,
  processObject: ProcessObject
): ProcessObject => {
  const newProcessObject = { ...processObject };
  const previewSpecifications = generatePreviewSpecifications(
    t,
    processObject,
    processObject.objectType
  );

  const previewImage = `${orchestration.url}/auction/primary-image/${processObject.id}`;

  newProcessObject.previewImage = previewImage;
  newProcessObject.previewTitle = getTitle({ processObject: newProcessObject });
  newProcessObject.previewPreamble = getPreviewPreamble(newProcessObject) || '';
  newProcessObject.previewPackage = processObject?.heading3;
  newProcessObject.previewSpecifications = previewSpecifications;

  return isEmpty(processObject) ? ({} as ProcessObject) : newProcessObject;
};

export const prettySlug = (auction: Auction) => {
  let slug = auction?.slug ?? '';
  const auctionId = auction?.id;

  if (slug !== '') {
    slug = `${slug}-`;
  }

  return encodeURIComponent(`${slug}${auctionId}`);
};

export const getIdFromSlug = (uri = '') => {
  const uriParts = uri.split('/');
  const slug = uriParts[uriParts.length - 1];
  if (!slug) {
    return '';
  }

  const split = slug.split('-') || [];
  if (!split || split.length === 0) {
    return '';
  }

  const lastParam = split[split.length - 1];
  if (!lastParam) {
    return '';
  }

  const param = lastParam.split('?');
  if (!param) {
    return '';
  }

  return param?.[0] ?? '';
};

export const getCanonicalLink = (t: TranslateFunction, auction: Auction) => {
  const slug = prettySlug(auction);
  const isBidding = auction.auctionType === AuctionType.BIDDING;
  const route = isBidding ? t('ROUTES.AUCTIONS') : t('ROUTES.FIXED_PRICE');

  return {
    rel: 'canonical',
    href: getBaseCanonicalUrl(`/${route}/${slug}`)
  };
};

export const getProcessTimeStatuses = (auction: Auction) => {
  const isClosed = isAuctionClosed(auction);

  const rawPreliminaryCloseAt = path<string>(
    ['activeAuction', 'preliminaryCloseAt'],
    auction
  );
  const preliminaryCloseAt = dayjs(rawPreliminaryCloseAt);
  const now = dayjs().startOf('day');
  const diffDays = Math.abs(now.diff(preliminaryCloseAt, 'days'));
  const isTonight = diffDays === 0 && preliminaryCloseAt.hour() >= 17; // Today and after 17:00
  const isToday = diffDays === 0;
  const isTomorrow = diffDays === 1;
  const isSoon = diffDays > 1 && diffDays < 5 && !isTomorrow;
  const isFar = diffDays >= 5;

  return {
    isFar: isFar,
    isSoon: isSoon,
    isToday: isToday,
    isClosed: isClosed,
    isTonight: isTonight,
    isTomorrow: isTomorrow
  };
};
export type ProcessTimeStatuses = ReturnType<typeof getProcessTimeStatuses>;

export const isRecreationalVehicle = (vehicleType: BaseObjectType) => {
  return !['CAR', 'LIGHT_TRUCK'].includes(vehicleType);
};

// Conversion of engine effect in European HP to kW
const conversionConstant = 1.3596216;
export const fromHPToKW = (effectHP: number | string) =>
  typeof effectHP === 'string'
    ? Math.round(parseInt(effectHP, 10) / conversionConstant)
    : Math.round(effectHP / conversionConstant);

const getConsumptionUnit = (t: TranslateFunction, consumptionType: string) => {
  switch (consumptionType) {
    case 'CONSUMPTION_METHANE':
      return t('kbm/100km');
    default:
      return t('l/100km');
  }
};

// TODO: Remove when we no longer get fact type distance
export const overrideDistance = (odometerUnit: Properties['odometerUnit']) => {
  return (fact: Fact) => {
    if (fact.type !== 'distance') {
      return fact;
    }

    switch (odometerUnit) {
      case 'km':
        return {
          ...fact,
          type: 'kilometers'
        };
      case 'miles':
        return {
          ...fact,
          type: 'english_miles'
        };
      case 'mil':
        return {
          ...fact,
          type: 'scandinavian_miles'
        };
      case 'tim':
        return {
          ...fact,
          type: 'hours'
        };

      default:
        return fact;
    }
  };
};

export const formatFact = (
  t: TranslateFunction,
  fact: Fact | ManufacturerWarrantyFact,
  objectType: BaseObjectType
) => {
  switch (fact.type) {
    case 'manufacture_month': {
      const factValue = String(fact.value);
      if (Number(factValue) && factValue.length === 6) {
        const date = dayjs(factValue, 'YYYYMM');
        // Return as formatted time, e.g., "November 2019"
        return date.format('MMMM YYYY');
      } else {
        return fact.value;
      }
    }
    case 'manufacturer_warranty': {
      const f = fact as ManufacturerWarrantyFact;
      const warrantyDate = f.value.expirationDate;
      const warrantyDistance = f.value?.odometerReadingLimit ?? 0;

      const formattedDate = dayjs(warrantyDate).format('YYYY-MM-DD');
      const manufacturerDistance = formatOdometerReading(
        t,
        warrantyDistance,
        'km',
        objectType
      );

      if (warrantyDistance) {
        return `${formattedDate} / ${manufacturerDistance} ${t(
          'Preliminary data'
        )}`;
      }

      return `${formattedDate} ${t('Preliminary data')}`;
    }
    case 'distance': // old fallback
    case 'kilometers':
      return formatOdometerReading(t, fact.value, 'km', objectType);
    case 'english_miles':
      return formatOdometerReading(t, fact.value, 'miles', objectType);
    case 'scandinavian_miles':
      return formatOdometerReading(t, fact.value, 'mil', objectType);
    case 'hours':
      return formatMoney({
        value: fact.value,
        currency: t('unit.hours')
      });

    case 'date': {
      const preliminaryFacts = ['NEW_CAR_WARRANTY', 'COLLISION_WARRANTY'];

      if (preliminaryFacts.includes(fact.key)) {
        return `${dayjs(fact.value).format('YYYY-MM-DD')} ${t(
          'Preliminary data'
        )}`;
      }

      return `${dayjs(fact.value).format('YYYY-MM-DD')}`;
    }
    case 'weight':
      return formatMoney({
        value: fact.value,
        currency: t('unit.kilogram')
      });
    case 'length':
      return formatMoney({
        value: Number(fact.value),
        currency: t('unit.millimeter')
      });
    case 'emission':
      return formatMoney({
        value: fact.value,
        currency: t('unit.grams_per_kilometer')
      });
    case 'consumption':
      return formatMoney({
        value: fact.value,
        currency: getConsumptionUnit(t, fact.key),
        precision: 1
      });
    case 'engine_power':
      return `${fact.value} ${t('unit.horsepower')} (${fromHPToKW(
        fact.value
      )} ${t('unit.kilowatts')})`;
    case 'string':
      return fact.value;
    default:
      return null;
  }
};

/* Emarsys category helper */
export const getAuctionCategory = (auction: Auction) => {
  const baseObject = path<BaseObject>(['processObject', 'baseObject'], auction);

  if (!baseObject) {
    return '';
  }

  const baseObjectType = prop('baseObjectType', baseObject);
  const baseObjectBody = prop('body', baseObject);

  return `${baseObjectType} -> ${baseObjectBody}`;
};

export const getAuctionPreviewImageAltText = (
  t: TranslateFunction,
  processObject: ProcessObject
): string => {
  if (!processObject) {
    return '';
  }

  const { title, properties, objectType } = processObject;
  const strComponents = [title];
  const { odometerReading, odometerUnit, gearbox, exteriorColor, modelYear } =
    properties || {};

  /* Mileage */
  const mileage = formatOdometerReading(
    t,
    odometerReading,
    odometerUnit,
    objectType
  );
  strComponents.push(mileage);

  /* Gearbox */
  if (gearbox) {
    const translatedGearbox = translateGearbox(t, gearbox);
    strComponents.push(translatedGearbox);
  }

  /* Color */
  if (exteriorColor) {
    const translatedColor = translateFilterValueString(exteriorColor, t);
    strComponents.push(translatedColor);
  }

  /* Model Year */
  if (modelYear) {
    strComponents.push(`${modelYear}`);
  }

  return strComponents.join(' - ');
};

export const getAltTextFromProcessObject = (
  t: TranslateFunction,
  processObject: ProcessObject
) => {
  return getAuctionPreviewImageAltText(t, processObject);
};

export const getImageFromAuction = (auction: Auction): Image =>
  applySpec<Image>({
    src: prop('previewImage'),
    alt: getAuctionPreviewImageAltText
  })(auction);

export const getMetaFromAuction = (
  t: TranslateFunction,
  auction: Auction
): {
  metaTitle: string;
  metaDescription: string;
} => {
  const { brand, heading2, properties, objectType } =
    auction?.processObject ?? {};
  const enginePower = getTranslatedEnginePower(t, auction.processObject);

  let metaTitle = t('Used %s %s', brand, heading2 ?? '');

  if (!properties) {
    return {
      metaTitle,
      metaDescription: 'Unknown'
    };
  }

  const odometerReading = formatOdometerReading(
    t,
    properties.odometerReading,
    properties.odometerUnit,
    objectType
  );

  const titleProperties = [brand, heading2 ?? ''];

  if (odometerReading) {
    titleProperties.push(`| ${odometerReading}`);
  }

  if (properties?.operatingHours) {
    titleProperties.push(
      `| ${formatMoney({
        value: properties.operatingHours,
        currency: t('unit.hours')
      })}`
    );
  }

  if (enginePower) {
    titleProperties.push(`| ${enginePower}`);
  }

  if (metaTitle?.length < META_TITLE_MIN_BREAK_POINT) {
    metaTitle = t('Used %s %s %s %s', ...titleProperties);
  }

  const metaDescription = t(
    'A used %s %s with gearbox of type %s and %s motor with %s. The vehicle, from %s, also has %s seats and has been driven for %s ..',
    brand,
    heading2 ?? '',
    translateGearbox(t, properties?.gearbox),
    translateFuel(t, properties?.fuels),
    enginePower ?? 0,
    properties.modelYear,
    properties.numberOfSeats,
    odometerReading
  );

  return {
    metaTitle,
    metaDescription
  };
};

export const getAuctionLink = (auction: Auction) => {
  const slug = prettySlug(auction);
  const routeString = '/ROUTES.MY_ACCOUNT/ROUTES.MY_ACCOUNT_TARGETED_AUCTIONS';
  const isTargetedAuction = Boolean(auction.targetedAuctionGroupId);

  let auctionLink =
    auction.auctionType === AuctionType.BUY_NOW
      ? `/ROUTES.FIXED_PRICE/${slug}`
      : `/ROUTES.AUCTIONS/${slug}`;
  if (isTargetedAuction) {
    const targetedAuctionGroupUrl = getTargetedAuctionGroupUrl(auction);
    const link = `${routeString}/${targetedAuctionGroupUrl}/${slug}`;
    auctionLink = link;
  }

  return auctionLink;
};

export const getBuyNowLink = (auction: Auction) => {
  const slug = prettySlug(auction);
  return auction.auctionType === AuctionType.BUY_NOW
    ? `/ROUTES.FIXED_PRICE/${slug}/ROUTES.BUY_NOW`
    : `/ROUTES.AUCTIONS/${slug}/ROUTES.BUY_NOW`;
};

interface GetPrimaryFinancingRowOptions {
  t: TranslateFunction;
  hasNoBids: boolean;
  startingBid: number;
  leadingBidAmount: number;
}

interface FinancingRowReturnType {
  value: string;
  label: string;
}

export const getPrimaryFinancingRowFromAuction = (
  auction: Auction,
  options: GetPrimaryFinancingRowOptions
): FinancingRowReturnType => {
  const isComingSoon = isComingSoonAuction(auction);
  const { t, startingBid, hasNoBids, leadingBidAmount } = options;
  const hasStartBid = startingBid > 0;

  if (auction.sellMethod) {
    return {
      label: t('End bid:'),
      value: formatMoney({
        value: auction.soldFor ?? 0,
        currency: t('SEK')
      })
    };
  }

  if (isComingSoon) {
    return !auction.preliminaryPrice
      ? {
          label: t('Starting bid:'),
          value: t('Coming soon (price)')
        }
      : {
          label: t('Starting bid:'),
          value: formatMoney({
            value: auction.preliminaryPrice,
            currency: t('SEK')
          })
        };
  }

  if (!hasNoBids) {
    return {
      label: t('Leading bid:'),
      value: formatMoney({
        value: leadingBidAmount,
        currency: t('SEK')
      })
    };
  }

  if (hasNoBids && hasStartBid) {
    return {
      label: t('Starting bid:'),
      value: formatMoney({
        value: startingBid,
        currency: t('SEK')
      })
    };
  }

  return {
    label: t('No bids'),
    value: ''
  };
};

interface GetSecondaryFinancingRowOptions {
  t: TranslateFunction;
  globalInterestRate?: number;
  hasNoBids: boolean;
  startingBid: number;
  leadingBidAmount: number;
  isExclVat?: boolean;
}

export const getSecondaryFinancingRowFromAuction = (
  auction: ProcessedAuction,
  options: GetSecondaryFinancingRowOptions
): FinancingRowReturnType => {
  const { t, hasNoBids, leadingBidAmount, globalInterestRate } = options;
  const isComingSoon = isComingSoonAuction(auction);
  const withResidualValue = getAllowedResidualLoan(auction.processObject);
  const isHeavyEquipment = isHeavyEquipmentObject(auction?.processObject);

  const startingBid = auction?.startBid ?? 0;
  const hasStartBid = startingBid > 0;
  const hasDeductibleVat = auction?.processObject?.deductibleVat ?? false;
  const { eligibleForAdditionalServices } = auction;

  const showLoanInformation =
    eligibleForAdditionalServices &&
    (leadingBidAmount || startingBid) >= FINANCING_MINIMUM_AMOUNT &&
    !isHeavyEquipment;

  const showExclVat = isHeavyEquipment && hasDeductibleVat && options.isExclVat;

  const calculateLoan = (price: number) => {
    return calculateFinance({
      price,
      downPayment: price * 0.2,
      interestRate: globalInterestRate ?? 0,
      residualPercentage: withResidualValue ? 0.5 : 0,
      months: withResidualValue
        ? MONTHLY_COST_RESIDUAL_VALUE_MONTHS
        : MONTHLY_COST_MONTHS
    });
  };

  /* Handle coming soon */
  if (isComingSoon && (!auction.preliminaryPrice || isHeavyEquipment)) {
    return { label: t('Valuation coming shortly'), value: '' };
  }

  /* Handle coming soon */
  if (isComingSoon && auction.preliminaryPrice && !isHeavyEquipment) {
    return {
      label: t('Monthly cost from:'),
      value: formatMoney({
        value: calculateLoan(auction.preliminaryPrice),
        currency: t('SEK/month')
      })
    };
  }

  /* Handle displaying of excl VAT */
  if (showExclVat && !hasNoBids) {
    return {
      label: t('Bid excl VAT:'),
      value: formatMoney({
        value: inclVATtoExclVAT(leadingBidAmount),
        currency: t('SEK')
      })
    };
  }

  /* Handle displaying of excl VAT */
  if (showExclVat && hasNoBids && hasStartBid) {
    return {
      label: t('Starting bid excl VAT:'),
      value: formatMoney({
        value: inclVATtoExclVAT(startingBid),
        currency: t('SEK')
      })
    };
  }

  if (showLoanInformation) {
    const loanValue = calculateLoan(leadingBidAmount || startingBid);

    return {
      label: t('With car loan:'),
      value: formatMoney({ value: loanValue, currency: t('SEK/month') })
    };
  }

  return {
    label: '',
    value: ''
  };
};

export const getReducedPrice = (auction?: Auction) => {
  const isPriceReduced = Boolean(
    auction?.processObject?.reducedPrice &&
      auction?.processObject?.initialBuyNowAmount &&
      (auction?.isSoldByBuyNow || auction?.buyNowAvailable)
  );
  const initialPrice = auction?.processObject?.initialBuyNowAmount;
  return { isPriceReduced, initialPrice };
};
