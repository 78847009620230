import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { localizationSelector } from '~/App/shared/selectors/localization';
import { useFeatureFlagValue } from '~/App/components/FeatureFlags';
import { Locale } from '~/App/shared/localization/types';
import { getRedirectBaseUrl } from '~/App/shared/localization/helpers';
import { isKeyOfObject } from '~/App/shared/types/helpers';
import { BASE_URLS } from '~/config/constants';

export const useLanguageSelection = () => {
  const multilingualSiteOnOneDomain = useFeatureFlagValue(
    'multilingual-site-on-one-domain'
  );
  const { domainLocale } = useSelector(localizationSelector);

  const getRedirectUrl = useCallback(
    (locale: Locale, generatedPath: string) => {
      const baseUrl = getRedirectBaseUrl({
        requestedlocale: locale,
        domainLocale,
        originUrl: window.location.origin,
        multilingualSiteOnOneDomain
      });
      if (isKeyOfObject(locale, BASE_URLS) && !multilingualSiteOnOneDomain) {
        return `${baseUrl}${generatedPath}`;
      }
      const localePath = locale !== domainLocale ? `/${locale}` : '';
      return `${baseUrl}${localePath}${generatedPath}`;
    },
    [domainLocale, multilingualSiteOnOneDomain]
  );

  return { getRedirectUrl };
};
