import {
  Button,
  Caption,
  Message,
  Spacer,
  Title,
  Flex,
  BodyText
} from '@kvdbil/components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import CheckBoxWithControl from '~/App/shared/components/Fields/CheckBoxWithControl';
import { EmailField } from '~/App/shared/components/Fields/shared';
import { useGA4User } from '~/App/shared/hooks/useGA4User';
import { isAxiosError } from '~/helpers/axiosUtils';
import { trackGA4NewsletterSignup } from '~/helpers/client/ga4TrackEvent';
import { createSubscriber } from '~/helpers/orchestration/subscribe';
import { recursiveStringFind } from '~/helpers/validation';
import { useTranslation } from '~/Locale';
import { SubscriptionType } from '~/App/shared/interfaces/EmailSubscription';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const FormWrapper = styled.div`
  padding-top: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

interface FormValues {
  email: string;
  personalCarSubscription: boolean;
  heavyVehiclesSubscription: boolean;
}
interface Props {
  title?: string;
  description?: string;
  sendButton?: string;
}

const Newsletter = ({ title, description, sendButton: buttonText }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid }
  } = useForm<FormValues>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      email: '',
      personalCarSubscription: false,
      heavyVehiclesSubscription: false
    }
  });

  const ga4User = useGA4User();

  const isPersonalCarSubscriptionChecked = watch('personalCarSubscription');
  const isHeavyVehiclesSubscriptionChecked = watch('heavyVehiclesSubscription');

  const onSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const emailSubscriptionNames: SubscriptionType[] = [];
      const { email, personalCarSubscription, heavyVehiclesSubscription } =
        values;
      personalCarSubscription &&
        emailSubscriptionNames.push(SubscriptionType.WeeklyPersonalCar);
      heavyVehiclesSubscription &&
        emailSubscriptionNames.push(SubscriptionType.WeeklyHeavyVehicles);

      await createSubscriber({
        member: { email, emailSubscriptionNames }
      });

      setIsSuccess(true);

      trackGA4NewsletterSignup(ga4User);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        const errorMessage = recursiveStringFind(
          error.response?.data ?? { message: '' }
        );
        setError(errorMessage.join(', '));
      }
    }

    setIsLoading(false);
  };

  return (
    <Container>
      <Title as="h3">{title || t('Subscribe to our newsletter')}</Title>
      <Spacer height={0.5} />
      <Caption>
        {description || t('You can end your subscription at any time.')}
      </Caption>

      <FormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <EmailField control={control} t={t} />

          {isSuccess ? (
            <Message fullWidth withIcon type="success">
              {t('Thanks! You are now subscribed to our newsletter')}
            </Message>
          ) : (
            <Flex rowGap="0.5rem" direction="column">
              <BodyText>
                {t('I am interested in newsletters about...')}
              </BodyText>
              <CheckBoxWithControl
                control={control}
                name="personalCarSubscription"
                label={t('Personal car')}
              />
              <CheckBoxWithControl
                control={control}
                name="heavyVehiclesSubscription"
                label={t('Heavy vehicles & machines')}
              />
            </Flex>
          )}

          <Button
            isLoading={isLoading}
            fullWidth
            type="submit"
            color="secondary"
            size="regular"
            isDisabled={
              !isValid ||
              !(
                isPersonalCarSubscriptionChecked ||
                isHeavyVehiclesSubscriptionChecked
              )
            }
          >
            {buttonText || t('Subscribe')}
          </Button>
        </Form>
      </FormWrapper>

      {error && (
        <>
          <Spacer />
          <Message withIcon type="error">
            {error}
          </Message>
        </>
      )}
    </Container>
  );
};

export default Newsletter;
