import Auction, { AuctionType } from '~/App/shared/interfaces/Auction';
import { useTranslation } from '~/Locale';
import { useSelector } from 'react-redux';
import { session as sessionSelector } from '~/App/shared/selectors/sessionSelector';
import { allBids as allBidsSelector } from '~/App/shared/selectors/storeObject';
import { wonAuctions as wonAuctionsSelector } from '~/App/shared/selectors/wonAuctions';
import {
  websocketAuctionsById,
  websocketBidsById
} from '~/App/shared/selectors/websockets';
import { globalInterestRate as globalInterestRateSelector } from '~/App/shared/selectors/finance';
import {
  getAuctionPreviewImageAltText,
  getPrimaryFinancingRowFromAuction,
  getProcessTimeStatuses,
  getReducedPrice,
  getSecondaryFinancingRowFromAuction,
  isActiveReservation,
  isComingSoonAuction,
  isSoldObject,
  processAuction,
  processAuctionStates,
  SOON_ON_KVDBIL_IMAGE_URL
} from '~/helpers/auction';
import { formatMoney, inclVATtoExclVAT } from '~/helpers/currency';
import { calculateFinance, getAllowedResidualLoan } from '~/helpers/calculator';
import {
  MONTHLY_COST_MONTHS,
  MONTHLY_COST_RESIDUAL_VALUE_MONTHS
} from '~/config/constants';
import { FinancingProps } from '~/App/shared/components/ProductCard/types';
import { Media } from '~/App/shared/interfaces/ProcessObject';
import { PreviewImage } from '~/App/shared/interfaces/PreviewImage';
import { isHeavyEquipmentObject } from '~/App/views/ObjectPage/helpers';
import { useFeatureFlagValue } from '~/App/components/FeatureFlags';
import useShowExclVat from '~/App/shared/hooks/useShowExclVat';
import { unionBy } from '~/helpers/unionBy';

const getAuctionImageURI = (media: Media[] | PreviewImage[], index = 0) => {
  return media?.filter(
    m => m.contentType === 'image' && m.type === 'PRESENTATION'
  )?.[index]?.uri;
};

const useProductCardBaseProps = (auction: Auction) => {
  const { t } = useTranslation();
  const session = useSelector(sessionSelector);
  const allBids = useSelector(allBidsSelector);
  const wonAuctions = useSelector(wonAuctionsSelector);
  const websocketBids = useSelector(websocketBidsById);
  const websocketAuctions = useSelector(websocketAuctionsById);
  const globalInterestRate = useSelector(globalInterestRateSelector);
  const isExclVat = useFeatureFlagValue('p2c-excl-vat');
  const isHeavyEquipment = isHeavyEquipmentObject(auction?.processObject);

  const showExclVat = useShowExclVat(auction?.processObject);

  const processedAuction = processAuction(t, {
    auction: auction || {},
    websocketBids: unionBy(
      [...Object.values(websocketBids ?? {}), ...allBids],
      'id'
    ),
    websocketAuctions: websocketAuctions
  });

  const auctionStates = processAuctionStates({
    auction: processedAuction,
    session,
    wonAuctions: wonAuctions
  });

  const isComingSoon = isComingSoonAuction(processedAuction);
  const auctionImage = isComingSoon
    ? SOON_ON_KVDBIL_IMAGE_URL
    : getAuctionImageURI(auction.previewImages) ??
      getAuctionImageURI(processedAuction.previewImages) ??
      processedAuction.previewImage;

  const isFixedPriceOnly = auction.auctionType === AuctionType.BUY_NOW;
  const isReserved = isActiveReservation(processedAuction);
  const isSold = isSoldObject(processedAuction);
  const isSoldByFixedPrice = isSold && processedAuction.isSoldByBuyNow;
  const { isPriceReduced, initialPrice } = getReducedPrice(processedAuction);

  const imageProps = {
    auctionId: auction.id,
    imageSrc: auctionImage,
    imageSrcHover: isComingSoon
      ? auctionImage
      : getAuctionImageURI(auction.previewImages, 1) ??
        getAuctionImageURI(processedAuction.previewImages, 1) ??
        processedAuction.previewImage,
    imageAlt: getAuctionPreviewImageAltText(t, processedAuction.processObject),
    isFixedPriceOnly,
    headerProps: {
      isComingSoon,
      isReserved,
      isSold,
      isSoldByFixedPrice,
      isPriceReduced,
      isCounting: auctionStates.isCounting,
      preliminaryCloseAt: processedAuction?.activeAuction?.preliminaryCloseAt,
      processTimeStatuses: getProcessTimeStatuses(auction)
    }
  };

  const isResidualValue = getAllowedResidualLoan(
    processedAuction.processObject
  );
  const fromLoanCost = calculateFinance({
    price: processedAuction.buyNowAmount ?? 0,
    interestRate: globalInterestRate,
    downPayment: (processedAuction.buyNowAmount ?? 0) * 0.2,
    months: isResidualValue
      ? MONTHLY_COST_RESIDUAL_VALUE_MONTHS
      : MONTHLY_COST_MONTHS,
    residualPercentage: isResidualValue ? 0.5 : 0
  });

  const financingProps: FinancingProps = {
    rows: [],
    color: auction.auctionType === AuctionType.BIDDING ? 'primary' : 'secondary'
  };
  if (auction.auctionType === AuctionType.BIDDING && !auction.isSoldByBuyNow) {
    const { leadingBid } = processedAuction;
    const leadingBidAmount = leadingBid?.amount ?? 0;
    const startingBid = processedAuction.startBid ?? 0;

    const primary = getPrimaryFinancingRowFromAuction(processedAuction, {
      t,
      leadingBidAmount,
      hasNoBids: !leadingBid,
      startingBid
    });

    const secondary = getSecondaryFinancingRowFromAuction(processedAuction, {
      t,
      hasNoBids: !leadingBid,
      startingBid,
      leadingBidAmount,
      globalInterestRate,
      isExclVat
    });

    financingProps.rows.push({
      label: primary.label,
      value: primary.value,
      isPrice: true
    });

    financingProps.rows.push({
      label: secondary.label,
      value: secondary.value,
      isPrice: false
    });
  }

  if (
    auctionStates.isBuyNowAvailable ||
    (auctionStates.isBuyNow && auction.isSoldByBuyNow)
  ) {
    financingProps.rows.push({
      label: `${t('Buy direct')}:`,
      value: formatMoney({
        value: processedAuction.buyNowAmount ?? 0,
        currency: t('SEK')
      }),
      isPrice: true,
      initialPrice: isPriceReduced ? initialPrice : null
    });

    if (!isHeavyEquipment) {
      financingProps.rows.push({
        label: t('With car loan:'),
        value: formatMoney({
          value: fromLoanCost,
          currency: t('SEK/month')
        }),
        isPrice: false
      });
    }

    if (showExclVat) {
      financingProps.rows.push({
        label: t('Price excl VAT:'),
        value: formatMoney({
          value: inclVATtoExclVAT(processedAuction?.buyNowAmount),
          currency: t('SEK')
        }),
        isPrice: false
      });
    }
  }

  return { imageProps, financingProps, processedAuction };
};

export type UseProductCardBaseReturnType = ReturnType<
  typeof useProductCardBaseProps
>;

export default useProductCardBaseProps;
