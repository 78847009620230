import { Button } from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import { useSelector } from 'react-redux';
import { showDato as showDatoSelector } from '../selectors/kvdHelperSelectors';
import { useFeatureFlag } from '~/App/components/FeatureFlags';
import { useTranslation } from '~/Locale';

const Container = styled.div`
  z-index: 0;

  #dato-btn {
    display: none;
  }

  .heroImage {
    img {
      z-index: -1;
    }
  }

  &:hover {
    outline: 4px solid #f65a41;
    outline-offset: -4px;

    #dato-btn {
      display: block;
    }
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  margin: 1rem;
  background-color: #f65a41;
  z-index: 9;
`;

function KvdHelperWrapper({
  children,
  url
}: {
  children: React.ReactNode;
  url: string;
}) {
  const { t } = useTranslation();
  const showDato = useSelector(showDatoSelector);
  const { value: showKvdHelper } = useFeatureFlag('kvd-helper');

  if (!showDato || !showKvdHelper) {
    return <>{children}</>;
  }

  return (
    <Container>
      <Link to={url} target="_blank" rel="noopener noreferrer">
        <StyledButton id="dato-btn">{t('Go to Dato')}</StyledButton>
      </Link>
      {children}
    </Container>
  );
}

export default KvdHelperWrapper;
