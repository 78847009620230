import {
  PageByNameQuery,
  AllCategoriesQuery,
  CategoryBySlugQuery,
  ArticlesByCategoryAndSlugWithRichTextQuery,
  SearchAllArticlesQuery,
  ArticlesForCategoryQuery,
  ArticlesByCategoriesQuery,
  ArticlesBySlugsQuery
} from '~/config/generated/graphql';

export const handleBlogStartPage = ({ page }: PageByNameQuery) => {
  if (!page) {
    return null;
  }
  return {
    name: page?.name,
    seo: page?.seo,
    optionalHero: page?.optionalHero,
    allReferencingCategoryOrders: page?._allReferencingCategoryOrders
  };
};

export const handleBlogAllCategories = ({
  allCategories
}: Partial<AllCategoriesQuery>) => {
  if (!allCategories) {
    return null;
  }
  return {
    allCategories: allCategories?.map(({ slug, name, id }) => ({
      slug,
      name,
      id
    }))
  };
};

export const handleBlogCategoryBySlug = ({ category }: CategoryBySlugQuery) => {
  if (!category) {
    return { notFound: true };
  }
  return {
    id: category?.id,
    name: category?.name,
    slug: category?.slug,
    optionalHero: category?.optionalHero,
    structuredDescription: category?.structuredDescription,
    seo: category?.seo
  };
};

export const handleBlogArticlesByCategoryAndSlug = ({
  article
}: ArticlesByCategoryAndSlugWithRichTextQuery) => {
  if (!article) {
    return { notFound: true };
  }
  return {
    title: article?.title,
    additionalCategories: article?.additionalCategories,
    seo: article?.seo,
    miniatureImage: article?.miniatureImage,
    newsletterSignupSection: article?.newsletterSignupSection,
    articleimage: article?.articleimage,
    _firstPublishedAt: article?._firstPublishedAt,
    premable: article?.premable,
    body: article?.body
  };
};

export const handleBlogArticles = ({
  allArticles
}: Partial<
  | ArticlesForCategoryQuery
  | ArticlesByCategoriesQuery
  | SearchAllArticlesQuery
  | ArticlesBySlugsQuery
>) => {
  return {
    allArticles: allArticles?.map(
      ({ title, mainCategory, _firstPublishedAt, slug, miniatureImage }) => ({
        title,
        mainCategory,
        _firstPublishedAt,
        slug,
        miniatureImage
      })
    )
  };
};
