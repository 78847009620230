import {
  SellFlowStartContentQuery,
  SellFlowInterestFormContentQuery,
  SellFlowHeavyEquipmentInterestFormContentQuery,
  SellFlowConfirmationViewContentQuery,
  SellFlowDynamicItemRecord,
  SellFlowReservationViewContentQuery,
  SellFlowTermsAndConditionsViewContentQuery
} from '~/config/generated/graphql';

export const handleSellFlowStartPage = ({
  sellFlowPage
}: SellFlowStartContentQuery) => {
  return sellFlowPage;
};

export const handleSellFlowInterestForm = ({
  sellFlowPage
}: SellFlowInterestFormContentQuery) => {
  return {
    vehicleInfo: {
      heading: sellFlowPage?.vehicleHeading,
      inputFieldPlaceholder: sellFlowPage?.vehicleInputfieldPlaceholder,
      introduction: sellFlowPage?.vehicleIntroduction,
      primaryButton: sellFlowPage?.vehiclePrimaryButton
    },
    odometer: {
      heading: sellFlowPage?.odometerHeading,
      introduction: sellFlowPage?.odometerIntroduction,
      placeholder: sellFlowPage?.odometerPlaceholder,
      primaryButton: sellFlowPage?.odometerPrimaryButton,
      secondaryButton: sellFlowPage?.odometerSecondaryButton,
      additionalInfo: sellFlowPage?.odometerAdditionalInfo,
      yearlyMaxMileageError: sellFlowPage?.odometerYearlyMaxError,
      maxMileageError: sellFlowPage?.odometerMaxMileageError
    },
    trimOptions: {
      heading: sellFlowPage?.trimHeading,
      introduction: sellFlowPage?.trimIntroduction,
      primaryButton: sellFlowPage?.trimPrimaryButton,
      dontKnowPackageInfo: sellFlowPage?.trimDontKnowPackageInfoText,
      packageTitle: sellFlowPage?.trimPackageTitle,
      addonsTitle: sellFlowPage?.trimAddonsTitle,
      primaryButtonManualValuation:
        sellFlowPage?.trimPrimaryButtonManualValuation,
      gatekeeperHeading: sellFlowPage?.trimGatekeeperHeading,
      gatekeeperTermsCheckbox: sellFlowPage?.trimGatekeeperTermsCheckbox
    },
    salesMethod: {
      heading: sellFlowPage?.salesHeading,
      headingSingleOption: sellFlowPage?.salesHeadingSingleOption,
      expressHeading: sellFlowPage?.salesExpressHeading,
      expressManualValuation: sellFlowPage?.salesExpressManualValuation,
      expressInfo: sellFlowPage?.salesExpressInfo,
      expressInfoExtra: sellFlowPage?.salesExpressInfoExtra,
      expressCheckItem1: sellFlowPage?.salesExpressCheckItem1,
      expressCheckItem2: sellFlowPage?.salesExpressCheckItem2,
      expressCheckItem3: sellFlowPage?.salesExpressCheckItem3,
      biddingHeading: sellFlowPage?.salesBiddingHeading,
      biddingManualValuation: sellFlowPage?.salesBiddingManualValuation,
      biddingInfo: sellFlowPage?.salesBiddingInfo,
      biddingCheckItem1: sellFlowPage?.salesBiddingCheckItem1,
      biddingCheckItem2: sellFlowPage?.salesBiddingCheckItem2,
      biddingCheckItem3: sellFlowPage?.salesBiddingCheckItem3,
      ctaButtonText: sellFlowPage?.salesCtaButtonText
    },
    contactForm: {
      headingOne: sellFlowPage?.contactHeadingOne,
      confirmationText: sellFlowPage?.contactConfirmationText,
      headingTwo: sellFlowPage?.contactHeadingTwo,
      informationText: sellFlowPage?.contactInformationText,
      expressInformation: sellFlowPage?.contactExpressInformationText,
      buttonText: sellFlowPage?.contactButtonText,
      buttonTextNotComplete: sellFlowPage?.contactButtonTextNotComplete,
      formHeaderRequiresManual: sellFlowPage?.contactFormHeaderRequiresManual,
      formHeaderRecreational: sellFlowPage?.contactFormHeaderRecreational,
      formHeaderConsumer: sellFlowPage?.contactFormHeaderConsumer,
      formHeaderCorporate: sellFlowPage?.contactFormHeaderCorporate,
      formHeaderHeavy: sellFlowPage?.contactFormHeaderHeavy,
      formHeaderOther: sellFlowPage?.contactFormHeaderOther
    },
    noticeOfInterest: {
      introduction: sellFlowPage?.noticeOfInterestIntroduction,
      equipmentTextareaHelperText:
        sellFlowPage?.noticeOfInterestEquipmentTextareaHelperText ?? '',
      extraTiresCheckboxLabel:
        sellFlowPage?.noticeOfInterestExtraTiresCheckboxLabel ?? ''
    },
    thankYou: {
      title: sellFlowPage?.thankYouTitle,
      consumer: sellFlowPage?.thankYouConsumer,
      manualValuation: sellFlowPage?.thankYouManualValuation,
      company: sellFlowPage?.thankYouCompany,
      express: sellFlowPage?.thankYouExpress
    },
    companyInformation: {
      title: sellFlowPage?.companyTitle,
      description: sellFlowPage?.companyDescription,
      ctaText: sellFlowPage?.companyCtaText,
      ctaLinkDestination: sellFlowPage?.companyCtaLinkDestination
    },
    prefilled: {
      heading: sellFlowPage?.prefilledHeading,
      introduction: sellFlowPage?.prefilledIntroduction,
      buttonText: sellFlowPage?.prefilledButtonText
    }
  };
};

export const handleSellFlowHeavyEquipmentInterestForm = ({
  sellFlowPage
}: SellFlowHeavyEquipmentInterestFormContentQuery) => {
  return {
    heavyEquipmentInterestForm: {
      heading: sellFlowPage?.heavyEquipmentHeading,
      introductionStructuredText: sellFlowPage?.heavyEquipmentIntroduction,
      terms: sellFlowPage?.heavyEquipmentTerms,
      thankYouHeading: sellFlowPage?.heavyEquipmentThankYouHeading,
      thankYouDescriptionStructuredText:
        sellFlowPage?.heavyEquipmentThankYouDescription
    }
  };
};

export const handleSellFlowConfirmationView = ({
  sellFlowPage
}: SellFlowConfirmationViewContentQuery) => {
  return {
    confirmation: {
      explanations: {
        ...arrayToObject(
          sellFlowPage?.confirmationExplanations as SellFlowDynamicItemRecord[]
        )
      }
    }
  };
};

export const handleSellFlowReservationView = ({
  sellFlowPage
}: SellFlowReservationViewContentQuery) => {
  return {
    thanksHeading: sellFlowPage?.reservationThanksHeading,
    upperExplanation: sellFlowPage?.reservationUpperExplanation,
    notSureUpperExplanation: sellFlowPage?.reservationNotSureUpperExplanation,
    heading: sellFlowPage?.reservationHeading,
    explanation: sellFlowPage?.reservationExplanation,
    valuationHeading: sellFlowPage?.reservationValuationHeading,
    manualValuationHeading: sellFlowPage?.reservationManualValuationHeading,
    setManualPriceButton: sellFlowPage?.reservationSetManualPriceButton,
    setRecommendedPriceButton:
      sellFlowPage?.reservationSetRecommendedPriceButton,
    readyToContinueHeading: sellFlowPage?.reservationReadyToContinueHeading,
    readyToContinueExplanation:
      sellFlowPage?.reservationReadyToContinueExplanation,
    headingReservationAccepted:
      sellFlowPage?.reservationHeadingReservationAccepted,
    explanationReservationAccepted:
      sellFlowPage?.reservationExplanationReservationAccepted
  };
};

export const handleSellFlowTermsAndConditionsView = ({
  sellFlowPage
}: SellFlowTermsAndConditionsViewContentQuery) => {
  return {
    termsDownloadButtonText: sellFlowPage?.termsDownloadButtonText,
    dynamicItems: {
      ...arrayToObject(
        sellFlowPage?.termsDynamicItems as SellFlowDynamicItemRecord[]
      )
    }
  };
};

const arrayToObject = (items: SellFlowDynamicItemRecord[] | undefined) => {
  return items?.reduce((acc, curr, i) => {
    const { key, ...obj } = curr;
    acc[`${key ?? i}`] = obj;
    return acc;
  }, {} as Record<string, Partial<SellFlowDynamicItemRecord>>);
};
