import React from 'react';
import {
  CabIcon,
  CoupeIcon,
  MinivanIcon,
  Pickup,
  SedanIcon,
  SuvIcon,
  WagonIcon
} from '@kvdbil/components';
import {
  buyMethodTypes,
  environmentalFuelOptions,
  FilterValue,
  regularFuelOptions,
  FilterSearchParamKey,
  FilterSearchParamsValue,
  VehicleBodyType,
  vehicleBodyTypes,
  CategoryOption,
  CountyOptions
} from '~/App/shared/types/FilterSearchParam';

import { TranslateFunction } from '~/Locale';
import { sortBy } from './sortBy';

import { formatMoney } from './currency';
import { isFilterSearchParamValue } from './filterSearchParams';
import { MinMaxIntervalKey } from '~/config/constants';
import store from '~/config/store';
import { facilityFilterOptionsSelector } from '~/App/shared/selectors/storeObjectsSelectors';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { VehicleTypes } from '~/App/shared/types/VehicleType';
import { SliderType } from '~/App/views/FilterPage/components/FilterSelector/types';

export const createFilterOption = (
  t: TranslateFunction,
  label: string,
  value: string,
  searchParam: FilterSearchParamKey
): FilterValue => ({
  label: capitalizeStr(translatedFilterValue(t, label)),
  value: value,
  searchParamKey: searchParam
});

export const translatedFilterValue = (
  t: TranslateFunction,
  filterValue: unknown
): string => {
  if (typeof filterValue !== 'string') {
    return String(filterValue);
  }

  const timedFilterValue = filterValue
    .trim()
    .replace(' ', '_')
    .toLocaleLowerCase();

  const translatedValues: {
    [K in FilterSearchParamsValue]: string;
  } = {
    // mainCategories

    car: t('Car'),
    light_transport: t('Van and pickup'),
    heavy_transport: t('Heavy transport'),
    machines: t('Machine'),
    recreational: t('Recreational vehicle & trailer'),
    transport_vehicle: t('Transport vehicles'),
    // subCategories
    light_truck: t('Van and pickup'),
    light_trailer: t('Trailer'),
    light_transport_other: t('Other'),
    heavy_truck: t('Heavy truck'),
    heavy_trailer: t('Heavy trailer'),
    heavy_transport_other: t('Other'),
    construction_machine: t('Construction machine'),
    excavator: t('Excavator'),
    dumper_hauler: t('Dumper/Hauler'),
    loading_machine: t('Loading machine'),
    motorized_equipment: t('Motorized equipment'),
    compact_tractor: t('Compact tractor'),
    riding_mower: t('Riding mower'),
    tractor: t('Tractor'),
    machine_other: t('Other'),
    trailer: t('Trailer'),
    mc_moped: t('MC/Moped'),
    camper: t('Camper'),
    mobile_home: t('Mobile home'),
    recreational_other: t('Other'),
    lighttruck: t('Van and pickup'),
    recreationalvehicle: t('Recreational vehicle & trailer'),
    recreational_vehicle: t('Recreational vehicle & trailer'),
    caravan: t('Caravan'),
    boat: t('Boat'),
    other: t('Other'),
    // bodyTypes
    suv: t('suv'),
    sedan: t('sedan'),
    wagon: t('wagon'),
    coupe: t('coupe'),
    minivan: t('minivan'),
    convertible: t('convertible'),
    'pick-up': t('Pickup'),
    // colors
    red: t('red'),
    cyan: t('cyan'),
    gray: t('gray'),
    grey: t('gray'),
    blue: t('blue'),
    green: t('green'),
    white: t('white'),
    black: t('black'),
    yellow: t('yellow'),
    orange: t('orange'),
    silver: t('silver'),
    purple: t('purple'),
    brown: t('brown'),
    // fuels
    hybrid: t('hybrid'),
    petrol: t('petrol'),
    diesel: t('diesel'),
    methane: t('methane'),
    ethanol: t('ethanol'),
    electric: t('electric'),
    electric_hybrid: t('electric_hybrid'),
    electric_diesel: t('electric/diesel'),
    electric_petrol: t('electric/petrol'),
    plugin_hybrid: t('plugin_hybrid'),
    petrol_lpg: t('petrol/lpg'),
    petrol_ethanol: t('petrol/ethanol'),
    petrol_methane: t('petrol/methane'),
    // equipment
    towbar: t('Towbar'),
    cabin_heater: t('Cabin heater'),
    full_leather: t('Full leather'),
    parking_assist: t('Parking assist'),
    panoramic_roof: t('Panoramic roof'),
    awd: t('AWD'),
    gps_navigation: t('GPS navigation'),
    automatic_folding_mirrors: t('Automatic folding mirrors'),
    // gearbox
    manual: t('manual'),
    automatic: t('automatic'),
    // Buy methods
    buy_now: t('Buy now'),
    bidding: t('Bidding'),
    // other
    certified: t('Certified'),
    eco_friendly: t('Eco friendly'),
    exportable: t('Exportable'),
    countdown_date: t('Tonight'),
    deductible_vat: t('VAT/LEASING'),
    releasable: t('Releasable'),
    reduced_price: t('Reduced price')
  } as const;

  if (isFilterSearchParamValue(timedFilterValue)) {
    return translatedValues[timedFilterValue];
  }

  return capitalizeStr(filterValue);
};

export const getFacilityName = (value: string) => {
  const state = store.getState();
  const facilities = facilityFilterOptionsSelector(state);

  const facility = facilities.find(facility => facility.value === value);

  return facility?.label || value;
};

export const TranslateLabels = (t: TranslateFunction, key: string): string => {
  const labels = {
    fuel: 'none',
    terms: 'none',
    brand: 'none',
    color: 'none',
    certified: t('Certified'),
    buyNow: t('Buy now'),
    releasable: t('Releasable'),
    ecoFriendly: t('Eco friendly'),
    bid: t('Bid'),
    bidTo: t('Bid to'),
    hpTo: t('To'),
    hpFrom: t('From'),
    yearTo: t('To'),
    gradeTo: t('Score to'),
    bidFrom: t('Bid from'),
    gearbox: 'none',
    yearFrom: t('From'),
    facility: 'none',
    numSeats: t('Seats'),
    metallic: t('Metallic'),
    gradeFrom: t('Score from'),
    equipment: 'none',
    familyName: 'none',
    exportable: t('Exportable'),
    vehicleBody: 'none',
    vehicleType: 'none',
    countdownDate: t('Tonight'),
    deductibleVat: t('VAT/LEASING'),
    reducedPrice: t('Reduced price'),
    cardealerPrice: 'none',
    cardealerPriceTo: t('To'),
    cardealerPriceFrom: t('From'),
    odometerReadingTo: t('To'),
    odometerReadingFrom: t('From'),
    operatingHoursTo: t('To'),
    operatingHoursFrom: t('From'),
    buyNowAmount: 'none',
    buyNowAmountTo: t('To'),
    buyNowAmountFrom: t('From'),
    co2To: t('To'),
    co2From: t('From'),
    loanCostTo: t('To'),
    loanCostFrom: t('From'),
    releasingCostTo: t('To'),
    releasingCostFrom: t('From'),
    auctionType: 'none'
  } as const;

  return labels[key as keyof typeof labels];
};

export const capitalizeStr = (str: string) => {
  const exceptions = { bmw: 'BMW' } as const;

  if (typeof str !== 'string') {
    return '';
  }

  if (str in exceptions) {
    return exceptions[str as keyof typeof exceptions];
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getVehicleIconFromBodyType = (
  vehicleBodyType: VehicleBodyType
) => {
  switch (vehicleBodyType.toLowerCase()) {
    case 'sedan':
      return <SedanIcon />;
    case 'wagon':
      return <WagonIcon />;
    // case 'hatchback':
    //   return <HatchbackIcon />;
    case 'suv':
      return <SuvIcon />;
    case 'coupe':
      return <CoupeIcon />;
    case 'convertible':
      return <CabIcon />;
    case 'minivan':
      return <MinivanIcon />;
    case 'pick-up':
      return <Pickup />;
    default:
      return <CoupeIcon />;
  }
};

export const getVehicleBodyTypes = (t: TranslateFunction) =>
  vehicleBodyTypes
    .map(value => ({
      ...createFilterOption(t, value, value, 'vehicleBody'),
      icon: getVehicleIconFromBodyType(value)
    }))
    .sort(sortBy('label'));

export const getObjectTypeOptions = (
  t: TranslateFunction,
  mainCategory: MainCategory
): FilterValue[] => {
  switch (mainCategory) {
    case 'car':
      return [
        {
          label: t('Car'),
          value: 'car',
          searchParamKey: 'objectTypes'
        }
      ];

    case 'light_transport':
      return [
        {
          label: t('Light truck'),
          value: 'light_truck',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Light trailer'),
          value: 'light_trailer',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Light transport other'),
          value: 'light_transport_other',
          searchParamKey: 'objectTypes'
        }
      ];

    case 'heavy_transport':
      return [
        {
          label: t('Heavy truck'),
          value: 'heavy_truck',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Heavy trailer'),
          value: 'heavy_trailer',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Heavy transport other'),
          value: 'heavy_transport_other',
          searchParamKey: 'objectTypes'
        }
      ];

    case 'machines':
      return [
        {
          label: t('Construction machine'),
          value: 'construction_machine',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Excavator'),
          value: 'excavator',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Dumper/Hauler'),
          value: 'dumper_hauler',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Loading machine'),
          value: 'loading_machine',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Motorized equipment'),
          value: 'motorized_equipment',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Compact tractor'),
          value: 'compact_tractor',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Riding lawn mower'),
          value: 'riding_mower',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Tractor'),
          value: 'tractor',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Machine other'),
          value: 'machine_other',
          searchParamKey: 'objectTypes'
        }
      ];

    case 'recreational_vehicle':
      return [
        {
          label: t('Trailer'),
          value: 'trailer',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('MC/Moped'),
          value: 'mc_moped',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Camper'),
          value: 'camper',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Mobile home'),
          value: 'mobile_home',
          searchParamKey: 'objectTypes'
        },
        {
          label: t('Recreational other'),
          value: 'recreational_other',
          searchParamKey: 'objectTypes'
        }
      ];

    default:
      return [];
  }
};

export const getAuctionTypes = (t: TranslateFunction) =>
  buyMethodTypes.map(value =>
    createFilterOption(t, value, value, 'auctionType')
  );

export const getRegularFuelOptions = (t: TranslateFunction) =>
  regularFuelOptions
    .map(value => createFilterOption(t, value, value, 'fuel'))
    .sort(sortBy('label'));

export const getEnvironmentalFuelOptions = (t: TranslateFunction) =>
  environmentalFuelOptions
    .map(value => createFilterOption(t, value, value, 'fuel'))
    .sort(sortBy('label'));

export const getOtherOptions = (
  t: TranslateFunction,
  mainCategory: VehicleTypes
): FilterValue[] => {
  const extraOptions = ['car', 'light_transport'].includes(mainCategory)
    ? [
        {
          label: t('Towbar'),
          value: 'towbar' as const,
          searchParamKey: 'equipment' as const
        }
      ]
    : [];
  return [
    {
      label: t('Certified cars'),
      value: 'certified' as const,
      searchParamKey: 'certified' as const
    },
    {
      label: t('Eco friendly cars'),
      value: 'ecoFriendly' as const,
      searchParamKey: 'ecoFriendly' as const
    },
    {
      label: t('Can be exported'),
      value: 'exportable' as const,
      searchParamKey: 'exportable' as const
    },
    {
      label: t('Tonight at KVD'),
      value: 'countdownDate' as const,
      searchParamKey: 'countdownDate' as const
    },
    {
      label: t('VAT/LEASING'),
      value: 'deductibleVat' as const,
      searchParamKey: 'deductibleVat' as const
    },
    {
      label: t('Reduced price'),
      value: 'reducedPrice' as const,
      searchParamKey: 'reducedPrice' as const
    },
    ...extraOptions
  ].sort(sortBy('label'));
};

export function getFuelOptions(t: TranslateFunction) {
  const environmentalFuels = getEnvironmentalFuelOptions(t);
  const regularFuels = getRegularFuelOptions(t);
  const fuelOptions = [
    {
      label: t('Environmental fuels'),
      options: [...environmentalFuels]
    },
    {
      label: t('Regular fuels'),
      options: [...regularFuels]
    }
  ];

  return fuelOptions;
}

export const getGearBoxOptions = (t: TranslateFunction) =>
  ['automatic', 'manual']
    .map(value => createFilterOption(t, value, value, 'gearbox'))
    .sort(sortBy('label'));

const formatIntervalFunction = ({
  from,
  to,
  max,
  prefix,
  suffix,
  isOdometerReading = false
}: {
  from: number;
  to: number;
  max: number;
  prefix?: string;
  suffix?: string;
  isOdometerReading?: boolean;
}): string => {
  const maxValue = isOdometerReading ? max / 10 : max;

  const fromValue = from > maxValue ? maxValue : from;
  const toValue = to >= maxValue ? maxValue : to;

  const fromMaxChar = fromValue >= maxValue ? '+' : '';
  const toMaxChar = toValue >= maxValue ? '+' : '';

  const formattedFromValue = formatMoney({
    value: fromValue
  }).trim();

  const formattedToValue = formatMoney({
    value: toValue
  }).trim();

  if (fromValue === toValue) {
    return `${prefix || ''} ${formattedFromValue}${fromMaxChar} ${
      suffix || ''
    }`.trim();
  }

  return `${
    prefix || ''
  } ${formattedFromValue}${fromMaxChar} - ${formattedToValue}${toMaxChar} ${
    suffix || ''
  }`.trim();
};

export const getCountiesOptions = (): FilterValue[] => {
  return CountyOptions.map(value => ({
    label: value,
    value: value,
    searchParamKey: 'counties'
  }));
};
export const translateIntervalValues = (
  t: TranslateFunction,
  key: MinMaxIntervalKey,
  values: SliderType,
  minMaxIntervalValues: Record<MinMaxIntervalKey, SliderType>
) => {
  const isOdometerReading = key === 'odometerReading';
  const [initialFrom, initialTo] = values;
  const [min, max] = minMaxIntervalValues[key];

  const preFrom = initialFrom || min;
  const preTo = initialTo || max;

  const from = isOdometerReading ? preFrom / 10 : preFrom;
  const to = isOdometerReading ? preTo / 10 : preTo;

  switch (key) {
    case 'cardealerPrice':
      return formatIntervalFunction({
        from,
        to,
        max,
        suffix: t('SEK')
      });

    case 'buyNowAmount':
      return formatIntervalFunction({
        from,
        to,
        max,
        suffix: t('SEK')
      });

    case 'year':
      return `${t('Year model')} ${from} - ${to}`;

    case 'odometerReading':
      return formatIntervalFunction({
        from,
        to,
        max,
        suffix: t('Mil'),
        isOdometerReading
      });

    case 'operatingHours':
      return formatIntervalFunction({
        from,
        to,
        max,
        suffix: t('h')
      });

    case 'grade':
      return `${t('Grade')} ${from} - ${to}`;

    case 'hp':
      return formatIntervalFunction({
        from,
        to,
        max,
        suffix: t('unit.horsepower')
      });

    case 'loanCost':
      return formatIntervalFunction({
        from,
        to,
        max,
        prefix: t('Loan cost'),
        suffix: t('SEK')
      });

    case 'releasingCost':
      return formatIntervalFunction({
        from,
        to,
        max,
        prefix: t('Lease cost'),
        suffix: t('SEK')
      });

    case 'bid':
      return formatIntervalFunction({
        from,
        to,
        max,
        prefix: t('Bid'),
        suffix: t('SEK')
      });

    case 'co2':
      return `${from} - ${to} ${t('unit.grams_per_kilometer')}`;

    case 'numSeats':
      return formatIntervalFunction({
        from,
        to,
        max
      });
    default:
      return `${from} - ${to}`;
  }
};

export interface OrderAlternative {
  label: string;
  value: string;
}

export const getOrderAlternatives = (
  t: TranslateFunction,
  isCarguideDone?: boolean,
  isFixedPrice?: boolean,
  isBidding?: boolean
): OrderAlternative[] => {
  const orderAlternatives = [];

  const fixedPricerderAlternatives: OrderAlternative[] = [
    {
      label: t('Recommended (grade)'),
      value: '-grade'
    },
    {
      label: t('Highest price'),
      value: '-buy_now_amount'
    },
    {
      label: t('Lowest price'),
      value: 'buy_now_amount'
    },
    {
      label: t('Latest added'),
      value: '-countdown_start_at'
    }
  ];

  const biddingOrderAlternatives: OrderAlternative[] = [
    {
      label: t('Recommended (grade)'),
      value: '-grade'
    },
    {
      label: t('Highest car dealer price'),
      value: '-cardealer_price'
    },
    {
      label: t('Lowest car dealer price'),
      value: 'cardealer_price'
    },
    {
      label: t('Shortest time remaining'),
      value: 'countdown_start_at'
    },
    {
      label: t('Latest added'),
      value: '-countdown_start_at'
    },
    {
      label: t('Most popular'),
      value: '-popularity'
    },
    {
      label: t('Least popular'),
      value: 'popularity'
    },
    {
      label: t('Highest bid'),
      value: '-highest_bid_amount'
    },
    {
      label: t('Lowest bid'),
      value: 'highest_bid_amount'
    }
  ];

  if (isCarguideDone) {
    orderAlternatives.push({
      label: t('My carguide'),
      value: 'carguide'
    });
  }

  if (isFixedPrice) {
    return [...fixedPricerderAlternatives, ...orderAlternatives];
  }

  if (isBidding) {
    return [...biddingOrderAlternatives, ...orderAlternatives];
  }

  // remove duplicates
  const mergedOrderAlternatives = [
    ...biddingOrderAlternatives,
    ...fixedPricerderAlternatives
  ];

  const values = mergedOrderAlternatives.map(
    (item: OrderAlternative) => item.value
  );
  const uniqueValues: OrderAlternative['value'][] = [...new Set(values)];
  const uniqueOrderAlternatives: OrderAlternative[] = uniqueValues
    .map(value =>
      mergedOrderAlternatives.find(
        (item: OrderAlternative) => item.value === value
      )
    )
    .filter(Boolean) as OrderAlternative[]; // filter out null values

  return [...uniqueOrderAlternatives, ...orderAlternatives];
};

export const getObjectCategoryOptions = (
  t: TranslateFunction
): CategoryOption[] => [
  {
    value: '',
    label: t('All vehicles')
  },
  { value: 'car', label: t('Car') },
  { value: 'light_transport', label: t('Light truck') },
  {
    value: 'heavy_transport',
    label: t('Heavy transport')
  },
  { value: 'machines', label: t('Machines') },
  {
    value: 'recreational_vehicle',
    label: t('Recreational vehicle')
  }
];

export const getPathFromMainCategory = (
  t: TranslateFunction,
  mainCategory?: MainCategory | string | string[] | null
) => {
  // Saved searches are still stored as an array....
  let value = mainCategory as MainCategory;
  if (Array.isArray(mainCategory)) {
    value = mainCategory[0] as MainCategory;
  }

  // backend sends it as uppercase, but we want to use lowercase...
  if (value) value = value.toLowerCase() as MainCategory;

  switch (value) {
    case 'light_transport':
    case 'transport_vehicle': // OLD vehicleType thats now light_transport
      return `/${t('ROUTES.FILTER_LIGHT_TRANSPORT')}`;
    case 'heavy_transport':
      return `/${t('ROUTES.FILTER_HEAVY_TRANSPORT')}`;
    case 'machines':
      return `/${t('ROUTES.FILTER_MACHINE')}`;
    case 'recreational_vehicle':
      return `/${t('ROUTES.FILTER_RECREATIONAL_VEHICLE')}`;
    case 'car':
      return `/${t('ROUTES.FILTER_CAR')}`;
    default:
      return `/${t('ROUTES.FILTER_ALL_VEHICLES')}`;
  }
};

export const getMainCategoryFromPath = (
  path: string,
  t: TranslateFunction
): MainCategory | '' => {
  const mainCategoryPath = path.split('/').filter(Boolean)?.[0];

  switch (mainCategoryPath) {
    case t('ROUTES.FILTER_LIGHT_TRANSPORT'):
      return 'light_transport';
    case t('ROUTES.FILTER_HEAVY_TRANSPORT'):
      return 'heavy_transport';
    case t('ROUTES.FILTER_MACHINE'):
      return 'machines';
    case t('ROUTES.FILTER_RECREATIONAL_VEHICLE'):
      return 'recreational_vehicle';
    case t('ROUTES.FILTER_CAR'):
      return 'car';
    case t('ROUTES.FILTER_ALL_VEHICLES'):
    default:
      return '';
  }
};
