import { ReduxStore } from '~/App/shared/interfaces/store';
import { App } from '~/App/shared/interfaces/store/App';
import { Locale } from '~/App/shared/localization/types';

export const appStopLoading = () => ({
  type: 'APP_STOP_LOADING' as const
});

export const appStartLoading = () => ({
  type: 'APP_START_LOADING' as const
});

export type AppStartStopAction =
  | ReturnType<typeof appStopLoading>
  | ReturnType<typeof appStartLoading>;

export const setLocaleCode = (
  key: keyof ReduxStore['app']['localization'],
  localeCode: Locale
) => ({
  type: 'APP_LOCALIZATION_LOCALE_CODE' as const,
  payload: { key, localeCode }
});

export const setCurrentRoute = (
  payload: App['localization']['currentRoute']
) => ({
  type: 'APP_LOCALIZATION_CURRENT_ROUTE' as const,
  payload
});

export const setFooterHiddenAction = (payload: boolean) => ({
  type: 'APP_SET_FOOTER_HIDDEN' as const,
  payload
});

export type AppLocalizationAction =
  | ReturnType<typeof setLocaleCode>
  | ReturnType<typeof setCurrentRoute>;
