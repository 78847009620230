/* eslint-disable max-lines */
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { combineReducers } from 'redux';
import {
  CHECKOUT_STEPS,
  PICKUP_LOCATIONS,
  PICKUP_PERSON,
  WarrantyMonths
} from '~/App/views/Checkout/constants';
import { getResponseMessage } from '~/helpers/checkout';
import { getCheckoutStepState } from '~/helpers/checkoutPure';
import { GA4ObjectProperties } from '~/helpers/client/ga4TrackEvent';
import {
  CheckoutPricesResponse,
  GetHomeDeliveryCostResponse
} from '~/helpers/orchestration/checkout';
import { GetFacilitiesAction } from '../../actions/facilities';
import {
  ChangeInterestInExtraTiresAction,
  CHANGE_PAYMENT_METHOD,
  CheckoutService,
  CLEAR_BUYER_ERRORS,
  CLEAR_ERRORS,
  CLEAR_HOME_DELIVERY_COST,
  COMPLETE_BUYER_STEP_FAILURE,
  COMPLETE_BUYER_STEP_REQUEST,
  COMPLETE_BUYER_STEP_SUCCESS,
  COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_SUCCESS,
  COMPLETE_CONFIGURE_STEP_FAILURE,
  COMPLETE_CONFIGURE_STEP_REQUEST,
  COMPLETE_CONFIGURE_STEP_SUCCESS,
  COMPLETE_DELIVERY_STEP_FAILURE,
  COMPLETE_DELIVERY_STEP_REQUEST,
  COMPLETE_DELIVERY_STEP_SUCCESS,
  EXCLUDE_CHECKOUT_SERVICE,
  GetAvailablePickupTimeslotsSuccess,
  GET_CHECKOUT_PRICES_SUCCESS,
  GET_HOME_DELIVERY_COST_SUCCESS,
  GET_LOAN_COST_FAILURE,
  GET_LOAN_COST_REQUEST,
  GET_LOAN_COST_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_TRANSPORT_PRICES_SUCCESS,
  GOTO_STEP,
  INCLUDE_CHECKOUT_SERVICE,
  NEXT_STEP,
  PaymentMethod,
  RESET_CHECKOUT_STATE,
  SetSelectedTimeslotAction,
  SET_DOWN_PAYMENT,
  SET_PAYMENT_PERIOD,
  SET_PICKUP_LOCATION,
  SET_PICKUP_PERSON_REQUEST,
  SET_PICKUP_PERSON_SUCCESS,
  SET_TO_FACILITY_ID,
  SET_WARRANTY_PERIOD,
  START_ORDER_FAILURE,
  START_ORDER_SUCCESS,
  SUBMIT_ORDER_FAILURE,
  SUBMIT_ORDER_REQUEST,
  SUBMIT_ORDER_SUCCESS,
  TOGGLE_CHECKOUT_SERVICE,
  CLEAR_ORDER_GA4_OBJECT,
  SET_ORDER_GA4_OBJECT_FAILURE,
  SET_ORDER_GA4_OBJECT_REQUEST,
  SET_ORDER_GA4_OBJECT_SUCCESS,
  SetInterestInFinancingAction
} from '../../actions/checkoutActions';
import { DeliveryLocation } from '../../interfaces/DeliveryLocation';
import { Facility } from '../../interfaces/Facility';
import { FacilityTransportCost } from '../../interfaces/FacilityTransportCost';
import { ReduxStore } from '../../interfaces/store';
import {
  CheckoutBuyer,
  CheckoutErrors,
  CheckoutOrder,
  CompletedSteps
} from '../../interfaces/store/Checkout';
import { CheckoutFlowType, CheckoutType } from '../../types/CheckoutType';
import { NotUsed } from '../../types/NotUsed';
import { bookedTimeslotReducer } from './bookedTimeslotReducer';
import {
  pickupProxyPersonReducer,
  pickupProxyTransportCompanyReducer
} from './pickupProxyReducer';

type TestType = '@test';

type PaymentMethodAction = {
  type: typeof CHANGE_PAYMENT_METHOD;
  payload?: PaymentMethod;
};

export type GetOrderSuccessAction = {
  type: typeof GET_ORDER_SUCCESS | typeof COMPLETE_CONFIGURE_STEP_SUCCESS;
  payload?: CheckoutOrder;
};

type ResetAction = {
  type: typeof RESET_CHECKOUT_STATE;
  payload?: null;
};

export const paymentMethodReducer = (
  state = '' as PaymentMethod,
  action: PaymentMethodAction | GetOrderSuccessAction | ResetAction
): PaymentMethod => {
  switch (action.type) {
    case 'CHANGE_PAYMENT_METHOD':
      return action.payload ?? state;
    case 'RESET_CHECKOUT_STATE':
      return '' as PaymentMethod;
    case 'GET_ORDER_SUCCESS': {
      return action.payload?.financing?.paymentMethod ?? state;
    }
    default:
      return state;
  }
};

export const interestInExtraTiresReducer = (
  state = false,
  action: ChangeInterestInExtraTiresAction | GetOrderSuccessAction | ResetAction
): boolean => {
  switch (action.type) {
    case 'CHANGE_INTEREST_IN_EXTRA_TIRES':
      return action.value ?? state;
    case 'RESET_CHECKOUT_STATE':
      return false;
    case 'GET_ORDER_SUCCESS': {
      if (action.payload?.extras) {
        return (
          action.payload?.extras.find(
            extra => extra.extraType === 'EXTRA_TIRES'
          )?.accepted || false
        );
      }
      return false;
    }
    default:
      return state;
  }
};

export const isCarPriceChangedReducer = (
  state = false,
  action: GetOrderSuccessAction | ResetAction | CompletedStepsSuccessAction
): boolean => {
  switch (action.type) {
    case 'RESET_CHECKOUT_STATE':
    case 'COMPLETE_CONFIGURE_STEP_SUCCESS':
    case 'COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_SUCCESS':
      return false;
    case 'GET_ORDER_SUCCESS': {
      return (
        action.payload?.checkoutProgress?.steps['FINANCING']?.errors?.includes(
          'buy now amount updated'
        ) || false
      );
    }
    default:
      return state;
  }
};

type ToggleSelectedServiceAction = {
  type: typeof TOGGLE_CHECKOUT_SERVICE;
  payload?: CheckoutService;
};

type IncludeSelectedServiceAction = {
  type: typeof INCLUDE_CHECKOUT_SERVICE;
  payload?: CheckoutService;
};

type ExcludeSelectedServiceAction = {
  type: typeof EXCLUDE_CHECKOUT_SERVICE;
  payload?: CheckoutService;
};

type RemoveSelectedServicesAction = {
  type: 'REMOVE_CHECKOUT_SERVICES';
};

type SelectServiceAction =
  | ToggleSelectedServiceAction
  | GetOrderSuccessAction
  | IncludeSelectedServiceAction
  | ExcludeSelectedServiceAction
  | RemoveSelectedServicesAction;

type SetWarrantyMonthsAction = {
  type: typeof SET_WARRANTY_PERIOD;
  payload?: WarrantyMonths;
};

export type GetCheckoutPrices = {
  type: typeof GET_CHECKOUT_PRICES_SUCCESS;
  payload: CheckoutPricesResponse;
};

export const selectedServicesReducer = (
  state = ['INSURANCE' as CheckoutService],
  action: SelectServiceAction | GetCheckoutPrices
): CheckoutService[] => {
  switch (action.type) {
    case 'TOGGLE_CHECKOUT_SERVICE': {
      /* Ignore this, INSURANCE is static */
      if (action.payload === 'INSURANCE') {
        return state;
      }
      if (action?.payload && state.includes(action.payload)) {
        return state.filter(item => item !== action.payload);
      } else if (action?.payload) {
        return [...state, action.payload];
      }

      return state;
    }
    case 'INCLUDE_CHECKOUT_SERVICE': {
      if (action.payload === 'INSURANCE') {
        return state;
      }
      if (action.payload && !state.includes(action.payload)) {
        return [...state, action.payload];
      }
      return state;
    }
    case 'EXCLUDE_CHECKOUT_SERVICE': {
      if (action.payload === 'INSURANCE') {
        return state;
      }
      const arr = state.filter(i => i !== action.payload);
      return [...arr];
    }
    case 'REMOVE_CHECKOUT_SERVICES': {
      return [];
    }
    case 'GET_ORDER_SUCCESS': {
      const services = action.payload?.financing?.services ?? [];
      const serviceList = services.map(service => {
        return service.serviceType;
      });

      if (action.payload?.checkoutType === 'BUY_NOW') {
        serviceList.push('WARRANTY');
      }

      serviceList.push('INSURANCE');
      return Array.from(new Set(serviceList)) ?? serviceList;
    }

    case 'GET_CHECKOUT_PRICES_SUCCESS': {
      const services = [...state];

      const insurance = action.payload.INSURANCE;
      const warranty = action.payload.WARRANTY;
      const serviceAgreement = action.payload.SERVICE_AGREEMENT;

      if (insurance?.available && insurance?.included) {
        services.push('INSURANCE');
      }

      if (warranty?.available && warranty?.included) {
        services.push('WARRANTY');
      }

      if (serviceAgreement?.available && serviceAgreement?.included) {
        services.push('SERVICE_AGREEMENT');
      }

      return services.filter(service => {
        if (
          service === 'INSURANCE' &&
          action.payload.INSURANCE?.available === false
        ) {
          return false;
        }

        if (
          service === 'WARRANTY' &&
          action.payload.WARRANTY?.available === false
        ) {
          return false;
        }

        if (
          service === 'SERVICE_AGREEMENT' &&
          action.payload.SERVICE_AGREEMENT?.available === false
        ) {
          return false;
        }

        return true;
      });
    }

    default:
      return state;
  }
};

export const warrantyMonthsReducer = (
  state: WarrantyMonths = null,
  action: SetWarrantyMonthsAction | GetOrderSuccessAction
) => {
  switch (action.type) {
    case 'checkout/SET_WARRANTY_PERIOD': {
      return action.payload || null;
    }
    case 'GET_ORDER_SUCCESS':
      const warranty = action.payload?.financing?.services.find(
        service => service.serviceType === 'WARRANTY'
      );
      return warranty?.config?.period.months ?? state;
    default:
      return state;
  }
};

type StepAction = {
  type:
    | typeof NEXT_STEP
    | typeof GOTO_STEP
    | typeof COMPLETE_CONFIGURE_STEP_SUCCESS
    | typeof COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_SUCCESS
    | typeof COMPLETE_DELIVERY_STEP_SUCCESS;
  payload?: number;
};

type CheckoutStepAction =
  | StepAction
  | GetOrderSuccessAction
  | CompleteBuyerAction;

export const stepReducer = (
  state = 0,
  action: CheckoutStepAction | SubmitActionFailure
): ReduxStore['checkout']['step'] => {
  switch (action.type) {
    case 'checkout/GOTO_STEP': {
      return action.payload ?? state;
    }

    case 'checkout/NEXT_STEP': {
      return state + 1;
    }

    case 'COMPLETE_CONFIGURE_STEP_SUCCESS':
    case 'COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_SUCCESS':
    case 'COMPLETE_DELIVERY_STEP_SUCCESS':
      return state + 1;

    case 'GET_ORDER_SUCCESS': {
      if (getCheckoutStepState('FINANCING', action.payload) !== 'COMPLETED') {
        return CHECKOUT_STEPS.FINANCING;
      }

      if (getCheckoutStepState('BUYER', action.payload) !== 'COMPLETED') {
        return CHECKOUT_STEPS.BUYER;
      }

      if (getCheckoutStepState('TRANSPORT', action.payload) !== 'COMPLETED') {
        return CHECKOUT_STEPS.TRANSPORT;
      }

      return CHECKOUT_STEPS.SUMMARY;
    }

    case 'COMPLETE_BUYER_STEP_SUCCESS': {
      if (getCheckoutStepState('FINANCING', action.payload) !== 'INVALID') {
        return state + 1;
      }
      return CHECKOUT_STEPS.FINANCING;
    }

    case 'checkout/SUBMIT_ORDER_FAILURE': {
      const error = getResponseMessage(action.payload);
      if (error.includes('invalid step: TRANSPORT')) {
        return CHECKOUT_STEPS.TRANSPORT;
      } else if (error.includes('invalid step')) {
        return 0;
      }

      return state;
    }

    default:
      return state;
  }
};

type DownPaymentAction = {
  type: typeof SET_DOWN_PAYMENT;
  payload?: string;
};
export const downPaymentReducer = (
  state = 0,
  action: DownPaymentAction | GetOrderSuccessAction | ResetAction
): ReduxStore['checkout']['financing']['downPayment'] => {
  switch (action.type) {
    case 'GET_ORDER_SUCCESS': {
      const downPayment = action.payload?.financing?.downPayment;
      return downPayment ? Number(downPayment) : state;
    }
    case 'checkout/SET_DOWN_PAYMENT':
      return isNaN(Number(action.payload)) ? state : Number(action.payload);
    case 'RESET_CHECKOUT_STATE': {
      return 0;
    }
    default:
      return state;
  }
};

export const amountReducer = (
  state = '0',
  action: GetOrderSuccessAction
): ReduxStore['checkout']['financing']['amount'] => {
  switch (action.type) {
    case 'COMPLETE_CONFIGURE_STEP_SUCCESS':
    case 'GET_ORDER_SUCCESS': {
      return action.payload?.financing?.amount ?? state;
    }
    default:
      return state;
  }
};

type PaymentPeriodAction = {
  type: typeof SET_PAYMENT_PERIOD;
  payload?: string;
};
export const paymentPeriodMonthsReducer = (
  state = 72,
  action: PaymentPeriodAction | GetOrderSuccessAction
): ReduxStore['checkout']['financing']['paymentPeriodMonths'] => {
  switch (action.type) {
    case 'checkout/SET_PAYMENT_PERIOD':
      return Number(action.payload) || state;
    case 'GET_ORDER_SUCCESS':
      return action.payload?.financing?.period || state;
    default:
      return state;
  }
};

export const initialCheckoutBuyerState: CheckoutBuyer = {
  memberType: '',
  nationalIdentificationNumber: '',
  firstName: '',
  lastName: '',
  address: '',
  zipCode: '',
  city: '',
  country: '',
  email: '',
  phoneNumber: '',
  vatNumber: null,
  organisationNumber: '',
  organisationName: '',
  isPerson: true,
  isOrderingMember: true
};

export type CompleteBuyerAction = {
  type: typeof COMPLETE_BUYER_STEP_SUCCESS;
  payload?: CheckoutOrder;
};

export type CheckoutBuyerAction =
  | CompleteBuyerAction
  | GetOrderSuccessAction
  | ResetAction;

export const checkoutBuyerReducer = (
  state = initialCheckoutBuyerState,
  action: CheckoutBuyerAction
) => {
  switch (action.type) {
    case 'COMPLETE_BUYER_STEP_SUCCESS': {
      return action.payload?.buyer || state;
    }
    case 'GET_ORDER_SUCCESS': {
      return (action.payload && action.payload.buyer) || state;
    }
    case 'RESET_CHECKOUT_STATE': {
      return initialCheckoutBuyerState;
    }
    default:
      return state;
  }
};

export type SubmitActionFailure = {
  type: typeof SUBMIT_ORDER_FAILURE;
  payload: AxiosError | Error;
};

type IsLoadingAction = {
  type:
    | typeof SUBMIT_ORDER_REQUEST
    | typeof SUBMIT_ORDER_SUCCESS
    | typeof COMPLETE_BUYER_STEP_SUCCESS
    | typeof COMPLETE_BUYER_STEP_FAILURE
    | typeof COMPLETE_CONFIGURE_STEP_SUCCESS
    | typeof COMPLETE_CONFIGURE_STEP_FAILURE
    | typeof COMPLETE_BUYER_STEP_REQUEST
    | typeof COMPLETE_CONFIGURE_STEP_REQUEST
    | typeof COMPLETE_DELIVERY_STEP_REQUEST
    | typeof COMPLETE_DELIVERY_STEP_SUCCESS
    | typeof COMPLETE_DELIVERY_STEP_FAILURE;
  payload?: NotUsed;
};
export const isLoadingReducer = (
  state = false,
  action: IsLoadingAction | SubmitActionFailure
) => {
  switch (action.type) {
    case 'checkout/SUBMIT_ORDER_REQUEST':
    case 'COMPLETE_BUYER_STEP_REQUEST':
    case 'COMPLETE_CONFIGURE_STEP_REQUEST':
    case 'COMPLETE_DELIVERY_STEP_REQUEST':
      return true;
    case 'checkout/SUBMIT_ORDER_SUCCESS':
    case 'checkout/SUBMIT_ORDER_FAILURE':
    case 'COMPLETE_BUYER_STEP_SUCCESS':
    case 'COMPLETE_CONFIGURE_STEP_SUCCESS':
    case 'COMPLETE_BUYER_STEP_FAILURE':
    case 'COMPLETE_CONFIGURE_STEP_FAILURE':
    case 'COMPLETE_DELIVERY_STEP_SUCCESS':
    case 'COMPLETE_DELIVERY_STEP_FAILURE':
      return false;
    default:
      return state;
  }
};

type CompletedStepsSuccessAction = {
  type:
    | TestType
    | typeof COMPLETE_CONFIGURE_STEP_SUCCESS
    | typeof COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_SUCCESS
    | typeof COMPLETE_BUYER_STEP_SUCCESS
    | typeof COMPLETE_DELIVERY_STEP_SUCCESS;
  payload?: CheckoutOrder;
};

const initialCompleteStepsState: CompletedSteps = {
  configure: false,
  buyer: false,
  delivery: false,
  summary: false
};

type CompletedStepsAction = CompletedStepsSuccessAction | GetOrderSuccessAction;
export const completedStepsReducer = (
  state = initialCompleteStepsState,
  action: CompletedStepsAction | SubmitActionFailure
): ReduxStore['checkout']['completedSteps'] => {
  switch (action.type) {
    case 'GET_ORDER_SUCCESS': {
      const isFinancingComplete =
        getCheckoutStepState('FINANCING', action.payload) === 'COMPLETED';
      const isTransportComplete =
        getCheckoutStepState('TRANSPORT', action.payload) === 'COMPLETED';
      const isBuyerComplete =
        getCheckoutStepState('BUYER', action.payload) === 'COMPLETED';

      const isAllComplete =
        isFinancingComplete && isBuyerComplete && isTransportComplete;

      return {
        ...state,
        configure: isFinancingComplete,
        buyer: isBuyerComplete,
        delivery: isTransportComplete,
        summary: isAllComplete
      };
    }
    case 'COMPLETE_CONFIGURE_STEP_SUCCESS':
    case 'COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_SUCCESS':
      return {
        ...state,
        configure: true
      };

    case 'COMPLETE_BUYER_STEP_SUCCESS': {
      const isFinancingComplete =
        getCheckoutStepState('FINANCING', action.payload) === 'COMPLETED';

      return {
        ...state,
        buyer: true,
        configure: isFinancingComplete
      };
    }
    case 'COMPLETE_DELIVERY_STEP_SUCCESS':
      return {
        ...state,
        delivery: true,
        summary: true
      };

    case 'checkout/SUBMIT_ORDER_FAILURE':
      const error = getResponseMessage(action.payload);
      const isTransportError = error.includes('invalid step: TRANSPORT');
      if (!isTransportError) {
        return state;
      }

      return {
        ...state,
        delivery: false
      };
    default:
      return state;
  }
};

type StartOrderAction = {
  type: typeof START_ORDER_SUCCESS;
  payload?: CheckoutOrder;
};

type GetCheckoutOrderAction = {
  type: typeof GET_ORDER_SUCCESS | typeof SUBMIT_ORDER_SUCCESS;
  payload?: CheckoutOrder;
};

type CompleteDeliveryStepAction = {
  type: typeof COMPLETE_DELIVERY_STEP_SUCCESS;
  payload?: CheckoutOrder;
};

type OrderReducerAction = GetCheckoutOrderAction | StartOrderAction;

export const orderReducer = (
  state: CheckoutOrder | null = null, // TODO: Discuss if inital value should be null.
  action:
    | OrderReducerAction
    | ResetAction
    | CompleteBuyerAction
    | SetInterestInFinancingAction
    | CompleteDeliveryStepAction
): ReduxStore['checkout']['order'] => {
  switch (action.type) {
    case 'START_ORDER_SUCCESS':
    case 'GET_ORDER_SUCCESS':
    case 'COMPLETE_BUYER_STEP_SUCCESS':
    case 'checkout/SUBMIT_ORDER_SUCCESS':
    case 'COMPLETE_DELIVERY_STEP_SUCCESS':
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        ...action.payload
      };
    case 'RESET_CHECKOUT_STATE': {
      return null; // TODO: Discuss if inital value should be null.
    }
    case 'SET_INTEREST_IN_FINANCING':
      if (state) {
        return {
          ...state,
          interestInFinancing: action.payload
        };
      }
      return state;

    default:
      return state;
  }
};

export const checkoutTypeReducer = (
  state: CheckoutType = 'BUY_NOW',
  action: GetCheckoutOrderAction
): CheckoutType => {
  switch (action.type) {
    case 'GET_ORDER_SUCCESS':
      return action.payload?.checkoutType || state;
    default:
      return state;
  }
};

export const checkoutFlowTypeReducer = (
  state: CheckoutFlowType = 'GENERAL',
  action: GetCheckoutOrderAction
): CheckoutFlowType => {
  switch (action.type) {
    case 'GET_ORDER_SUCCESS':
      return action?.payload?.checkoutFlow || 'GENERAL';
    default:
      return state;
  }
};

type GetPriceAction = {
  type: typeof GET_CHECKOUT_PRICES_SUCCESS;
  payload?: CheckoutPricesResponse;
};

type GetLoanCostAction = {
  type: typeof GET_LOAN_COST_SUCCESS;
  payload?: { monthlyCost: number; downPayment: number };
};

const initialPriceState: ReduxStore['checkout']['prices'] = {
  loan: {
    price: 0
  },
  homeDeliveryCost: {
    price: 0
  },
  biddingFee: {
    price: 0
  },
  mediationFee: {
    price: 0
  },
  insurance: {
    included: false,
    available: false,
    price: 0
  },
  warranty: {
    included: false,
    available: false,
    choices: []
  },
  serviceAgreement: undefined,
  homeDelivery: {
    homeDeliveryCost: 0,
    transportToFacilityCost: 0
  }
};

export const pricesReducer = (
  state = initialPriceState,
  action:
    | GetHomeDeliveryCostSuccessAction
    | ResetAction
    | GetPriceAction
    | GetOrderSuccessAction
    | GetLoanCostAction
    | CompletedStepsSuccessAction
): ReduxStore['checkout']['prices'] => {
  switch (action.type) {
    case 'checkout/GET_LOAN_COST_SUCCESS': {
      return {
        ...state,
        loan: {
          price: action.payload?.monthlyCost || 0
        }
      };
    }
    case 'GET_CHECKOUT_PRICES_SUCCESS':
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        insurance: {
          ...action.payload.INSURANCE,
          price: action.payload.INSURANCE.price ?? 0
        },
        warranty: action.payload.WARRANTY,
        serviceAgreement: action.payload.SERVICE_AGREEMENT
      };
    case 'GET_HOME_DELIVERY_COST_SUCCESS': {
      const payload = action.payload;
      if (!payload || !payload.available) {
        return {
          ...state,
          homeDelivery: {
            available: false
          }
        };
      }

      return {
        ...state,
        homeDelivery: {
          ...state.homeDelivery,
          homeDeliveryCost: parseFloat(payload.homeDeliveryCost)
        }
      };
    }
    case 'GET_ORDER_SUCCESS': {
      const {
        transport,
        biddingFee = '0.00',
        mediationFee = '0.00'
      } = action.payload || {};

      if (transport && !transport.homeDeliveryCost) {
        transport.homeDeliveryCost = '0.00';
      }

      return {
        ...state,
        biddingFee: {
          price: parseFloat(biddingFee),
          included: true
        },
        mediationFee: {
          price: parseFloat(mediationFee),
          included: true
        },
        homeDelivery: {
          ...state.homeDelivery,
          homeDeliveryCost: parseFloat(transport?.homeDeliveryCost ?? '0')
        }
      };
    }
    case 'COMPLETE_BUYER_STEP_SUCCESS': {
      const biddingFee =
        (action.payload && action.payload.biddingFee) || '0.00';
      const mediationFee =
        (action.payload && action.payload.mediationFee) || '0.00';

      return {
        ...state,
        biddingFee: {
          price: parseFloat(biddingFee),
          included: true
        },
        mediationFee: {
          price: parseFloat(mediationFee),
          included: true
        }
      };
    }
    case 'RESET_CHECKOUT_STATE': {
      return initialPriceState;
    }
    default:
      return state;
  }
};

type SetPickupPersonAction = {
  type: typeof SET_PICKUP_PERSON_SUCCESS | typeof SET_PICKUP_PERSON_REQUEST;
  payload?: PICKUP_PERSON;
};
export const pickupPersonReducer = (
  state = PICKUP_PERSON.ME,
  action: SetPickupPersonAction
): ReduxStore['checkout']['transport']['pickupPerson'] => {
  switch (action.type) {
    case 'SET_PICKUP_PERSON_REQUEST':
      return action.payload ?? state; // The new HOT "or". Only resolves to state on undefined :D
    case 'SET_PICKUP_PERSON_SUCCESS':
      return action.payload ?? state;
    default:
      return state;
  }
};

type SetPickupLocationAction = {
  type: typeof SET_PICKUP_LOCATION;
  payload?: PICKUP_LOCATIONS;
};
export const pickupLocationReducer = (
  state = PICKUP_LOCATIONS.FACILITY,
  action: SetPickupLocationAction | GetOrderSuccessAction | ResetAction
): ReduxStore['checkout']['transport']['pickupLocation'] => {
  switch (action.type) {
    case 'checkout/SET_PICKUP_LOCATION':
      return action.payload ?? state;
    case 'GET_ORDER_SUCCESS': {
      const pickupLocation = action?.payload?.transport?.deliveryLocation;

      if (action.payload?.transport?.wantsDelayedBooking) {
        return PICKUP_LOCATIONS.DELAYED_BOOKING;
      }

      return pickupLocation || !action?.payload?.transport
        ? PICKUP_LOCATIONS.LOCATION
        : PICKUP_LOCATIONS.FACILITY;
    }
    case 'RESET_CHECKOUT_STATE': {
      return PICKUP_LOCATIONS.CURRENT_FACILITY;
    }
    default:
      return state;
  }
};

type ToFacilityAction = {
  type: typeof SET_TO_FACILITY_ID;
  payload?: string;
};

export const toFacilityIdReducer = (
  state = '',
  action: ToFacilityAction | GetOrderSuccessAction | ResetAction
): ReduxStore['checkout']['transport']['toFacilityId'] => {
  switch (action.type) {
    case 'checkout/SET_TO_FACILITY_ID':
      return action.payload || '';
    case 'GET_ORDER_SUCCESS': {
      return action.payload?.transport?.destinationFacilityId || state;
    }
    case 'RESET_CHECKOUT_STATE': {
      return '';
    }
    default:
      return state;
  }
};

type GetHomeDeliveryCostSuccessAction = {
  type: typeof GET_HOME_DELIVERY_COST_SUCCESS;
  payload?: GetHomeDeliveryCostResponse;
  meta?: DeliveryLocation;
};

type ClearHomeDeliveryCost = {
  type: typeof CLEAR_HOME_DELIVERY_COST;
  payload?: null;
};

type HomeDeliveryCostAction =
  | ResetAction
  | ClearHomeDeliveryCost
  | GetOrderSuccessAction
  | GetHomeDeliveryCostSuccessAction;

export const homeDeliveryReducer = (
  state = null,
  action: HomeDeliveryCostAction
): ReduxStore['checkout']['transport']['homeDelivery'] => {
  switch (action.type) {
    case 'GET_HOME_DELIVERY_COST_SUCCESS': {
      if (!action.payload?.available) {
        return {
          available: false
        };
      }
      return {
        ...action.payload,
        transportToFacilityCost:
          action.payload.transportToFacilityCost.toString(),
        homeDeliveryCost: action.payload.homeDeliveryCost.toString()
      };
    }

    case 'GET_ORDER_SUCCESS':
      const { payload } = action;

      if (
        Boolean(payload?.transport?.deliveryLocation) &&
        payload &&
        payload.transport
      ) {
        return {
          available: true,
          toFacilityId: '',
          homeDeliveryCost: payload.transport.homeDeliveryCost ?? '0',
          transportToFacilityCost:
            payload.transport.transportToFacilityCost ?? '0'
        };
      }

      return state;
    case 'CLEAR_HOME_DELIVERY_COST':
      return null;
    case 'RESET_CHECKOUT_STATE':
      return null;
    default:
      return state;
  }
};

export const deliveryLocationReducer = (
  state = null,
  action: HomeDeliveryCostAction | ResetAction
): ReduxStore['checkout']['transport']['deliveryLocation'] => {
  switch (action.type) {
    case 'GET_HOME_DELIVERY_COST_SUCCESS':
      return action.meta || state;
    case 'GET_ORDER_SUCCESS': {
      const deliveryLocation = action.payload?.transport?.deliveryLocation;
      return deliveryLocation || state;
    }
    case 'RESET_CHECKOUT_STATE': {
      return null;
    }
    default:
      return state;
  }
};

export const availablePickupTimeslotsReducer = (
  state = {},
  action: GetAvailablePickupTimeslotsSuccess | { type: '@@unknown' }
): ReduxStore['checkout']['transport']['availablePickupTimeslots'] => {
  switch (action.type) {
    case 'GET_AVAILABLE_PICKUP_TIMESLOTS_SUCCESS':
      let bookableTimeslots = {};

      Object.entries(action.payload).forEach(dayEntry => {
        const [key, value] = dayEntry;

        if (value.length) {
          bookableTimeslots = { ...bookableTimeslots, [key]: value };
        }
      });

      return bookableTimeslots;

    default:
      return state;
  }
};

type ErrorAction = {
  type:
    | typeof CLEAR_ERRORS
    | typeof COMPLETE_BUYER_STEP_FAILURE
    | typeof CLEAR_BUYER_ERRORS;
  payload?: Record<string, string[]>;
};

type GetOrderFailure = {
  type: typeof GET_ORDER_FAILURE;
  payload?: AxiosError;
};

type StartOrderFailure = {
  type: typeof START_ORDER_FAILURE;
  payload?: AxiosError;
};

type GetOrderRequest = {
  type: typeof GET_ORDER_REQUEST;
  payload?: NotUsed;
};

type CompleteOrderRequest = {
  type: typeof COMPLETE_BUYER_STEP_REQUEST;
  payload?: NotUsed;
};

export const errorsReducer = (
  state = {},
  action:
    | ErrorAction
    | GetOrderFailure
    | GetOrderRequest
    | StartOrderFailure
    | CompleteOrderRequest
): CheckoutErrors => {
  switch (action.type) {
    case 'START_ORDER_FAILURE':
      return { ...state, startOrder: action.payload };
    case 'COMPLETE_BUYER_STEP_REQUEST':
      return { ...state, buyer: undefined };
    case 'COMPLETE_BUYER_STEP_FAILURE':
      return { ...state, buyer: action.payload };
    case 'GET_ORDER_FAILURE':
      return { ...state, getOrder: action.payload };
    case 'GET_ORDER_REQUEST':
      return { ...state, getOrder: undefined };
    case 'checkout/CLEAR_ERRORS':
      return {};
    case 'checkout/CLEAR_BUYER_ERRORS':
      return { ...state, buyer: undefined };
    default:
      return state;
  }
};

export const selectedTimeslotReducer = (
  state = null,
  action:
    | SetSelectedTimeslotAction
    | CompleteDeliveryStepAction
    | GetOrderSuccessAction
): ReduxStore['checkout']['transport']['selectedTimeslot'] => {
  switch (action.type) {
    case 'SET_SELECTED_TIMESLOT':
      return action.timeslot ?? state;
    case 'COMPLETE_DELIVERY_STEP_SUCCESS':
      if (action.payload?.transport?.currentFacilityTimeslot) {
        return {
          date: new Date(
            action.payload.transport.currentFacilityTimeslot.startTime
          ),
          text: dayjs(
            action.payload.transport.currentFacilityTimeslot.startTime
          ).format('HH:mm'),
          end: new Date(
            action.payload.transport.currentFacilityTimeslot.endTime ?? ''
          )
        };
      }
      return state;
    case 'GET_ORDER_SUCCESS':
      if (action?.payload?.transport?.currentFacilityTimeslot) {
        return {
          date: new Date(
            action?.payload?.transport?.currentFacilityTimeslot.startTime
          ),
          text: dayjs(
            action?.payload?.transport?.currentFacilityTimeslot.startTime
          ).format('HH:mm'),
          end: new Date(
            action?.payload?.transport?.currentFacilityTimeslot.endTime ?? ''
          )
        };
      }
      return state;
    default:
      return state;
  }
};

export interface PickupProxySelectedAction {
  type: 'SET_PICKUP_PROXY_SELECTED';
  pickupProxySelected: boolean;
}

export const pickupProxySelectedReducer = (
  state = false,
  action: PickupProxySelectedAction | GetOrderSuccessAction
): ReduxStore['checkout']['transport']['pickupProxySelected'] => {
  switch (action.type) {
    case 'SET_PICKUP_PROXY_SELECTED':
      return action.pickupProxySelected;
    case 'GET_ORDER_SUCCESS':
      return !action?.payload?.pickupByBuyer;
    default:
      return state;
  }
};

export const facilitiesReducer = (
  state: Facility[] = [],
  action: GetFacilitiesAction
) => {
  switch (action.type) {
    case 'GET_FACILITIES_SUCCESS':
      return action.payload || state;
    default:
      return state;
  }
};

type FacilityCostAction = {
  type: typeof GET_TRANSPORT_PRICES_SUCCESS;
  payload?: FacilityTransportCost[];
};

export const facilityCostsReducer = (
  state: FacilityTransportCost[] = [],
  action: FacilityCostAction | ResetAction
) => {
  switch (action.type) {
    case 'GET_TRANSPORT_PRICES_SUCCESS':
      return action.payload || state;
    case 'RESET_CHECKOUT_STATE':
      return [];
    default:
      return state;
  }
};

type PriceLoadingAction = {
  type:
    | typeof GET_LOAN_COST_REQUEST
    | typeof GET_LOAN_COST_FAILURE
    | typeof GET_LOAN_COST_SUCCESS;
  payload?: null;
};
export const isPriceLoadingReducer = (
  state = false,
  action: PriceLoadingAction
) => {
  switch (action.type) {
    case 'checkout/GET_LOAN_COST_REQUEST':
      return true;
    case 'checkout/GET_LOAN_COST_SUCCESS':
    case 'checkout/GET_LOAN_COST_FAILURE':
      return false;
    default:
      return state;
  }
};

export type OrderGA4ObjectRequestsType = {
  type:
    | typeof SET_ORDER_GA4_OBJECT_SUCCESS
    | typeof SET_ORDER_GA4_OBJECT_REQUEST
    | typeof SET_ORDER_GA4_OBJECT_FAILURE
    | typeof CLEAR_ORDER_GA4_OBJECT;
  payload?: GA4ObjectProperties;
};

export const orderGA4ObjectReducer = (
  state = null,
  action: OrderGA4ObjectRequestsType
) => {
  switch (action.type) {
    case SET_ORDER_GA4_OBJECT_SUCCESS:
      return action.payload ? { ...action.payload } : null;
    case SET_ORDER_GA4_OBJECT_REQUEST:
    case SET_ORDER_GA4_OBJECT_FAILURE:
    case CLEAR_ORDER_GA4_OBJECT:
      return null;
    default:
      return state;
  }
};

const warrantyReducer = combineReducers<
  ReduxStore['checkout']['services']['warranty']
>({
  months: warrantyMonthsReducer
});

const servicesReducer = combineReducers<ReduxStore['checkout']['services']>({
  warranty: warrantyReducer
});

const financeReducer = combineReducers<ReduxStore['checkout']['financing']>({
  amount: amountReducer,
  downPayment: downPaymentReducer,
  paymentMethod: paymentMethodReducer,
  paymentPeriodMonths: paymentPeriodMonthsReducer,
  selectedMonths: servicesReducer
});

const transportReducer = combineReducers<ReduxStore['checkout']['transport']>({
  deliveryLocation: deliveryLocationReducer,
  facilities: facilitiesReducer,
  pickupPerson: pickupPersonReducer,
  pickupLocation: pickupLocationReducer,
  homeDelivery: homeDeliveryReducer,
  toFacilityId: toFacilityIdReducer,
  costs: facilityCostsReducer,
  availablePickupTimeslots: availablePickupTimeslotsReducer,
  selectedTimeslot: selectedTimeslotReducer,
  pickupProxySelected: pickupProxySelectedReducer,
  pickupProxyPerson: pickupProxyPersonReducer,
  pickupProxyTransportCompany: pickupProxyTransportCompanyReducer
});

export default combineReducers<ReduxStore['checkout']>({
  order: orderReducer,
  step: stepReducer,
  errors: errorsReducer,
  prices: pricesReducer,
  type: checkoutTypeReducer,
  flowType: checkoutFlowTypeReducer,
  services: servicesReducer,
  financing: financeReducer,
  transport: transportReducer,
  buyer: checkoutBuyerReducer,
  completedSteps: completedStepsReducer,
  selectedServices: selectedServicesReducer,
  isLoading: isLoadingReducer,
  isPriceLoading: isPriceLoadingReducer,
  interestInExtraTires: interestInExtraTiresReducer,
  bookedTimeslot: bookedTimeslotReducer,
  isCarPriceChanged: isCarPriceChangedReducer,
  orderGA4Object: orderGA4ObjectReducer
});
