import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import {
  MinMaxIntervalKey,
  minMaxIntervalValues as initialMinMaxIntervalValues
} from '~/config/constants';
import { SliderType } from './components/FilterSelector/types';

export const getMinMaxIntervalValues = (mainCategory?: MainCategory) => {
  switch (mainCategory) {
    case 'heavy_transport':
    case 'machines':
      return {
        ...initialMinMaxIntervalValues,
        odometerReading: [0, 2500000]
      } as Record<MinMaxIntervalKey, SliderType>;

    default:
      return initialMinMaxIntervalValues;
  }
};
