import { useFeatureFlagValue } from '~/App/components/FeatureFlags';
import { isHeavyEquipmentObject } from '~/App/views/ObjectPage/helpers';
import ProcessObject from '../interfaces/ProcessObject';

const useShowExclVat = (processObject: ProcessObject) => {
  const isExclVat = useFeatureFlagValue('p2c-excl-vat');
  const isHeavyEquipment = isHeavyEquipmentObject(processObject);
  const hasDeductibleVat = processObject?.deductibleVat;

  const showExclVat = isHeavyEquipment && hasDeductibleVat && isExclVat;

  return showExclVat;
};

export default useShowExclVat;
