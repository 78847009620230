import { Button, Message, Spacer } from '@kvdbil/components';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  registrationCompleteAction,
  sessionStartSuccess
} from '~/App/shared/actions/session';
import { useParamFromQuery } from '~/App/shared/hooks/useQueryParams';
import { trackGA4AccountCreated } from '~/helpers/client/ga4TrackEvent';
import { createMember } from '~/helpers/orchestration/member';
import { runWithRecaptcha } from '~/helpers/recaptcha';
import { regexEmail } from '~/helpers/regex';
import { formErrors } from '~/helpers/validation';
import { useTranslation } from '~/Locale';
import { handleConversion } from '../../../../AwinTags/AwinConversionElement';
import { affiliateClickId } from '../../../../AwinTags/helper';
import {
  CountryField,
  EmailField,
  FirstNameField,
  LastNameField,
  NationalIdentificationField,
  PasswordField
} from '../../../../Fields/shared';
import AwinAffiliateElement from '../AwinAffiliateElement';
import { useAwinAffiliateRefs, useRecaptcha } from '../../../hooks/authHooks';
import { TermsCheckbox } from '../TermsCheckbox';
import useLocalization from '~/App/shared/hooks/useLocalization';

const FormComponent = styled.form`
  display: grid;
  grid-row-gap: 1rem;
  text-align: left;
`;

const PrivateRegistrationForm = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[] | null>(null);
  const [hasEmail, setHasEmail] = useState(false);
  const { conversionPixelRef, conversionTagRef } = useAwinAffiliateRefs();
  const dispatch = useDispatch();
  const { isSwedishLocale } = useLocalization();

  const emailFromParams = useParamFromQuery('email') ?? '';

  useRecaptcha();

  useEffect(() => {
    if (emailFromParams && regexEmail.test(emailFromParams)) {
      setHasEmail(true);
    } else {
      setHasEmail(false);
    }
  }, [emailFromParams]);

  const { handleSubmit, control, getValues, watch } = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: emailFromParams,
      password: '',
      nationalIdentificationNumber: '',
      acceptsMinimumAgeRequirement: false,
      country: isSwedishLocale
        ? { value: 'se', label: t('SWEDEN') }
        : { value: '', label: '' }
    }
  });

  const onSubmit = useCallback(
    (googleResponse: string) => {
      const values = getValues();

      const nationalIdentificationData =
        values?.nationalIdentificationNumber && values.country.value !== 'se'
          ? {
              nationalIdentificationCountry: values.country.value,
              nationalIdentificationNumber: values.nationalIdentificationNumber
            }
          : {};

      const memberData = {
        person: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          acceptsMinimumAgeRequirement: values.acceptsMinimumAgeRequirement,
          ...nationalIdentificationData
        },
        termsAccepted: true,
        affiliateClickId,
        addresses: [{ country: values?.country?.value }]
      };

      handleConversion(conversionTagRef);

      createMember({
        member: memberData,
        gRecaptchaResponse: googleResponse
      })
        .then(data => {
          trackGA4AccountCreated({
            user_id: data.member.id,
            user_type: 'private'
          });

          handleConversion(conversionPixelRef);

          dispatch(sessionStartSuccess(data));

          const conditionalData =
            values?.country?.value !== 'se'
              ? {
                  country: values.country,
                  nationalIdentificationNumber:
                    values.nationalIdentificationNumber
                }
              : {};

          const registrationCompleteData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            ...conditionalData
          };

          dispatch(registrationCompleteAction(registrationCompleteData));
        })
        .catch((error: Record<string, string>[]) => {
          const errorMessage = formErrors(
            { errors: error },
            'response.data.message',
            true
          );
          setError(errorMessage[0]);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, getValues, conversionPixelRef, conversionTagRef]
  );

  const submitWithRecaptcha = useCallback(() => {
    setLoading(true);
    setError(null);

    runWithRecaptcha(googleResponse => onSubmit(googleResponse));
  }, [onSubmit, setLoading, setError]);

  const country = watch('country');

  return (
    <div>
      <FormComponent onSubmit={handleSubmit(submitWithRecaptcha)} noValidate>
        <FirstNameField control={control} t={t} />

        <LastNameField control={control} t={t} />

        <EmailField isDisabled={hasEmail} control={control} t={t} />

        <PasswordField control={control} t={t} />

        <CountryField control={control} t={t} />

        {country.value !== 'se' && (
          <NationalIdentificationField
            control={control}
            t={t}
            label={t('National identification number (optional)')}
            rules={{
              required: false,
              pattern: undefined,
              maxLength: undefined
            }}
            helperText={t(
              'Entering this information will speed up the process when buying a vehicle.'
            )}
          />
        )}

        <Spacer height={0.5} />

        <TermsCheckbox control={control} />

        {error && (
          <Message fullWidth withIcon type="error">
            {error}
          </Message>
        )}

        <Button
          size="regular"
          fullWidth
          isLoading={isLoading}
          data-test="register-submit"
        >
          {t('Register')}
        </Button>
      </FormComponent>

      <AwinAffiliateElement
        conversionPixelRef={conversionPixelRef}
        conversionTagRef={conversionTagRef}
        orderRef={emailFromParams}
      />
    </div>
  );
};

export default PrivateRegistrationForm;
