import { createSelector } from 'reselect';
import { VerifyPhoneNumber } from '../interfaces/store/VerifyPhoneNumber';
import { ReduxStore } from '../interfaces/store';

const root = createSelector<ReduxStore, VerifyPhoneNumber, VerifyPhoneNumber>(
  ({ verifyPhoneNumber }) => verifyPhoneNumber || {},
  root => root
);

export const verifyPhoneNumberSelector = root;

export const phoneVerificationIsSending = createSelector(
  root,
  state => state.isSendingCode ?? false
);

export const phoneVerificationIsSendingSuccess = createSelector(
  root,
  state => state.isSendingCodeSuccess ?? false
);

export const phoneVerificationIsVerifying = createSelector(
  root,
  state => state.isVerifying ?? false
);

export const phoneVerificationIsMaxRetriesExceeded = createSelector(
  root,
  state => state.isMaxRetriesExceeded ?? false
);

export const phoneVerificationOnBackward = createSelector(
  root,
  state => state.onBackward ?? false
);

export const phoneVerificationIsVerified = createSelector(
  root,
  state => state.isVerified ?? false
);

export const phoneVerificationError = createSelector(
  root,
  state => state.error ?? ''
);

export const phoneVerificationHasUpdatedNumber = createSelector(
  root,
  state => state.updatedPhoneNumber ?? false
);

export const phoneVerificationIsUpdatingPhoneNumber = createSelector(
  root,
  state => state.isUpdatingPhoneNumber ?? false
);

export const phoneVerificationIsEmailLoading = createSelector(
  root,
  state => state.isEmailLoading ?? false
);

export const phoneVerificationIsEmailAuthLoading = createSelector(
  root,
  state => state.isEmailAuthLoading ?? false
);
