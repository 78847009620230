import React from 'react';
import Link from '~/App/shared/components/Link';
import Media from '~/App/shared/components/Media';
import styled from 'styled-components';
import {
  mq,
  Title,
  BodyText,
  theme,
  Subtitle,
  Caption
} from '@kvdbil/components';
import { AboutAreaRecord } from '~/config/generated/graphql';
import StructuredText, {
  StructuredTextProps
} from '~/App/components/DatoCMS/StructuredText';
import { DatoTitleTag } from '~/App/shared/interfaces/datoCMS';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.background.light};
  width: 100%;
  padding: 2rem;

  ${mq('tablet')} {
    display: flex;
  }

  ${mq(null, 'mobileL')} {
    padding: 2rem 1rem;
  }
`;

const BenefitButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0 2rem;
  width: 100%;

  ${mq(null, 'tablet')} {
    flex-direction: column;
  }
`;

const StyledLink = styled(Link)`
  border: 2px solid ${theme.colors.background.gray};
  border-radius: 0.5rem;
  color: ${theme.colors.text.dark};
  padding: 1rem;
  text-align: center;
  width: 100%;
  overflow: hidden;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  ${mq(null, 'tablet')} {
    flex-direction: column;
  }
`;

const BenefitIconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0;

  ${mq(null, 'mobileL')} {
    gap: 0.5rem;
    flex-direction: column;
  }
`;

const BenefitMediaContainer = styled.div`
  width: 4rem;
  margin-top: 0.25rem;
  margin-right: 1rem;
`;

const AboutArea = ({
  title,
  paragraph,
  linkList,
  iconsWithText
}: AboutAreaRecord) => {
  const headingText = title?.[0]?.text;
  const headingTag = title?.[0]?.tag ?? 'h2';
  const benefitsList = linkList.map(linkItem => {
    const { text, url, caption } = linkItem;
    return (
      <StyledLink to={url ?? ''} key={text}>
        <Subtitle>{text}</Subtitle>
        <Caption>{caption}</Caption>
      </StyledLink>
    );
  });

  return (
    <Container>
      <div>
        <Title as={headingTag as DatoTitleTag}>{headingText}</Title>
        <StructuredText data={paragraph as StructuredTextProps['data']} />
      </div>

      <BenefitButtons>{benefitsList}</BenefitButtons>

      <StyledFlex>
        {iconsWithText.map(({ text, icon }, i) => (
          <BenefitIconWrapper key={i}>
            <BenefitMediaContainer>
              <Media
                width={75}
                height={65}
                src={icon?.url ?? ''}
                alt={icon?.alt ?? ''}
                imageType="THUMBNAIL"
              />
            </BenefitMediaContainer>
            <BodyText as="span">{text}</BodyText>
          </BenefitIconWrapper>
        ))}
      </StyledFlex>
    </Container>
  );
};

export default AboutArea;
