import { mq, resetButtonStyle } from '@kvdbil/components';
import React, { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  email as emailSelector,
  idToken as idTokenSelector
} from '~/App/shared/selectors/sessionSelector';
import { resendEmailVerification } from '~/helpers/orchestration/verification';
import { useTranslation } from '~/Locale';

const Conatiner = styled.div`
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.colors.text.light};
  background: ${({ theme }) => theme.colors.secondary.main};
  font-size: 0.75rem;

  ${mq('tablet')} {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
`;
const Content = styled.div`
  max-width: 1024px;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ResendButton = styled.button`
  ${resetButtonStyle};
  color: white;
  text-decoration: underline;
`;

export interface EmailMessageProps {
  onError(): void;
  initialIsClicked?: boolean;
  resend?: typeof resendEmailVerification;
}
export const EmailMessage = ({
  onError,
  initialIsClicked = false,
  resend = resendEmailVerification
}: EmailMessageProps) => {
  const { t } = useTranslation();
  const email = useSelector(emailSelector) ?? '';
  const idToken = useSelector(idTokenSelector);
  const [isClicked, setIsClicked] = useState(initialIsClicked);

  const handleResend = (event: MouseEvent) => {
    event.preventDefault();

    resend({ idToken: idToken })
      .then(() => setIsClicked(prev => !prev))
      .catch(onError);
  };

  return (
    <Conatiner data-test="message-confirm-email">
      <Content>
        <span>
          {t(
            'An email with a link to confirm your email has been sent to %1$s',
            email
          )}
        </span>

        <div>
          {!isClicked ? (
            <ResendButton
              onClick={handleResend}
              data-testid="resend-email-button"
            >
              {t('Resend email verification')}
            </ResendButton>
          ) : (
            <span>{t('A new mail has been sent')}</span>
          )}
        </div>
      </Content>
    </Conatiner>
  );
};

export default EmailMessage;
