import { translateRoute } from '~/App/shared/localization';
import { ReduxDispatch } from '../interfaces/Redux';
import { ReduxStore } from '../interfaces/store';
import { UserAccountType } from '../types/UserAccountType';
import { TranslateFunction } from '~/Locale';

type LoginFlowRegisterStepArgs = {
  origin: string;
  callback?: CallableFunction | null;
};

type LoginFlowStepArgs = {
  navigateCallback: CallableFunction;
};

export const flowEnd = () => ({
  type: 'LOGIN_FLOW_END' as const
});

export const flowStart = () => ({
  type: 'LOGIN_FLOW_START' as const
});

export const loginFlowRegisterStep = ({
  origin,
  callback
}: LoginFlowRegisterStepArgs) => ({
  type: 'LOGIN_FLOW_REGISTER_STEP' as const,
  payload: {
    origin,
    callback
  }
});

export const loginFlowOffCanvas = (offCanvas: boolean) => ({
  type: 'LOGIN_FLOW_OFF_CANVAS' as const,
  offCanvas: offCanvas
});

export const loginFlowStep =
  ({ navigateCallback }: LoginFlowStepArgs) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const {
      loginFlow: { origin, callback },
      session
    } = getState();

    if (!session.isAuthenticated) {
      return;
    }

    typeof callback === 'function' && callback();

    if (origin) {
      navigateCallback(origin);
      return dispatch(flowEnd());
    }

    dispatch(flowEnd());
    return navigateCallback('/');
  };

export const loginFlowEnd = () => (dispatch: ReduxDispatch) =>
  dispatch(flowEnd());

export const accountRegistrationNavigate =
  (
    t: TranslateFunction,
    processObjectId: string,
    accountType: UserAccountType,
    navigateCallback: CallableFunction
  ) =>
  async (dispatch: ReduxDispatch) => {
    const currentSalesLink =
      '/ROUTES.MY_ACCOUNT/ROUTES.MY_ACCOUNT_MY_SALES/ROUTES.MY_ACCOUNT_MY_SALES_CURRENT_SALES/';

    const redirectLink = `${translateRoute(
      t,
      currentSalesLink
    )}${processObjectId}`;

    dispatch(
      loginFlowRegisterStep({
        origin: redirectLink
      })
    );
    return await navigateCallback({
      pathname:
        accountType === 'private'
          ? `${translateRoute(
              t,
              '/ROUTES.MEMBER/ROUTES.MEMBER_REGISTER_PRIVATE'
            )}`
          : `${translateRoute(
              t,
              '/ROUTES.MEMBER/ROUTES.MEMBER_REGISTER_ORGANIZATION'
            )}`
    });
  };

type LoginFlowStartPayload = {
  origin: string;
  callback?: CallableFunction | null;
  navigateCallback: CallableFunction;
  alwaysNavigate?: boolean;
};

/**
 * @deprecated Use `openMenuDrawer` instead.
 * TODO: [KVDBIL-1437] Use same action everywhere
 */
export const loginFlowStart =
  (t: TranslateFunction, payload: LoginFlowStartPayload) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore): void => {
    const {
      origin = '',
      callback,
      navigateCallback,
      alwaysNavigate = false
    } = payload;

    const {
      loginFlow: { offCanvas }
    } = getState();

    dispatch(
      loginFlowRegisterStep({
        origin,
        callback
      })
    );

    if (!offCanvas || alwaysNavigate) {
      return navigateCallback({
        pathname: translateRoute(t, '/ROUTES.MEMBER/ROUTES.MEMBER_SIGN_IN'),
        state: {
          offCanvas: offCanvas
        }
      });
    }

    dispatch(flowStart());
    return void dispatch(loginFlowStep({ navigateCallback }));
  };

export type LoginFlowActions =
  | ReturnType<typeof flowStart>
  | ReturnType<typeof flowEnd>
  | ReturnType<typeof loginFlowRegisterStep>
  | ReturnType<typeof loginFlowOffCanvas>;
