import React, { ComponentType, ReactElement } from 'react';
import styled from 'styled-components';
import {
  Image,
  StructuredText as StructuredTextBase,
  StructuredTextGraphQlResponse,
  renderNodeRule
} from 'react-datocms';
import { Stack, Table, Button, mq, BodyText } from '@kvdbil/components';
import Link from '~/App/shared/components/Link';
import { TableProps } from '@kvdbil/components/types/components/Table';
import { Color, Size } from '@kvdbil/components/types/Types';
import {
  InlineimageRecord,
  NewsletterSignupRecord,
  CarInspirationListRecord,
  HtmlInputRecord,
  TextGridRecord,
  TextSectionRecord,
  ContactItemListRecord,
  DynamicPageFormRecord,
  DynamicPageCustomElementRecord,
  TextTabsBlockRecord,
  DynamicPageValuationFormRecord,
  ButtonModelRecord,
  FeaturedBlocksBlockRecord,
  YoutubeClipRecord,
  ButtonRecord
} from '~/config/generated/graphql';
import Newsletter from '~/App/views/Start/components/Newsletter';
import Section, {
  BackgroundColor,
  ContentBackgroundColor
} from '~/App/shared/components/Layout/Section';
import CarInspirationList from '~/App/views/Blog/components/CarInspirationList';
import {
  renderRule,
  isParagraph,
  isLink,
  isHeading,
  isEmptyDocument
} from 'datocms-structured-text-utils';
import {
  CustomInlineRecord,
  CustomRecord,
  StructuredTextData
} from '~/App/shared/interfaces/datoCMS';
import ContactPageItems from '~/App/views/DynamicPage/components/ContactPageItems';
import TextTabsBlock from '~/App/views/DynamicPage/components/TextTabsBlock';
import ValuationForm from '~/App/views/DynamicPage/components/forms/ValuationForm';
import FeatureBlocksItems from '~/App/views/Start/components/FeatureBlocksItems';
import Auction from '~/App/shared/interfaces/Auction';
import DynamicPageFormDispatcher from '~/App/views/DynamicPage/components/DynamicPageFormDispatcher';
import DynamicPageCustomElemDispatcher from '~/App/views/DynamicPage/components/DynamicPageCustomElemDispatcher';
import YouTubePlayer from '~/App/views/ObjectPage/components/YouTubePlayer';
import { slugify } from '~/helpers/slugify';
import {
  getHeadingByNodeLevel,
  getHeadingByRecordTypename
} from '~/App/components/DatoCMS/StructuredText/utils';

interface InlineimageWrapperPorps {
  $minMaxWidth?: string;
}

const InlineimageWrapper = styled.div<InlineimageWrapperPorps>`
  ${mq('mobileL')} {
    ${({ $minMaxWidth }) =>
      $minMaxWidth && `max-width: ${$minMaxWidth}; min-width: ${$minMaxWidth};`}
  }
`;

const StructuredTextWrapper = styled.div<WrapperProps>`
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: revert;
    padding: revert;
    margin-bottom: 1rem;
  }

  > p {
    margin: revert;
    padding: revert;
  }

  b,
  strong,
  em,
  i {
    font-style: revert;
  }

  > ul {
    list-style: disc;
  }

  > ul,
  ol {
    margin: 1rem 0;
    padding: revert;

    li > p {
      margin: revert;
      padding: revert;
    }
  }

  ${({ contentTextAlign }) =>
    contentTextAlign && `text-align: ${contentTextAlign};`}
`;

const TextGridWrapper = styled.div<{ $columnGap: number }>`
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 0;

  ${mq('tablet')} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${({ $columnGap }) => $columnGap}px;
  }
`;

const TextGridStructuredTextWrapper = styled(StructuredTextWrapper)`
  & > *:first-child {
    margin-top: 0;
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
`;

type WrapperProps = {
  contentTextAlign?: string | null;
};

export type StructuredTextProps = {
  data?: StructuredTextData | null;
  wrapper?: ComponentType;
  wrapperProps?: WrapperProps;
  prefetchedData?: { objects?: Record<string, Auction[]> | null };
};

const StructuredText = ({
  data,
  wrapper: CustomWrapper = StructuredTextWrapper,
  wrapperProps,
  prefetchedData
}: StructuredTextProps) => {
  return (
    <CustomWrapper {...wrapperProps}>
      <StructuredTextBase
        data={data as StructuredTextGraphQlResponse}
        customNodeRules={[
          renderNodeRule(isLink, ({ node, children, key }) => {
            const metaProps: Record<string, string> = {};
            node?.meta?.forEach(({ id, value }) => {
              metaProps[id] = value;
            });
            return (
              <Link key={key} to={node.url} {...metaProps}>
                {children}
              </Link>
            );
          }),
          renderNodeRule(
            isHeading,
            ({ adapter: { renderNode }, node, children, key }) => {
              const headingText = (children?.[0] as ReactElement)?.props
                ?.children?.[0];
              return renderNode(
                getHeadingByNodeLevel(node.level),
                {
                  key,
                  as: `h${node.level}`,
                  id:
                    typeof headingText === 'string'
                      ? slugify(headingText)
                      : undefined
                },
                children
              );
            }
          ),
          renderRule(
            isParagraph,
            ({ adapter: { renderNode }, children, key }) => {
              return renderNode(BodyText, { key, as: 'p' }, children);
            }
          )
        ]}
        renderBlock={({ record }) => {
          const recordTypename =
            record.__typename as CustomRecord['__typename'];
          switch (recordTypename) {
            case 'BodyTextRecord':
              return (
                <BodyText as={(record?.tag ?? 'p') as never}>
                  {record.text as typeof record}
                </BodyText>
              );

            case 'ButtonRecord':
              const { url, text, color, size, openLinkInNewWindow } =
                record as ButtonRecord;
              return (
                <StyledLink
                  target={openLinkInNewWindow ? '_blank' : undefined}
                  to={url ?? '/'}
                >
                  <Button size={size as Size} color={color as Color}>
                    {text}
                  </Button>
                </StyledLink>
              );

            case 'InlineimageRecord':
              const {
                lefttext,
                righttext,
                image,
                imageMaxWidth,
                textPlacement,
                textContent
              } = record as InlineimageRecord;
              if (!image) {
                return <></>;
              }
              const { responsiveImage, url: svgImageUrl, alt } = image;
              const isStructuredText = !isEmptyDocument(textContent);
              const hasText = Boolean(
                lefttext || righttext || isStructuredText
              );
              const minMaxWidth =
                imageMaxWidth || (hasText ? '50%' : undefined);

              const StructuredTextContent = () => (
                <StructuredText
                  data={textContent}
                  wrapper={TextGridStructuredTextWrapper}
                />
              );
              return (
                <Stack direction={{ mobileS: 'column', mobileL: 'row' }}>
                  {!isStructuredText && lefttext && (
                    <BodyText>{lefttext}</BodyText>
                  )}
                  {isStructuredText && textPlacement === 'left' && (
                    <StructuredTextContent />
                  )}
                  {(responsiveImage || svgImageUrl) && (
                    <InlineimageWrapper $minMaxWidth={minMaxWidth}>
                      {responsiveImage && (
                        <Image
                          data={responsiveImage}
                          style={{
                            maxWidth:
                              !hasText && imageMaxWidth
                                ? imageMaxWidth
                                : undefined
                          }}
                        />
                      )}
                      {!responsiveImage && svgImageUrl && (
                        <img
                          src={svgImageUrl}
                          alt={alt ?? 'svg-image'}
                          width="100%"
                        />
                      )}
                    </InlineimageWrapper>
                  )}

                  {!isStructuredText && righttext && (
                    <BodyText>{righttext}</BodyText>
                  )}
                  {isStructuredText && textPlacement === 'right' && (
                    <StructuredTextContent />
                  )}
                </Stack>
              );

            case 'TableRecord':
              const structure = record.tablestructure as TableProps;
              return (
                <Table columns={structure.columns} data={structure.data} />
              );

            case 'HeadlineRecord':
            case 'TitleRecord':
            case 'SubtitleRecord':
              const headingId =
                typeof record.text === 'string'
                  ? slugify(record.text)
                  : undefined;
              const HeadingElement = getHeadingByRecordTypename(recordTypename);
              return (
                <HeadingElement
                  as={(record?.tag ?? 'h2') as never}
                  id={headingId}
                >
                  {record.text as typeof record}
                </HeadingElement>
              );

            case 'NewsletterSignupRecord':
              const { title, description } = record as NewsletterSignupRecord;
              return (
                <Section spacing={2} backgroundColor="sand" maxWidth={35.5}>
                  <Newsletter
                    title={title ?? undefined}
                    description={description ?? undefined}
                  />
                </Section>
              );

            case 'CarInspirationListRecord':
              const { id, queryString, heading, linkText, itemsPerSlide } =
                record as CarInspirationListRecord;

              if (!queryString) {
                return null;
              }

              return (
                <CarInspirationList
                  queryString={queryString}
                  heading={heading ?? ''}
                  linkText={linkText ?? ''}
                  prefetchedObjects={prefetchedData?.objects?.[id]}
                  itemsPerSlide={itemsPerSlide ? itemsPerSlide : undefined}
                />
              );

            case 'HtmlInputRecord':
              const { htmlField } = record as HtmlInputRecord;

              if (!htmlField) {
                return null;
              }

              return (
                <div
                  dangerouslySetInnerHTML={{
                    __html: htmlField
                  }}
                />
              );

            case 'TextSectionRecord':
              const {
                body: sectionBody,
                contentMaxWidth,
                contentBackgroundColor,
                contentPadding,
                contentTextAlign,
                paddingTop,
                paddingBottom,
                backgroundColor
              } = record as TextSectionRecord;
              return (
                <Section
                  backgroundColor={backgroundColor as BackgroundColor}
                  maxWidth={(contentMaxWidth ?? 768) / 16}
                  spacingTop={(paddingTop ?? 24) / 16}
                  spacingBottom={(paddingBottom ?? 24) / 16}
                  contentBackgroundColor={
                    contentBackgroundColor as ContentBackgroundColor
                  }
                  contentPadding={contentPadding ? contentPadding : undefined}
                >
                  <StructuredText
                    data={sectionBody}
                    wrapperProps={{ contentTextAlign }}
                    prefetchedData={prefetchedData}
                  />
                </Section>
              );

            case 'TextGridRecord':
              const { columnGap, columns } = record as TextGridRecord;
              return (
                <TextGridWrapper $columnGap={columnGap || 80}>
                  {columns?.map((column, key) => {
                    if (!isEmptyDocument(column?.body)) {
                      return (
                        <StructuredText
                          data={column?.body}
                          key={`column-${key}`}
                          wrapper={TextGridStructuredTextWrapper}
                        />
                      );
                    }
                  })}
                </TextGridWrapper>
              );

            case 'DynamicPageFormRecord':
              return (
                <DynamicPageFormDispatcher
                  record={record as DynamicPageFormRecord}
                />
              );
            case 'DynamicPageCustomElementRecord':
              return (
                <DynamicPageCustomElemDispatcher
                  record={record as DynamicPageCustomElementRecord}
                />
              );
            case 'ContactItemListRecord':
              const { items } = record as ContactItemListRecord;
              return <ContactPageItems items={items} />;
            case 'TextTabsBlockRecord':
              return <TextTabsBlock record={record as TextTabsBlockRecord} />;
            case 'DynamicPageValuationFormRecord':
              return (
                <ValuationForm
                  record={record as DynamicPageValuationFormRecord}
                />
              );
            case 'FeaturedBlocksBlockRecord':
              const { blockButtons } = record as FeaturedBlocksBlockRecord;
              return <FeatureBlocksItems blockButtons={blockButtons} />;
            case 'YoutubeClipRecord':
              const { videoId } = record as YoutubeClipRecord;
              return videoId ? <YouTubePlayer videoId={videoId} /> : null;

            default:
              return null;
          }
        }}
        renderInlineRecord={({ record }) => {
          switch (record.__typename as CustomInlineRecord['__typename']) {
            case 'ButtonModelRecord':
              const { url, text, color, size, openLinkInNewWindow } =
                record as ButtonModelRecord;
              return (
                <StyledLink
                  target={openLinkInNewWindow ? '_blank' : undefined}
                  to={url ?? '/'}
                >
                  <Button size={size as Size} color={color as Color}>
                    {text}
                  </Button>
                </StyledLink>
              );
            default:
              return null;
          }
        }}
      />
    </CustomWrapper>
  );
};

export default StructuredText;
