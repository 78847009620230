import styled from 'styled-components';
import { mq } from '@kvdbil/components';
import React from 'react';
import { FinancingProps } from '~/App/shared/components/ProductCard/types';
import FinancingRowItem from '~/App/shared/components/ProductCard/components/FinancingRowItem';
import { Color } from '@kvdbil/components/types/Types';
import { useFeatureFlagValue } from '~/App/components/FeatureFlags';

const Container = styled.div<{ $color: Color | false }>`
  margin-top: 1rem;
  border-top: 2px solid
    ${({ theme, $color }) =>
      $color ? theme.colors[$color].main : theme.colors.gray.light6};

  ${mq('tablet')} {
    margin-top: 0.5rem;
  }
`;

const Financing = ({ rows, color }: FinancingProps) => {
  const ispMergedBuyingMethodsColors = useFeatureFlagValue(
    'p2c-merged-buying-methods-colors'
  );
  return (
    <Container $color={ispMergedBuyingMethodsColors ? false : color}>
      {rows.map((row, index) => (
        <FinancingRowItem
          key={index}
          label={row.label}
          value={row.value}
          isPrice={row.isPrice}
          initialPrice={row.initialPrice}
        />
      ))}
    </Container>
  );
};

export default Financing;
