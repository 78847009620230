import React from 'react';
import { useTranslation } from '~/Locale';
import FacebookAuthButton from '../../../components/FacebookAuthButton';
import PrivateRegistrationForm from './Form';
import { BodyText, Button, Flex, Spacer } from '@kvdbil/components';
import styled from 'styled-components';
import { useFeatureFlagValue } from '~/App/components/FeatureFlags';

const ButtonWrapper = styled(Flex)`
  margin-top: 2rem;
`;

const CenteredBodyText = styled(BodyText)`
  text-align: center;
`;

export const PrivateRegistrationView = () => {
  const { t } = useTranslation();
  const bankIDEnabled = useFeatureFlagValue('bankid-register');
  const isFacebookEnabled = useFeatureFlagValue('sign-in-facebook');

  return (
    <div>
      {isFacebookEnabled && (
        <>
          <CenteredBodyText>
            {t('Create an account with Facebook...')}
          </CenteredBodyText>

          <Spacer />

          <FacebookAuthButton />

          <Spacer height={2} />

          <CenteredBodyText>
            {t('Or create an account with e-mail...')}
          </CenteredBodyText>

          <Spacer />
        </>
      )}

      <PrivateRegistrationForm />

      {bankIDEnabled && (
        <ButtonWrapper>
          <Button variant="flat" color="neutral">
            {t('I want to use BankID instead')}
          </Button>
        </ButtonWrapper>
      )}
    </div>
  );
};

export default PrivateRegistrationView;
