import React, { useState } from 'react';
import SiteMessage from '~/App/shared/components/SiteMessage';
import ConfirmUserSettingsMessage from './ConfirmUserSettingsMessage';
import { useDispatch, useSelector } from 'react-redux';
import { dismissMessage } from '~/App/shared/actions/messages';
import { Session } from '~/App/shared/interfaces/store/Session';
import { TargetedAuctionStatus } from '~/App/shared/interfaces/store/TargetedAuctions';
import { isConnectionError } from '~/App/shared/selectors/connectionSelector';
import { messagesTop } from '~/App/shared/selectors/global';
import { session as sessionSelector } from '~/App/shared/selectors/sessionSelector';
import { notSeenTargetedAuctionGroups } from '~/App/shared/selectors/targetedAuctions';
import { SiteMessageRecord } from '~/config/generated/graphql';
import { requestErrorHandler } from '~/helpers/notifyError';
import { setUserHasSeenLatestAuctions } from '~/helpers/orchestration/myPages';
import { getIsWebsocketSupported } from '~/helpers/websocket';
import { useTranslation } from '~/Locale';
import EmailMessage from './EmailMessage';

type SiteMessageTypeWDismiss = SiteMessageRecord & {
  onDismiss(): void;
};
interface AddTargetedAuctionHandlerProps {
  session: Session;
  groupsNotSeenLatestAuctions: TargetedAuctionStatus[];
  onMessageDismiss(cookie: string, type: string): void;
}
const addTargetedAuctionDismissHandler =
  ({
    session,
    groupsNotSeenLatestAuctions = [],
    onMessageDismiss
  }: AddTargetedAuctionHandlerProps) =>
  (message: SiteMessageRecord): SiteMessageTypeWDismiss[] => {
    return groupsNotSeenLatestAuctions.map(targetedAuctionStatus => {
      const options = {
        idToken: session.auth.idToken,
        groupId: targetedAuctionStatus.groupId,
        memberId: session.member.id,
        hasSeen: true
      };

      const handleClick = () => {
        setUserHasSeenLatestAuctions(options)
          .then(() => {
            onMessageDismiss(
              message.id,
              message.siteMessageType ?? 'Information'
            );
          })
          .catch(requestErrorHandler);
      };

      return {
        ...message,
        onDismiss: handleClick
      };
    });
  };

const HeaderMessages = () => {
  const { t } = useTranslation();
  const [hasResendEmailError, setHasResendEmailError] = useState(false);
  const [removedConnectionError, setRemovedConnectionError] = useState(false);

  const dispatch = useDispatch();
  const session = useSelector(sessionSelector);
  const messages = useSelector(messagesTop) ?? [];
  const hasConnectionError = useSelector(isConnectionError);
  const groupsNotSeenLatestAuctions = useSelector(notSeenTargetedAuctionGroups);

  const showConfirmSettingsMessage =
    session.isAuthenticated &&
    session.member &&
    session.member.consentAcquired === false;

  const onMessageDismiss = (id: string, type: string) => {
    dispatch(dismissMessage(id, type));
  };

  const userSettingsMessages = messages.filter(
    message => message.siteMessageType === 'ConfirmUserSettings'
  );

  const filteredTargetedAuctionMessages = messages.filter(
    message => message.siteMessageType === 'TargetedAuctionInformation'
  );

  const targetedAuctionMessages = filteredTargetedAuctionMessages.map(
    addTargetedAuctionDismissHandler({
      session,
      onMessageDismiss,
      groupsNotSeenLatestAuctions
    })
  );

  const nonUserMessages = messages.filter(
    message =>
      !['TargetedAuctionInformation', 'ConfirmUserSettings'].includes(
        message.siteMessageType as string
      )
  );

  const allMessages = [
    ...nonUserMessages,
    ...targetedAuctionMessages.flat(Infinity)
  ];
  const isWebsocketSupported = getIsWebsocketSupported();
  const showConnectionError =
    hasConnectionError && !removedConnectionError && isWebsocketSupported;

  return (
    <div>
      {showConfirmSettingsMessage &&
        userSettingsMessages.map(message => (
          <ConfirmUserSettingsMessage
            key={message.id}
            message={message.message ?? ''}
          />
        ))}

      {session.isAuthenticated && !session.member?.person?.emailVerified && (
        <EmailMessage onError={() => setHasResendEmailError(true)} />
      )}

      {hasResendEmailError && (
        <SiteMessage
          type="Error"
          onDismiss={() => setHasResendEmailError(false)}
        >
          {t('The confirmation email could not be sent')}
        </SiteMessage>
      )}

      {showConnectionError && (
        <SiteMessage
          type="Error"
          onDismiss={() => setRemovedConnectionError(true)}
        >
          {t('You seem to have lost connection. Try reloading.')}
        </SiteMessage>
      )}

      {allMessages.map(
        (message: SiteMessageRecord | SiteMessageTypeWDismiss) => (
          <SiteMessage
            key={message?.id}
            type={message?.siteMessageType ?? ''}
            onDismiss={() =>
              'onDismiss' in message
                ? message.onDismiss()
                : onMessageDismiss(
                    message?.id,
                    message?.siteMessageType ?? 'Information'
                  )
            }
          >
            {message.message ?? ''}
          </SiteMessage>
        )
      )}
    </div>
  );
};

export default HeaderMessages;
