import { DrawerMenuAction } from '../actions/drawerMenu';
import { ReduxStore } from '../interfaces/store';

type DrawerMenuState = ReduxStore['drawerMenu'];

export const initialState: DrawerMenuState = {
  isOpen: false,
  view: 'MAIN'
};

export const drawerMenuReducer = (
  state: DrawerMenuState = initialState,
  action: DrawerMenuAction
): ReduxStore['drawerMenu'] => {
  switch (action.type) {
    case 'CLOSE_MENU_DRAWER':
      return {
        ...state,
        isOpen: false,
        view: 'MAIN'
      };
    case 'OPEN_MENU_DRAWER':
      return {
        ...state,
        isOpen: true,
        view: action.payload
      };
    default:
      return state;
  }
};

export default drawerMenuReducer;
