import { useCmsData } from '~/App/shared/hooks/useCmsData';
import { CheckoutData } from '~/App/shared/interfaces/store/CmsData';
import {
  CheckoutFlowType,
  CheckoutType
} from '~/App/shared/types/CheckoutType';
import {
  CheckoutConfirmationRecord,
  CheckoutDocument,
  CheckoutQuery,
  CheckoutStepDeliveryCommonRecord,
  CheckoutStepFinancingCommonRecord,
  CheckoutStepOwnerDetailsCarDealerRecord,
  CheckoutStepSummaryCommonRecord
} from '~/config/generated/graphql';

export const handleCheckoutCmsData = ({ checkout }: CheckoutQuery) => {
  return {
    title: checkout?.title,
    description: checkout?.description,
    auctionTitle: checkout?.auctionTitle,
    auctionDescription: checkout?.auctionDescription,
    closedDescription: checkout?.closedDescription,
    finalInformationText: checkout?.finalInformationText,
    finalSubmitButtonText: checkout?.finalSubmitButtonText,
    finalSubmitButtonAuctionText: checkout?.finalAuctionSubmitButtonText,
    acceptPolicyText: checkout?.acceptPolicyText,
    acceptPolicyLinkText: checkout?.acceptPolicyLinkText,
    acceptSitePolicyLinkText: checkout?.acceptSitePolicyLinkText,
    acceptDeliveryPolicyText: checkout?.acceptDeliveryPolicyText,
    acceptPickupPolicyText: checkout?.acceptPickupPolicyText,
    acceptBuyerTransferPolicyText: checkout?.acceptBuyerTransferPolicyText,
    policies: {
      private: checkout?.policyPrivate?.content,
      company: checkout?.policyCompany?.content,
      site: checkout?.policySite?.content
    },
    steps: {
      financing: {
        ...checkout?.stepsFinancing,
        details: checkout?.stepsFinancing
          ?.details as CheckoutStepFinancingCommonRecord
      },
      ownerDetails: {
        ...checkout?.stepsOwnerDetails,
        details: checkout?.stepsOwnerDetails
          ?.details as CheckoutStepOwnerDetailsCarDealerRecord
      },
      delivery: {
        ...checkout?.stepsDelivery,
        details: checkout?.stepsDelivery
          ?.details as CheckoutStepDeliveryCommonRecord
      },
      summary: {
        ...checkout?.stepsSummary,
        details: checkout?.stepsSummary
          ?.details as CheckoutStepSummaryCommonRecord
      }
    },
    confirmation: handleConfirmationData(
      checkout?.confirmationData as CheckoutConfirmationRecord[] | undefined
    )
  };
};

const handleConfirmationData = (
  confirmationData: CheckoutConfirmationRecord[] | undefined
) => {
  return {
    AUCTION: confirmationData?.find(c => c.checkoutType === 'AUCTION'),
    BUY_NOW: confirmationData?.find(c => c.checkoutType === 'BUY_NOW'),
    ALL: confirmationData?.find(c => c.checkoutType === 'ALL')
  };
};

export const getConfirmationCMSData = (
  checkoutType: CheckoutType,
  checkoutFlow: CheckoutFlowType | undefined,
  cmsData: ReturnType<typeof handleConfirmationData>
) => {
  if (
    checkoutFlow === 'GENERAL' &&
    ['AUCTION', 'BUY_NOW'].includes(checkoutType)
  ) {
    return cmsData[checkoutType];
  }

  return cmsData.ALL;
};

interface UseCheckoutCMSData {
  checkoutFlow?: CheckoutFlowType;
}

export const useCheckoutCmsData = ({ checkoutFlow }: UseCheckoutCMSData) =>
  useCmsData<CheckoutQuery, CheckoutData>({
    storeKey: 'checkout',
    storeNestedKey: checkoutFlow,
    query: CheckoutDocument,
    options: {
      variables: {
        flowType: checkoutFlow
      }
    }
  });
