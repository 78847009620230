import {
  BankidIcon,
  BodyText,
  Button,
  MailIcon,
  Stack
} from '@kvdbil/components';
import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '~/Locale';
import FacebookAuthButton from '../../components/FacebookAuthButton';
import { SignInOption } from '../types';
import { useFeatureFlagValue } from '~/App/components/FeatureFlags';

const StyledOptionsButton = styled(Button)`
  gap: 1rem;
`;

const centerText = css`
  text-align: center;
`;

const StyledBodyText = styled(BodyText)`
  ${centerText}
`;

export type SignInOptionButtonsProps = {
  activeSignInOption: SignInOption;
  onPressBankId: () => void;
  onPressEmail: () => void;
  onPressFacebook: () => void;
};

export const SignInOptionButtons = ({
  activeSignInOption,
  onPressBankId,
  onPressEmail
}: SignInOptionButtonsProps) => {
  const { t } = useTranslation();
  const isBankIdEnabled = useFeatureFlagValue('sign-in-bank-id');
  const isFacebookEnabled = useFeatureFlagValue('sign-in-facebook');

  const showBankId = activeSignInOption !== 'BANKID' && isBankIdEnabled;
  const showEmail = activeSignInOption !== 'EMAIL';
  const showFacebook = activeSignInOption !== 'FACEBOOK' && isFacebookEnabled;

  // if we wont have any other options to sign in with, we don't want to show this component..
  if (!showEmail && !isBankIdEnabled && !isFacebookEnabled) {
    return null;
  }

  return (
    <Stack rowGap={0.5}>
      <StyledBodyText>{t('...or sign in with:')}</StyledBodyText>
      {showBankId && (
        <StyledOptionsButton
          variant="outline"
          size="regular"
          color="neutral"
          onClick={onPressBankId}
          fullWidth
          data-testid="sign-in-with-bankid"
        >
          <BankidIcon fontSize="1.5rem" />
          {t('BankID')}
        </StyledOptionsButton>
      )}
      {showEmail && (
        <StyledOptionsButton
          variant="outline"
          size="regular"
          color="neutral"
          onClick={onPressEmail}
          fullWidth
          data-testid="sign-in-with-email"
        >
          <MailIcon fontSize="1.5rem" />
          {t('Email')}
        </StyledOptionsButton>
      )}
      {showFacebook && (
        <FacebookAuthButton
          size="regular"
          data-testid="sign-in-with-facebook"
        />
      )}
    </Stack>
  );
};
