import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import {
  Tabs,
  Tab,
  Subtitle,
  Spacer,
  BodyText,
  Flex,
  Title,
  Headline
} from '@kvdbil/components';
import PrivateRegistrationView from './components/PrivateRegistration';
import { CompanyRegistration } from './components/CompanyRegistration';
import RegistrationComplete from './components/RegistrationComplete';
import { useDispatch, useSelector } from 'react-redux';
import { registrationCompleteValuesSelector } from '../../../selectors/sessionSelector';
import { registrationCompleteAction } from '../../../actions/session';
import { getRegistrationTitle } from './registrationHelpers';

const RegistrationContainer = styled(Flex)`
  position: relative;
`;

interface Props {
  callback?: CallableFunction;
  onClose?(): void;
  showTitle?: boolean;
  titleComponent?: 'subtitle' | 'title' | 'headline';
  showDescription?: boolean;
  initialTab?: 'company' | 'private';
  onPrivateClick?(): void;
  onCompanyClick?(): void;
}

const Registration = ({
  onClose,
  showTitle = true,
  titleComponent = 'headline',
  showDescription = false,
  initialTab,
  onPrivateClick,
  onCompanyClick
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const registrationCompleteValues = useSelector(
    registrationCompleteValuesSelector
  );
  const [activeTab, setActiveTab] = useState(initialTab === 'company' ? 1 : 0);

  const isCompany = activeTab === 1;

  const createTitleElement = useCallback(() => {
    const title = getRegistrationTitle(isCompany, t);

    switch (titleComponent) {
      case 'subtitle':
        return <Subtitle>{title}</Subtitle>;
      case 'title':
        return <Title>{title}</Title>;
      case 'headline':
      default:
        return <Headline>{title}</Headline>;
    }
  }, [isCompany, titleComponent, t]);

  const handleOnClose = () => {
    dispatch(registrationCompleteAction(null));
    onClose?.();
  };

  if (registrationCompleteValues) {
    return (
      <RegistrationComplete
        {...registrationCompleteValues}
        onClose={handleOnClose}
      />
    );
  }

  return (
    <RegistrationContainer
      direction={'column'}
      gap={3}
      style={{ textAlign: 'center' }}
    >
      {showTitle && createTitleElement()}

      {showDescription && (
        <BodyText>
          {t('Create a free account and set up notifications or buy cars.')}
        </BodyText>
      )}

      <Tabs
        justify={'space-around'}
        activeColor="secondary"
        activeIndex={activeTab ? 1 : 0}
      >
        <Tab
          label={t('Private')}
          onClick={() => {
            setActiveTab(0);
            onPrivateClick?.();
          }}
        >
          <Spacer />
          <PrivateRegistrationView />
        </Tab>

        <Tab
          label={t('Organization')}
          onClick={() => {
            setActiveTab(1);
            onCompanyClick?.();
          }}
        >
          <Spacer />
          <CompanyRegistration />
        </Tab>
      </Tabs>
    </RegistrationContainer>
  );
};

export default Registration;
