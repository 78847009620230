import React from 'react';
import { ArrowRightIcon, CloseIcon, TinyTitleBold } from '@kvdbil/components';
import styled from 'styled-components';

const ChildrenContainer = styled.div`
  padding: 2rem;
  height: 100%;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1rem;
`;

const MenuTitle = styled(TinyTitleBold)`
  text-align: center;
  width: 100%;
  font-size: 1rem;
`;

const Close = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackArrow = styled(ArrowRightIcon)`
  cursor: pointer;
  font-size: 1.75rem;
  transform: rotate(180deg);
`;

type Props = {
  title?: string;
  onBack?(): void;
  onClose?(): void;
  children: React.ReactNode;
};

export const MenuViewContainer = ({
  title = '',
  onBack,
  onClose,
  children
}: Props) => {
  return (
    <>
      {(onBack || onClose) && (
        <Header>
          {onBack && <BackArrow onClick={onBack} />}

          {title && <MenuTitle>{title}</MenuTitle>}
          {onClose && (
            <Close onClick={onClose}>
              <CloseIcon fontSize={32} />
            </Close>
          )}
        </Header>
      )}
      <ChildrenContainer>{children}</ChildrenContainer>
    </>
  );
};
