import React, { useEffect, useState } from 'react';
import LanguageSelect from '~/App/shared/localization/components/LanguageSelect';
import {
  ArrowRightIcon,
  BodyText,
  Button,
  Caption,
  Flex,
  Stack,
  TinyTitleBold,
  theme
} from '@kvdbil/components';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated as isAuthenticatedSelector } from '~/App/shared/selectors/sessionSelector';
import { NavigationData } from '~/App/shared/interfaces/store/CmsData';
import { useCmsData } from '~/App/shared/hooks/useCmsData';
import {
  NavigationDocument,
  NavigationQuery
} from '~/config/generated/graphql';
import Link from '../../../../../shared/components/Link';
import { MenuViewContainer } from './MenuViewContainer';
import {
  closeMenuDrawer,
  openMenuDrawer
} from '~/App/shared/actions/drawerMenu';

const DrawerMenuBottomContainer = styled(Stack)`
  background: ${({ theme }) => theme.colors.background.gray};
  width: 100%;
  padding: 3rem 2rem;
  padding-bottom: 1rem;
  text-align: left;

  & > * {
    flex: 1;
  }
`;

const PrimaryLink = styled(TinyTitleBold)`
  font-size: 1.25rem;
  text-decoration: underline;
`;

const PrimaryCaption = styled(Caption)`
  color: ${({ theme }) => theme.colors.gray.dark3};
`;

const AccountButton = styled(Button)`
  flex: 1;
`;

const FullWidthLink = styled(Link)`
  width: 100%;
  flex: 1;
`;

export const MenuMainView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: cmsData } = useCmsData<NavigationQuery, NavigationData>({
    storeKey: 'navigation',
    query: NavigationDocument
  });
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [selectedNavigation, setSelectedNavigation] =
    useState<NavigationData | null>(cmsData);
  const [isSubNavigation, setIsSubNavigation] = useState(false);

  const handleOnClose = () => {
    dispatch(closeMenuDrawer());
  };

  const handleLogin = () => {
    dispatch(openMenuDrawer('LOGIN'));
  };

  const handleRegister = () => {
    dispatch(openMenuDrawer('REGISTER'));
  };

  const handleSubNavigation = (subNavigation: NavigationData) => {
    setIsSubNavigation(true);
    setSelectedNavigation(subNavigation);
  };

  const handleBack = () => {
    setIsSubNavigation(false);
    setSelectedNavigation(cmsData);
  };

  useEffect(() => {
    if (cmsData) {
      setSelectedNavigation(cmsData);
    }
  }, [cmsData]);

  return (
    <>
      <MenuViewContainer
        title={selectedNavigation?.navigationTitle ?? ''}
        onClose={handleOnClose}
        onBack={isSubNavigation ? handleBack : undefined}
      >
        <Stack spacing={1.5}>
          {selectedNavigation?.primaryNavigation?.map(
            ({ text, url, description, subNavigation }) => (
              <React.Fragment key={text}>
                {subNavigation ? (
                  <div style={{ cursor: 'pointer' }}>
                    <Flex
                      justify="space-between"
                      onClick={() =>
                        handleSubNavigation(subNavigation as NavigationData)
                      }
                    >
                      <PrimaryLink>{text}</PrimaryLink>
                      {subNavigation && (
                        <ArrowRightIcon width="1.5rem" height="1.5rem" />
                      )}
                    </Flex>

                    {description && (
                      <PrimaryCaption>{description}</PrimaryCaption>
                    )}
                  </div>
                ) : (
                  <Link key={text} to={String(url)}>
                    <PrimaryLink>{text}</PrimaryLink>

                    {description && (
                      <PrimaryCaption>{description}</PrimaryCaption>
                    )}
                  </Link>
                )}
              </React.Fragment>
            )
          )}
        </Stack>
      </MenuViewContainer>

      <DrawerMenuBottomContainer>
        {isAuthenticated && (
          <Stack style={{ marginBottom: '2rem' }}>
            {selectedNavigation?.loggedInNavigationTitle && (
              <TinyTitleBold>
                {selectedNavigation?.loggedInNavigationTitle}
              </TinyTitleBold>
            )}
            {selectedNavigation?.loggedInNavigation?.map(({ text, url }) => (
              <Link key={text} to={String(url)}>
                <BodyText>{text}</BodyText>
              </Link>
            ))}
          </Stack>
        )}

        <Stack>
          {selectedNavigation?.secondaryNavigationTitle && (
            <TinyTitleBold>
              {selectedNavigation?.secondaryNavigationTitle}
            </TinyTitleBold>
          )}
          {selectedNavigation?.secondaryNavigation?.map(({ text, url }) => (
            <Link key={text} to={String(url)}>
              <BodyText>{text}</BodyText>
            </Link>
          ))}
        </Stack>

        <Flex style={{ marginTop: '2rem' }}>
          {isAuthenticated ? (
            <FullWidthLink to="/ROUTES.LOGOUT">
              <AccountButton
                size="regular"
                color="neutral"
                variant="outline"
                fullWidth
              >
                {t('Log out')}
              </AccountButton>
            </FullWidthLink>
          ) : (
            <>
              <AccountButton
                color="neutral"
                variant="outline"
                size="regular"
                style={{ border: `1px solid ${theme.colors.text.dark}` }}
                onClick={handleRegister}
              >
                {t('Register')}
              </AccountButton>

              <AccountButton
                size="regular"
                color="secondary"
                onClick={handleLogin}
              >
                {t('Sign in')}
              </AccountButton>
            </>
          )}
        </Flex>

        <Flex>
          <LanguageSelect isPlacedInHeader={false} />
        </Flex>
      </DrawerMenuBottomContainer>
    </>
  );
};
