import { DrawerMenuView } from '../interfaces/store/DrawerMenu';

export const closeMenuDrawer = () => ({
  type: 'CLOSE_MENU_DRAWER' as const
});

export const openMenuDrawer = (payload: DrawerMenuView) => ({
  type: 'OPEN_MENU_DRAWER' as const,
  payload
});

export type DrawerMenuAction =
  | ReturnType<typeof closeMenuDrawer>
  | ReturnType<typeof openMenuDrawer>;
