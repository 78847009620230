import {
  filter,
  evolve,
  always,
  T,
  F,
  pathOr,
  reject,
  propOr,
  assoc,
  append,
  any,
  propEq,
  map,
  pipe,
  ifElse
} from 'ramda';
import { SellFlowAction } from '../actions/sellflowActions';
import { ReduxStore } from '../interfaces/store';
import { BusinessProposal } from '../interfaces/store/SellFlow';

export const initialDefaultContactDetails = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  whenToSell: { value: 'ASAP', label: 'Soon' },
  whenToContact: { value: '__AB_TEST_HIDDEN__', label: 'ab test hidden' },
  consent: false,
  campaignCode: ''
};

export const initialState: ReduxStore['sellFlow1'] = {
  defaultContactDetails: initialDefaultContactDetails,
  prefilledRegistrationPlate: '',
  registrationPlate: '',
  vehicleInfoLocked: false,
  prefilledOdometerReadingInKilometers: '',
  odometerReading: '',
  odometerLocked: false,
  odometerUnknown: false,
  isVehicleInfoLoading: false,
  isOdometerLoading: false,
  isTrimOptionsEdited: false,
  isTrimOptionsLoading: false,
  leadObjectInformation: null,
  leadObjectInformationError: '',
  packageSelected: '',
  addonsSelected: [],
  packageUnknown: null,
  trimOptionsLocked: false,
  trimOptionsUnknown: false,
  valuationStandardAddonsSelected: [],
  businessProposalLoading: false,
  businessProposalError: '',
  businessProposal: null,
  selectReservationPriceLoading: false,
  salesAgreementLoading: false,
  salesAgreementError: '',
  salesAgreement: null,
  contactDetails: {},
  token: '',
  valuationClosed: true,
  salesMethodClosed: true,
  salesMethod: '',
  ticketRole: null
};

const SellFlow1Reducer = (
  state = initialState,
  action: SellFlowAction
): ReduxStore['sellFlow1'] => {
  switch (action.type) {
    case 'SELLFLOW_RESET':
      return initialState;

    case 'SELLFLOW_SET_REGISTRATION_PLATE':
      return evolve(
        {
          registrationPlate: always(action.payload),
          odometerReading: always(''),
          packageSelected: always(''),
          addonsSelected: always([]),
          valuationStandardAddonsSelected: always([])
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_SET_ODOMETER_READING':
      return evolve(
        {
          odometerReading: always(action.payload?.toString() ?? ''),
          odometerUnknown: F
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_ODOMETER_UNKNOWN':
      return evolve({ odometerReading: always(''), odometerUnknown: T }, state);

    case 'SELLFLOW_SET_TOKEN':
      return evolve(
        {
          token: always(action.payload)
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_PREFILLED_REGISTRATION_PLATE':
      return evolve(
        {
          prefilledRegistrationPlate: always(action.payload),
          vehicleInfoLocked: T
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_PREFILLED_ODOMETER_READING': {
      const odometerReading = Number(action.payload);
      const prefilledOdometerReadingInKilometers = !Number.isNaN(
        odometerReading
      )
        ? Math.round(odometerReading * 10)
        : '';

      return {
        ...initialState,
        ...state,
        prefilledOdometerReadingInKilometers:
          prefilledOdometerReadingInKilometers.toString(),
        odometerLocked: false
      };
    }

    case 'SELLFLOW_VEHICLE_INFO_LOCK':
      return evolve({ vehicleInfoLocked: T, valuationClosed: F }, state);

    case 'SELLFLOW_VEHICLE_INFO_UNLOCK':
      return evolve(
        {
          defaultContactDetails: always(state.defaultContactDetails)
        },
        initialState
      );

    case 'SELLFLOW_ODOMETER_LOCK':
      return evolve(
        { odometerLocked: always(action.payload), valuationClosed: F },
        Object.assign({}, initialState, state)
      );

    case 'SELL_FLOW_VEHICLE_INFO_REQUEST':
      return evolve(
        {
          isVehicleInfoLoading: T,
          leadObjectInformationError: always(''),
          odometerLocked: F,
          trimOptionsLocked: F
        },
        Object.assign({}, initialState, state)
      );

    case 'GET_VALUATION_SUCCESS': {
      if (!action.payload?.requiresManualValuation) {
        return evolve(
          {
            odometerLocked: T,
            vehicleInfoLocked: T,
            isOdometerLoading: F,
            isVehicleInfoLoading: F,
            leadObjectInformation: always(action.payload),
            odometerReading: always(action.meta.odometerReading.toString()),
            registrationPlate: always(action.meta.registrationPlate),
            prefilledRegistrationPlate: always(action.meta.registrationPlate)
          },
          Object.assign({}, initialState, state)
        );
      }
      return state;
    }

    case 'SELL_FLOW_VEHICLE_INFO_SUCCESS':
      return evolve(
        {
          isVehicleInfoLoading: F,
          vehicleInfoLocked: T,
          leadObjectInformation: always(action.payload)
        },
        Object.assign({}, initialState, state)
      );

    case 'SELL_FLOW_VEHICLE_INFO_FAILURE':
      return evolve(
        {
          isVehicleInfoLoading: F,
          vehicleInfoLocked: F,
          leadObjectInformation: always(null),
          leadObjectInformationError: always(action.payload)
        },
        Object.assign({}, initialState, state ?? {})
      );

    case 'SELL_FLOW_ODOMETER_REQUEST':
      return evolve(
        {
          isOdometerLoading: T,
          leadObjectInformationError: always('')
        },
        Object.assign({}, initialState, state)
      );

    case 'SELL_FLOW_ODOMETER_SUCCESS':
      return evolve(
        {
          isOdometerLoading: F,
          vehicleInfoLocked: T,
          odometerLocked: T,
          leadObjectInformation: always(action.payload)
        },
        Object.assign({}, initialState, state)
      );

    case 'SELL_FLOW_ODOMETER_FAILURE':
      return evolve(
        {
          isOdometerLoading: F,
          odometerLocked: F,
          leadObjectInformationError: always(action.payload)
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_TRIM_OPTIONS_REQUEST':
      return evolve(
        {
          isTrimOptionsLoading: T,
          leadObjectInformationError: always('')
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_TRIM_OPTIONS_SUCCESS':
      return evolve(
        {
          isTrimOptionsLoading: F,
          vehicleInfoLocked: T,
          leadObjectInformation: always(action.payload)
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_TRIM_OPTIONS_FAILURE':
      return evolve(
        {
          isTrimOptionsLoading: F,
          leadObjectInformation: always(null),
          vehicleInfoLocked: F,
          leadObjectInformationError: always(action.payload)
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_VEHICLE_INFO_DEFAULTS':
      const packages = action?.payload?.technicalSpecification?.packages ?? [];
      const packageSelected = packages.find(item => item.default === true);

      return evolve(
        {
          packageSelected: always(packageSelected?.key ?? ''),
          addonsSelected: pipe(
            pathOr([], ['payload', 'technicalSpecification', 'addons']),
            filter(propEq('default', true)),
            map(assoc('value', true)),
            always
          )(action),
          valuationStandardAddonsSelected: pipe(
            pathOr(
              [],
              ['payload', 'technicalSpecification', 'valuationStandardAddons']
            ),
            filter(propEq('default', true)),
            map(assoc('value', true)),
            always
          )(action)
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_BUSINESS_PROPOSAL_REQUEST':
      return evolve(
        {
          businessProposalLoading: T,
          businessProposal: always(null),
          businessProposalError: always('')
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_BUSINESS_PROPOSAL_SUCCESS': {
      return evolve(
        {
          businessProposalLoading: F,
          businessProposal: always(
            action.payload?.businessProposal as BusinessProposal // TODO: Remove this "as".
          ),
          token: always(action.payload.token ?? ''),
          businessProposalError: always('')
        },
        Object.assign({}, initialState, state)
      );
    }

    case 'SELLFLOW_BUSINESS_PROPOSAL_FAILURE':
      return evolve(
        {
          businessProposalLoading: F,
          businessProposal: always(null),
          token: always(''),
          businessProposalError: always(String(action.payload))
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_SALES_AGREEMENT_REQUEST':
      return evolve(
        {
          salesAgreementLoading: T,
          salesAgreement: always(null),
          salesAgreementError: always('')
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_SALES_AGREEMENT_LOADING': {
      return { ...state, salesAgreementLoading: action.payload };
    }

    case 'SELLFLOW_SALES_AGREEMENT_SUCCESS':
      return evolve(
        {
          salesAgreementLoading: F,
          salesAgreement: always(propOr({}, 'payload', action)),
          salesAgreementError: always('')
        },
        Object.assign({}, initialState, state)
      );
    case 'SELLFLOW_SALES_AGREEMENT_FAILURE':
      return evolve(
        {
          salesAgreementLoading: F,
          salesAgreement: always(null),
          salesAgreementError: always(String(action.payload))
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_SELECT_RESERVATION_PRICE_REQUEST':
      return evolve(
        {
          selectReservationPriceLoading: T
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_SELECT_RESERVATION_PRICE_SUCCESS':
      return evolve(
        {
          selectReservationPriceLoading: F
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_SELECT_RESERVATION_PRICE_FAILURE':
      return evolve(
        {
          selectReservationPriceLoading: F
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_SET_DEFAULT_CONTACT_DETAILS': {
      const { defaultContactDetails } = state;
      return {
        ...state,
        defaultContactDetails: {
          ...defaultContactDetails,
          ...(action?.payload ?? {})
        }
      };
    }

    case 'SELLFLOW_SET_CONTACT_DETAILS':
      return {
        ...state,
        contactDetails: { ...state.contactDetails, ...action.payload }
      };

    case 'SELLFLOW_SELECT_PACKAGE':
      return evolve(
        {
          packageSelected: always(pathOr('', ['payload', 'key'], action)),
          isTrimOptionsEdited: T
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_SELECT_ADDON': {
      return evolve(
        {
          // Array toggle, remove or append
          addonsSelected: ifElse(
            any(propEq('key', action.payload?.key)),
            reject(propEq('key', action.payload?.key)),
            append(assoc('value', true, action.payload))
          ),
          trimOptionsUnknown: F,
          isTrimOptionsEdited: T
        },
        Object.assign({}, initialState, state)
      );
    }

    case 'SELLFLOW_SELECT_VALUATION_ADDON': {
      return evolve(
        {
          // Array toggle, remove or append
          valuationStandardAddonsSelected: ifElse(
            any(propEq('key', action.payload?.key)),
            reject(propEq('key', action.payload?.key)),
            append(assoc('value', true, action.payload))
          ),
          trimOptionsUnknown: F,
          isTrimOptionsEdited: T
        },
        Object.assign({}, initialState, state)
      );
    }

    case 'SELLFLOW_TRIM_OPTIONS_LOCK':
      return evolve(
        {
          trimOptionsLocked: always(action.payload)
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_TRIM_OPTIONS_UNKNOWN':
      return evolve(
        {
          trimOptionsUnknown: always(action.payload),
          addonsSelected: always([]),
          valuationStandardAddonsSelected: always([]),
          isTrimOptionsEdited: T
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_PACKAGE_UNKNOWN':
      return evolve(
        {
          packageUnknown: always(action.payload),
          packageSelected: always(''),
          isTrimOptionsEdited: T
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_VALUATION_CLOSE':
      return evolve(
        {
          valuationClosed: always(action.payload)
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_SALES_METHOD_CLOSE':
      return evolve(
        {
          salesMethodClosed: always(action.payload)
        },
        Object.assign({}, initialState, state)
      );

    case 'SELLFLOW_SET_SALES_METHOD':
      return evolve(
        {
          salesMethod: always(action.payload)
        },
        Object.assign({}, initialState, state)
      );
  }
  return state;
};

export default SellFlow1Reducer;
