import { Caption, theme } from '@kvdbil/components';
import React from 'react';
import { useTranslation } from '~/Locale';
import { MinimumAgeField } from '../../../Fields/shared';
import Link from '~/App/shared/components/Link';
import { getTranslatedDynamicPageRoute } from '~/App/shared/localization/translateRoute';
import { Control, FieldValues } from 'react-hook-form';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${theme.colors.info.main} !important;
  font-weight: ${theme.typography.fontBaseBoldWeight};
`;

interface Props<FormState extends FieldValues> {
  control: Control<FormState>;
}

export const TermsCheckbox = <FormState extends FieldValues>({
  control
}: Props<FormState>) => {
  const { t } = useTranslation();

  return (
    <MinimumAgeField
      data-testid="terms-checkbox"
      control={control}
      t={t}
      label={
        <Caption>
          {t('I confirm that I am of age and agree to')}{' '}
          <StyledLink
            to={`/${getTranslatedDynamicPageRoute(t, 'aboutKvdTerms')}`}
            noTranslation
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('the terms of Kvdcars')}
          </StyledLink>
          .
        </Caption>
      }
    />
  );
};
