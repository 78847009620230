import {
  BodyText,
  Button,
  Flex,
  Select,
  TextField,
  Title
} from '@kvdbil/components';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '~/Locale';

const createField = (
  label: string,
  value: string | { value: string; label: string },
  fieldType: 'textfield' | 'select'
) => {
  if (fieldType === 'textfield' && typeof value === 'string') {
    return <TextField label={label} value={value} isDisabled />;
  }

  if (fieldType === 'select') {
    return (
      <Select
        name=""
        onChange={() => null}
        label={label}
        value={value}
        options={[value]}
        isDisabled
      />
    );
  }

  return null;
};

export interface RegistrationCompleteValues {
  firstName: string;
  lastName: string;
  organisationName: string;
  vatNumber: string;
  organisationNumber: string;
  nationalIdentificationNumber: string;
  country: { value: string; label: string };
  email: string;
}

interface Props extends RegistrationCompleteValues {
  title: string;
  description: string;
  onClose?(): void;
}

const RegistrationComplete = ({
  title,
  description,
  firstName,
  lastName,
  organisationName,
  vatNumber,
  organisationNumber,
  nationalIdentificationNumber,
  country,
  email,
  onClose
}: Partial<Props>) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    } else {
      history.push('/');
    }
  };

  const getNationalIdentificationNumberLabel = useCallback(() => {
    if (country?.value !== 'se' && nationalIdentificationNumber) {
      return t('National identification number (optional)');
    }

    return t('National identification number');
  }, [country?.value, nationalIdentificationNumber, t]);

  const fields = useMemo(() => {
    const fieldTemplate = [
      {
        label: getNationalIdentificationNumberLabel(),
        value: nationalIdentificationNumber,
        fieldType: 'textfield'
      },
      { label: t('First name'), value: firstName, fieldType: 'textfield' },
      { label: t('Last name'), value: lastName, fieldType: 'textfield' },
      {
        label: t('Company name'),
        value: organisationName,
        fieldType: 'textfield'
      },
      { label: t('VAT number'), value: vatNumber, fieldType: 'textfield' },
      {
        label: t('Organisation number'),
        value: organisationNumber,
        fieldType: 'textfield'
      },
      { label: t('Country'), value: country, fieldType: 'select' },
      { label: t('E-mail'), value: email, fieldType: 'textfield' }
    ];

    return fieldTemplate
      .filter(field => Boolean(field?.value))
      .map(({ label, value, fieldType }, index) => (
        <div key={index}>
          {createField(label, value ?? '', fieldType as 'textfield' | 'select')}
        </div>
      ));
  }, [
    t,
    getNationalIdentificationNumberLabel,
    firstName,
    lastName,
    organisationName,
    vatNumber,
    organisationNumber,
    nationalIdentificationNumber,
    country,
    email
  ]);

  return (
    <Flex style={{ textAlign: 'left' }} direction={'column'} gap={3}>
      <Title style={{ textAlign: 'center' }}>{title ?? t('Done!')}</Title>

      <Flex direction="column">
        <BodyText style={{ textAlign: 'center' }}>
          {description ??
            t('Welcome! Now you can start looking for a car that suits you.')}
        </BodyText>

        {fields}
      </Flex>

      <Button size="regular" fullWidth onClick={handleOnClose}>
        {t('Close')}
      </Button>
    </Flex>
  );
};

export default RegistrationComplete;
